@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Regular'),
         local('Ubuntu-Regular'),
         url('../fonts/UbuntuMin/Ubuntu.woff2') format('woff2'),
         url('../fonts/UbuntuMin/Ubuntu.woff') format('woff'),
         url('../fonts/UbuntuMin/Ubuntu.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Light'),
         local('Ubuntu-Light'),
         url('../fonts/UbuntuMin/UbuntuLight.woff2') format('woff2'),
         url('../fonts/UbuntuMin/UbuntuLight.woff') format('woff'),
         url('../fonts/UbuntuMin/UbuntuLight.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Medium'),
         local('Ubuntu-Medium'),
         url('../fonts/UbuntuMin/UbuntuMedium.woff2') format('woff2'),
         url('../fonts/UbuntuMin/UbuntuMedium.woff') format('woff'),
         url('../fonts/UbuntuMin/UbuntuMedium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Ubuntu';
    font-display: swap;
    font-style: normal;
    src: local('Ubuntu Bold'),
         local('Ubuntu-Bold'),
         url('../fonts/UbuntuMin/UbuntuBold.woff2') format('woff2'),
         url('../fonts/UbuntuMin/UbuntuBold.woff') format('woff'),
         url('../fonts/UbuntuMin/UbuntuBold.ttf') format('truetype');
    font-weight: 700;
}
