@charset "UTF-8";
@import url(../vendors/fancybox/jquery.fancybox.min.css);
@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  font-style: normal;
  src: local("Ubuntu Regular"), local("Ubuntu-Regular"), url("../fonts/UbuntuMin/Ubuntu.woff2") format("woff2"), url("../fonts/UbuntuMin/Ubuntu.woff") format("woff"), url("../fonts/UbuntuMin/Ubuntu.ttf") format("truetype");
  font-weight: 400; }

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  font-style: normal;
  src: local("Ubuntu Light"), local("Ubuntu-Light"), url("../fonts/UbuntuMin/UbuntuLight.woff2") format("woff2"), url("../fonts/UbuntuMin/UbuntuLight.woff") format("woff"), url("../fonts/UbuntuMin/UbuntuLight.ttf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  font-style: normal;
  src: local("Ubuntu Medium"), local("Ubuntu-Medium"), url("../fonts/UbuntuMin/UbuntuMedium.woff2") format("woff2"), url("../fonts/UbuntuMin/UbuntuMedium.woff") format("woff"), url("../fonts/UbuntuMin/UbuntuMedium.ttf") format("truetype");
  font-weight: 500; }

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  font-style: normal;
  src: local("Ubuntu Bold"), local("Ubuntu-Bold"), url("../fonts/UbuntuMin/UbuntuBold.woff2") format("woff2"), url("../fonts/UbuntuMin/UbuntuBold.woff") format("woff"), url("../fonts/UbuntuMin/UbuntuBold.ttf") format("truetype");
  font-weight: 700; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #fa9308;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #b26804;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.86rem; }

h3, .h3 {
  font-size: 1.6rem; }

h4, .h4 {
  font-size: 1.33rem; }

h5, .h5 {
  font-size: 1.2rem; }

h6, .h6 {
  font-size: 1.066rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.143rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 600px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 600px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #f2f2f2; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #f2f2f2; }
  .table tbody + tbody {
    border-top: 2px solid #f2f2f2; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #f2f2f2; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #f2f2f2; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fee1ba; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #fcc77f; }

.table-hover .table-primary:hover {
  background-color: #fed6a1; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fed6a1; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e1e1e3; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c8c8cb; }

.table-hover .table-secondary:hover {
  background-color: #d4d4d7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d4d4d7; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cee8b8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #a3d47a; }

.table-hover .table-success:hover {
  background-color: #c1e2a5; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #c1e2a5; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcecb8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fadc7a; }

.table-hover .table-warning:hover {
  background-color: #fbe5a0; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbe5a0; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f7c1c5; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f08d93; }

.table-hover .table-danger:hover {
  background-color: #f4aab0; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f4aab0; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: white; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fefefe; }

.table-hover .table-light:hover {
  background-color: #f2f2f2; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f2f2f2; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c6c6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #959595; }

.table-hover .table-dark:hover {
  background-color: #b9b9b9; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9b9b9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #f2f2f2; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 599.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid #eee;
  border-radius: 0.143rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #f7f7f7;
    border-color: #fdca85;
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #f7f7f7; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.9rem + 1px);
  padding-bottom: calc(0.9rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.11rem; }

.form-control-lg {
  height: calc(1.5em + 1.8rem + 2px);
  padding: 0.9rem 1.78rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.17rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.33rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4fad00; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 15px 15px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #4fad00;
  border-radius: 0.143rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #4fad00;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234fad00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.25rem);
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #4fad00;
    box-shadow: 0 0 0 0.2rem rgba(79, 173, 0, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #4fad00;
  padding-right: calc((1em + 1rem) * 3 / 4 + 2.2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.2rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234fad00' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #f7f7f7 no-repeat center right 2.2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #4fad00;
    box-shadow: 0 0 0 0.2rem rgba(79, 173, 0, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4fad00; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4fad00; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #4fad00; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #66e000;
  background-color: #66e000; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(79, 173, 0, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #4fad00; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4fad00; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #4fad00;
  box-shadow: 0 0 0 0.2rem rgba(79, 173, 0, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e32330; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 15px 15px;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #e32330;
  border-radius: 0.143rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #e32330;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e32330' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e32330' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.25rem);
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #e32330;
    box-shadow: 0 0 0 0.2rem rgba(227, 35, 48, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #e32330;
  padding-right: calc((1em + 1rem) * 3 / 4 + 2.2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.2rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23e32330' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23e32330' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #f7f7f7 no-repeat center right 2.2rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #e32330;
    box-shadow: 0 0 0 0.2rem rgba(227, 35, 48, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #e32330; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #e32330; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #e32330; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9505b;
  background-color: #e9505b; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 35, 48, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e32330; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e32330; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e32330;
  box-shadow: 0 0 0 0.2rem rgba(227, 35, 48, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 600px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1.2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.143rem;
  transition: color 75ms ease-in-out, background-color 75ms ease-in-out, border-color 75ms ease-in-out, box-shadow 75ms ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #fa9308;
  border-color: #fa9308; }
  .btn-primary:hover {
    color: #fff;
    background-color: #d77e04;
    border-color: #cb7604; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(217, 131, 13, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #fa9308;
    border-color: #fa9308; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #cb7604;
    border-color: #be6f04; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(217, 131, 13, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #95959b;
  border-color: #95959b; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #818188;
    border-color: #7b7b82; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(165, 165, 170, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #95959b;
    border-color: #95959b; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7b7b82;
    border-color: #75757c; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(165, 165, 170, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4fad00;
  border-color: #4fad00; }
  .btn-success:hover {
    color: #fff;
    background-color: #3e8700;
    border-color: #387a00; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(105, 185, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4fad00;
    border-color: #4fad00; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #387a00;
    border-color: #326d00; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(105, 185, 38, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f6bc00;
  border-color: #f6bc00; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d09f00;
    border-color: #c39500; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(214, 165, 6, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f6bc00;
    border-color: #f6bc00; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #c39500;
    border-color: #b68b00; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(214, 165, 6, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #e32330;
  border-color: #e32330; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c61925;
    border-color: #bb1823; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(231, 68, 79, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e32330;
    border-color: #e32330; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bb1823;
    border-color: #b01621; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(231, 68, 79, 0.5); }

.btn-light {
  color: #212529;
  background-color: #fefefe;
  border-color: #fefefe; }
  .btn-light:hover {
    color: #212529;
    background-color: #ebebeb;
    border-color: #e5e5e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(221, 221, 222, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #e5e5e5;
    border-color: #dedede; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(221, 221, 222, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #333;
  border-color: #333; }
  .btn-dark:hover {
    color: #fff;
    background-color: #202020;
    border-color: #1a1a1a; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(82, 82, 82, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1a1a;
    border-color: #131313; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(82, 82, 82, 0.5); }

.btn-outline-primary {
  color: #fa9308;
  border-color: #fa9308; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #fa9308;
    border-color: #fa9308; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(250, 147, 8, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #fa9308;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #fa9308;
    border-color: #fa9308; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(250, 147, 8, 0.5); }

.btn-outline-secondary {
  color: #95959b;
  border-color: #95959b; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #95959b;
    border-color: #95959b; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(149, 149, 155, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #95959b;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #95959b;
    border-color: #95959b; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(149, 149, 155, 0.5); }

.btn-outline-success {
  color: #4fad00;
  border-color: #4fad00; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4fad00;
    border-color: #4fad00; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(79, 173, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4fad00;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4fad00;
    border-color: #4fad00; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(79, 173, 0, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #f6bc00;
  border-color: #f6bc00; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f6bc00;
    border-color: #f6bc00; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(246, 188, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f6bc00;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f6bc00;
    border-color: #f6bc00; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(246, 188, 0, 0.5); }

.btn-outline-danger {
  color: #e32330;
  border-color: #e32330; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e32330;
    border-color: #e32330; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(227, 35, 48, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e32330;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e32330;
    border-color: #e32330; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(227, 35, 48, 0.5); }

.btn-outline-light {
  color: #fefefe;
  border-color: #fefefe; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(254, 254, 254, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #fefefe;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #fefefe;
    border-color: #fefefe; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(254, 254, 254, 0.5); }

.btn-outline-dark {
  color: #333;
  border-color: #333; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #333;
    border-color: #333; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #333;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #333;
    border-color: #333; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(51, 51, 51, 0.5); }

.btn-link {
  font-weight: 400;
  color: #fa9308;
  text-decoration: none; }
  .btn-link:hover {
    color: #b26804;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.9rem 1.78rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.17rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.11rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.143rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 600px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #fa9308; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.2rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #eee;
  border-radius: 0.143rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.8rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.9rem 1.78rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.17rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.11rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.642rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #fa9308;
    background-color: #fa9308; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #fdca85; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #fee0b7;
    border-color: #fee0b7; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.179rem;
    left: -1.642rem;
    display: block;
    width: 1.142rem;
    height: 1.142rem;
    pointer-events: none;
    content: "";
    background-color: #f7f7f7;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.179rem;
    left: -1.642rem;
    display: block;
    width: 1.142rem;
    height: 1.142rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.143rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #fa9308;
  background-color: #fa9308; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 147, 8, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(250, 147, 8, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(250, 147, 8, 0.5); }

.custom-switch {
  padding-left: 2.4985rem; }
  .custom-switch .custom-control-label::before {
    left: -2.4985rem;
    width: 1.9985rem;
    pointer-events: all;
    border-radius: 0.571rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.179rem + 2px);
    left: calc(-2.4985rem + 2px);
    width: calc(1.142rem - 4px);
    height: calc(1.142rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.571rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #f7f7f7;
    transform: translateX(0.8565rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(250, 147, 8, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 2.2rem 0.5rem 1.2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.2rem center/8px 10px;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 0.143rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #fdca85;
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #f7f7f7; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.2rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1.8rem + 2px);
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 1.78rem;
  font-size: 1rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #fdca85;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 0.143rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1rem);
    padding: 0.5rem 1.2rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.143rem 0.143rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 rgba(0, 0, 0, 0); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 rgba(0, 0, 0, 0); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 rgba(0, 0, 0, 0); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #fa9308;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fee0b7; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #fa9308;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fee0b7; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #fa9308;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fee0b7; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.143rem;
    border-top-right-radius: 0.143rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.143rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #fa9308; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.143rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.143rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.55rem 0.87rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fa9308;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #b26804;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.143rem;
  border-bottom-left-radius: 0.143rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.143rem;
  border-bottom-right-radius: 0.143rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #fa9308;
  border-color: #fa9308; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.17rem;
  border-bottom-left-radius: 0.17rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.17rem;
  border-bottom-right-radius: 0.17rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.11rem;
  border-bottom-left-radius: 0.11rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.11rem;
  border-bottom-right-radius: 0.11rem; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.17rem; }
  @media (min-width: 600px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.143rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #824c04;
  background-color: #fee9ce;
  border-color: #fee1ba; }
  .alert-primary hr {
    border-top-color: #fed6a1; }
  .alert-primary .alert-link {
    color: #512f02; }

.alert-secondary {
  color: #4d4d51;
  background-color: #eaeaeb;
  border-color: #e1e1e3; }
  .alert-secondary hr {
    border-top-color: #d4d4d7; }
  .alert-secondary .alert-link {
    color: #343437; }

.alert-success {
  color: #295a00;
  background-color: #dcefcc;
  border-color: #cee8b8; }
  .alert-success hr {
    border-top-color: #c1e2a5; }
  .alert-success .alert-link {
    color: #122700; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #806200;
  background-color: #fdf2cc;
  border-color: #fcecb8; }
  .alert-warning hr {
    border-top-color: #fbe5a0; }
  .alert-warning .alert-link {
    color: #4d3b00; }

.alert-danger {
  color: #761219;
  background-color: #f9d3d6;
  border-color: #f7c1c5; }
  .alert-danger hr {
    border-top-color: #f4aab0; }
  .alert-danger .alert-link {
    color: #4a0b10; }

.alert-light {
  color: #848484;
  background-color: white;
  border-color: white; }
  .alert-light hr {
    border-top-color: #f2f2f2; }
  .alert-light .alert-link {
    color: #6b6b6b; }

.alert-dark {
  color: #1b1b1b;
  background-color: #d6d6d6;
  border-color: #c6c6c6; }
  .alert-dark hr {
    border-top-color: #b9b9b9; }
  .alert-dark .alert-link {
    color: #020202; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.143rem;
    border-top-right-radius: 0.143rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.143rem;
    border-bottom-left-radius: 0.143rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #fa9308;
    border-color: #fa9308; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.143rem;
      border-bottom-left-radius: 0.143rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.143rem;
      border-bottom-right-radius: 0.143rem;
      border-bottom-left-radius: 0; }

@media (min-width: 600px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.143rem;
        border-bottom-left-radius: 0.143rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.143rem;
        border-bottom-right-radius: 0.143rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.143rem;
        border-bottom-left-radius: 0.143rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.143rem;
        border-bottom-right-radius: 0.143rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.143rem;
        border-bottom-left-radius: 0.143rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.143rem;
        border-bottom-right-radius: 0.143rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.143rem;
        border-bottom-left-radius: 0.143rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.143rem;
        border-bottom-right-radius: 0.143rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #824c04;
  background-color: #fee1ba; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #824c04;
    background-color: #fed6a1; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #824c04;
    border-color: #824c04; }

.list-group-item-secondary {
  color: #4d4d51;
  background-color: #e1e1e3; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4d4d51;
    background-color: #d4d4d7; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #4d4d51;
    border-color: #4d4d51; }

.list-group-item-success {
  color: #295a00;
  background-color: #cee8b8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #295a00;
    background-color: #c1e2a5; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #295a00;
    border-color: #295a00; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #806200;
  background-color: #fcecb8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #806200;
    background-color: #fbe5a0; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #806200;
    border-color: #806200; }

.list-group-item-danger {
  color: #761219;
  background-color: #f7c1c5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #761219;
    background-color: #f4aab0; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #761219;
    border-color: #761219; }

.list-group-item-light {
  color: #848484;
  background-color: white; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #848484;
    background-color: #f2f2f2; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #848484;
    border-color: #848484; }

.list-group-item-dark {
  color: #1b1b1b;
  background-color: #c6c6c6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1b1b;
    background-color: #b9b9b9; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1b1b;
    border-color: #1b1b1b; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.17rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.17rem;
  border-top-right-radius: 0.17rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.17rem;
  border-bottom-left-radius: 0.17rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 600px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #fff; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #fff; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #fff; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #fff; }

.tooltip-inner {
  max-width: 200px;
  padding: 15px 15px;
  color: #888;
  text-align: center;
  background-color: #fff;
  border-radius: 0.143rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.17rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.17rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.17rem 0; }
    .bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.17rem 0; }
    .bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.17rem - 1px);
  border-top-right-radius: calc(0.17rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.15rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #fa9308 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #cb7604 !important; }

.bg-secondary {
  background-color: #95959b !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #7b7b82 !important; }

.bg-success {
  background-color: #4fad00 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #387a00 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #f6bc00 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c39500 !important; }

.bg-danger {
  background-color: #e32330 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bb1823 !important; }

.bg-light {
  background-color: #fefefe !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e5e5e5 !important; }

.bg-dark {
  background-color: #333 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #fa9308 !important; }

.border-secondary {
  border-color: #95959b !important; }

.border-success {
  border-color: #4fad00 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #f6bc00 !important; }

.border-danger {
  border-color: #e32330 !important; }

.border-light {
  border-color: #fefefe !important; }

.border-dark {
  border-color: #333 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.11rem !important; }

.rounded {
  border-radius: 0.143rem !important; }

.rounded-top {
  border-top-left-radius: 0.143rem !important;
  border-top-right-radius: 0.143rem !important; }

.rounded-right {
  border-top-right-radius: 0.143rem !important;
  border-bottom-right-radius: 0.143rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.143rem !important;
  border-bottom-left-radius: 0.143rem !important; }

.rounded-left {
  border-top-left-radius: 0.143rem !important;
  border-bottom-left-radius: 0.143rem !important; }

.rounded-lg {
  border-radius: 0.17rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 600px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 600px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 600px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 600px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 600px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #fa9308 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #b26804 !important; }

.text-secondary {
  color: #95959b !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #6e6e75 !important; }

.text-success {
  color: #4fad00 !important; }

a.text-success:hover, a.text-success:focus {
  color: #2c6100 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #f6bc00 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #aa8200 !important; }

.text-danger {
  color: #e32330 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a5151f !important; }

.text-light {
  color: #fefefe !important; }

a.text-light:hover, a.text-light:focus {
  color: #d8d8d8 !important; }

.text-dark {
  color: #333 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #0d0d0d !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #f2f2f2; }
  .table .thead-dark th {
    color: inherit;
    border-color: #f2f2f2; } }

.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative; }

.slider.slider-horizontal {
  width: 210px;
  height: 20px; }

.slider.slider-horizontal .slider-track {
  height: 10px;
  width: 100%;
  margin-top: -5px;
  top: 50%;
  left: 0; }

.slider.slider-horizontal .slider-selection, .slider.slider-horizontal .slider-track-low, .slider.slider-horizontal .slider-track-high {
  height: 100%;
  top: 0;
  bottom: 0; }

.slider.slider-horizontal .slider-tick, .slider.slider-horizontal .slider-handle {
  margin-left: -10px; }

.slider.slider-horizontal .slider-tick.triangle, .slider.slider-horizontal .slider-handle.triangle {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-width: 0 10px 10px 10px;
  width: 0;
  height: 0;
  border-bottom-color: #2e6da4;
  margin-top: 0; }

.slider.slider-horizontal .slider-tick-container {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.slider.slider-horizontal .slider-tick-label-container {
  white-space: nowrap;
  margin-top: 20px; }

.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  padding-top: 4px;
  display: inline-block;
  text-align: center; }

.slider.slider-horizontal .tooltip {
  -ms-transform: translateX(-50%);
  transform: translateX(-50%); }

.slider.slider-horizontal.slider-rtl .slider-track {
  left: initial;
  right: 0; }

.slider.slider-horizontal.slider-rtl .slider-tick, .slider.slider-horizontal.slider-rtl .slider-handle {
  margin-left: initial;
  margin-right: -10px; }

.slider.slider-horizontal.slider-rtl .slider-tick-container {
  left: initial;
  right: 0; }

.slider.slider-horizontal.slider-rtl .tooltip {
  -ms-transform: translateX(50%);
  transform: translateX(50%); }

.slider.slider-vertical {
  height: 210px;
  width: 20px; }

.slider.slider-vertical .slider-track {
  width: 10px;
  height: 100%;
  left: 25%;
  top: 0; }

.slider.slider-vertical .slider-selection {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0; }

.slider.slider-vertical .slider-track-low, .slider.slider-vertical .slider-track-high {
  width: 100%;
  left: 0;
  right: 0; }

.slider.slider-vertical .slider-tick, .slider.slider-vertical .slider-handle {
  margin-top: -10px; }

.slider.slider-vertical .slider-tick.triangle, .slider.slider-vertical .slider-handle.triangle {
  border-width: 10px 0 10px 10px;
  width: 1px;
  height: 1px;
  border-left-color: #2e6da4;
  border-right-color: #2e6da4;
  margin-left: 0;
  margin-right: 0; }

.slider.slider-vertical .slider-tick-label-container {
  white-space: nowrap; }

.slider.slider-vertical .slider-tick-label-container .slider-tick-label {
  padding-left: 4px; }

.slider.slider-vertical .tooltip {
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.slider.slider-vertical.slider-rtl .slider-track {
  left: initial;
  right: 25%; }

.slider.slider-vertical.slider-rtl .slider-selection {
  left: initial;
  right: 0; }

.slider.slider-vertical.slider-rtl .slider-tick.triangle, .slider.slider-vertical.slider-rtl .slider-handle.triangle {
  border-width: 10px 10px 10px 0; }

.slider.slider-vertical.slider-rtl .slider-tick-label-container .slider-tick-label {
  padding-left: initial;
  padding-right: 4px; }

.slider.slider-disabled .slider-handle {
  background-image: -webkit-linear-gradient(top, #dfdfdf 0, #bebebe 100%);
  background-image: -o-linear-gradient(top, #dfdfdf 0, #bebebe 100%);
  background-image: linear-gradient(to bottom, #dfdfdf 0, #bebebe 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdfdfdf',endColorstr='#ffbebebe',GradientType=0); }

.slider.slider-disabled .slider-track {
  background-image: -webkit-linear-gradient(top, #e5e5e5 0, #e9e9e9 100%);
  background-image: -o-linear-gradient(top, #e5e5e5 0, #e9e9e9 100%);
  background-image: linear-gradient(to bottom, #e5e5e5 0, #e9e9e9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5',endColorstr='#ffe9e9e9',GradientType=0);
  cursor: not-allowed; }

.slider input {
  display: none; }

.slider .tooltip {
  pointer-events: none; }

.slider .tooltip.top {
  margin-top: -36px; }

.slider .tooltip-inner {
  white-space: nowrap;
  max-width: none; }

.slider .hide {
  display: none; }

.slider-track {
  position: absolute;
  cursor: pointer;
  background-image: -webkit-linear-gradient(top, #f5f5f5 0, #f9f9f9 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0, #f9f9f9 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0, #f9f9f9 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5',endColorstr='#fff9f9f9',GradientType=0);
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.slider-selection {
  position: absolute;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9',endColorstr='#fff5f5f5',GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px; }

.slider-selection.tick-slider-selection {
  background-image: -webkit-linear-gradient(top, #8ac1ef 0, #82b3de 100%);
  background-image: -o-linear-gradient(top, #8ac1ef 0, #82b3de 100%);
  background-image: linear-gradient(to bottom, #8ac1ef 0, #82b3de 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ac1ef',endColorstr='#ff82b3de',GradientType=0); }

.slider-track-low, .slider-track-high {
  position: absolute;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px; }

.slider-handle {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: #337ab7;
  background-image: -webkit-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: -o-linear-gradient(top, #337ab7 0, #2e6da4 100%);
  background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7',endColorstr='#ff2e6da4',GradientType=0);
  filter: none;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 0 solid transparent; }

.slider-handle:hover {
  cursor: pointer; }

.slider-handle.round {
  border-radius: 50%; }

.slider-handle.triangle {
  background: transparent none; }

.slider-handle.custom {
  background: transparent none; }

.slider-handle.custom::before {
  line-height: 20px;
  font-size: 20px;
  content: '\2605';
  color: #726204; }

.slider-tick {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9',endColorstr='#fff5f5f5',GradientType=0);
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  filter: none;
  opacity: .8;
  border: 0 solid transparent; }

.slider-tick.round {
  border-radius: 50%; }

.slider-tick.triangle {
  background: transparent none; }

.slider-tick.custom {
  background: transparent none; }

.slider-tick.custom::before {
  line-height: 20px;
  font-size: 20px;
  content: '\2605';
  color: #726204; }

.slider-tick.in-selection {
  background-image: -webkit-linear-gradient(top, #8ac1ef 0, #82b3de 100%);
  background-image: -o-linear-gradient(top, #8ac1ef 0, #82b3de 100%);
  background-image: linear-gradient(to bottom, #8ac1ef 0, #82b3de 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ac1ef',endColorstr='#ff82b3de',GradientType=0);
  opacity: 1; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.toast-title {
  font-weight: 700; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }

.toast-message a, .toast-message label {
  color: #FFF; }

.toast-message a:hover {
  color: #CCC;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -.3em;
  top: -.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: .8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1; }

.toast-close-button:focus, .toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

.rtl .toast-close-button {
  left: -.3em;
  float: left;
  right: .3em; }

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none; }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

.toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none; }

.toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: .8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

.toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center; }

.toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

.toast-container > .toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=); }

.toast-container > .toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=); }

.toast-container > .toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==); }

.toast-container > .toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=); }

.toast-container.toast-bottom-center > div, .toast-container.toast-top-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

.toast-container.toast-bottom-full-width > div, .toast-container.toast-top-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #51A351; }

.toast-error {
  background-color: #BD362F; }

.toast-info {
  background-color: #2F96B4; }

.toast-warning {
  background-color: #F89406; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: .4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

@media all and (max-width: 240px) {
  .toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  .toast-container > div.rtl {
    padding: 8px 50px 8px 8px; }
  .toast-container .toast-close-button {
    right: -.2em;
    top: -.2em; }
  .toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  .toast-container > div.rtl {
    padding: 8px 50px 8px 8px; }
  .toast-container .toast-close-button {
    right: -.2em;
    top: -.2em; }
  .toast-container .rtl .toast-close-button {
    left: -.2em;
    right: .2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; }
  .toast-container > div.rtl {
    padding: 15px 50px 15px 15px; } }

html {
  font-size: 14px; }

body {
  font-family: "Ubuntu", "Roboto", sans-serif;
  overflow-x: hidden;
  min-width: 300px;
  color: #555; }

.container {
  max-width: 1440px; }

svg {
  display: inline-block; }

.feather {
  display: inline-block;
  vertical-align: middle; }

.svg-inline {
  height: 1.4em;
  width: 1.4em; }

a {
  text-decoration: none;
  color: #fa9308;
  transition: color ease-out 70ms; }
  a:hover {
    color: #333;
    text-decoration: none; }
  a:focus {
    outline: none; }
  a.link_dark {
    color: #333; }
    a.link_dark:hover {
      color: #fa9308; }

.link-more {
  display: inline-block;
  white-space: nowrap;
  color: #333;
  font-size: 13px; }
  .link-more:after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/70% 70% no-repeat;
    margin: 0 6px;
    vertical-align: middle; }
  .link-more:hover {
    color: #fa9308; }
    .link-more:hover:after {
      background-color: #fa9308;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
      color: #fff; }

.link-collapse {
  position: relative;
  padding-right: 25px; }
  .link-collapse:before {
    content: "";
    position: absolute;
    right: 5px;
    top: 2px;
    bottom: 0;
    margin: auto 0;
    width: 15px;
    height: 15px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23555' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat;
    transform: rotateZ(180deg); }
  .link-collapse.collapsed:before {
    transform: rotateZ(0deg); }

.link-tag {
  background: #f5f5f5;
  padding: 5px 11px 6px;
  color: #999;
  font-size: .8em;
  border-radius: 4px;
  margin: 0 1px 5px;
  line-height: 15px;
  display: inline-block; }
  .link-tag:hover {
    background: #fa9308;
    color: #fff; }
  .link-tag:active {
    color: #333; }

:focus {
  outline: none; }

::-moz-focus-inner {
  border: 0; }

button:focus {
  outline: none; }

.form-control_white {
  background-color: #fff;
  border-color: #f2f2f2; }
  .form-control_white[readonly] {
    background-color: #fff; }
  .form-control_white:focus {
    background-color: #fff; }

.custom-select_clean {
  background-image: none; }

.custom-select {
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"), linear-gradient(to bottom, #f2f2f2, #f2f2f2);
  background-size: 15px 15px, 1px 1px;
  background-position: right 7px center, right 2rem top;
  background-repeat: no-repeat, repeat-y;
  padding-right: 2.7rem; }
  .custom-select:hover {
    background-size: 15px 15px, 2rem 100%;
    background-position: right 7px center, right top; }
  .custom-select:active {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"), linear-gradient(to bottom, #dddddd, #dddddd); }

.btn {
  white-space: nowrap; }

.btn__icon {
  width: 1.4em;
  height: 1.4em; }

.btn-icon {
  padding-left: .5em;
  padding-right: .5em; }

.btn-primary {
  color: #fff; }

.btn-outline-primary:hover {
  color: #fff; }

.btn-light {
  border-color: #f2f2f2; }

.btn-link {
  text-decoration: none !important; }

.alert-warning {
  background-color: #fffcf4; }

.alert-success {
  background-color: #f7fbf4; }

.alert-danger {
  background-color: #fdf6f7;
  color: #e62222; }

.alert-secondary {
  background: #f3f4f5;
  color: #535c69; }

.text-muted {
  color: #999 !important; }

.bg-black {
  background: #000; }

.alert-dismissible {
  padding-right: 2.7rem; }

.tooltip-inner {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.12); }

.table_thin th {
  font-weight: 400;
  color: #999; }
  .table_thin th:first-child {
    border-left: 1px solid #f2f2f2; }
  .table_thin th:last-child {
    border-right: 1px solid #f2f2f2; }

.table_thin thead th {
  border-bottom-width: 1px; }

.table_thin td {
  border-top: 0;
  border-bottom: 1px solid #f2f2f2;
  padding-top: 15px;
  padding-bottom: 15px; }

.link_back-to-list {
  display: inline-block;
  position: relative;
  padding: 10px 20px 11px 64px;
  background: #fff;
  border: 1px solid #f2f2f2;
  line-height: 22px;
  box-shadow: none;
  z-index: 2;
  border-radius: 2px;
  color: #333;
  white-space: nowrap; }
  .link_back-to-list:before {
    content: "";
    position: absolute;
    top: 0;
    left: 44px;
    display: block;
    background: #f2f2f2;
    width: 1px;
    height: 100%;
    z-index: 1; }
  .link_back-to-list:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
    margin: auto 0;
    display: block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat;
    transform: scaleX(-1);
    width: 21px;
    height: 21px; }
  .link_back-to-list:hover {
    background: #f2f2f2; }
    .link_back-to-list:hover:before {
      background: #fff; }

.line-vertical-divider {
  display: inline-block;
  height: 1.5em;
  width: 1px;
  margin: 0 5px;
  vertical-align: middle;
  background: #dee2e7; }

.nav-tabs {
  border-bottom-color: #f2f2f2; }
  .nav-tabs .nav-link {
    position: relative;
    background: #f9f9fa;
    border: 1px solid #f2f2f2;
    color: #222;
    margin: 0 -1px -1px 0;
    padding: 17px 25px 18px;
    text-decoration: none;
    border-radius: 0;
    font-weight: 700;
    text-transform: none; }
    .nav-tabs .nav-link:before {
      content: "";
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background: #ddd; }
    .nav-tabs .nav-link:hover {
      color: #fa9308; }
    .nav-tabs .nav-link.active {
      color: #fa9308; }
      .nav-tabs .nav-link.active:before {
        display: block;
        background: #fa9308; }

.nav-tabs_links {
  border: 0;
  padding-bottom: 5px; }
  .nav-tabs_links .nav-item {
    margin-right: 36px;
    margin-bottom: 6px; }
    @media (max-width: 767.98px) {
      .nav-tabs_links .nav-item {
        margin-right: 20px; } }
  .nav-tabs_links .nav-link {
    font-size: 16px;
    line-height: 1.2;
    border: 0;
    border-bottom: 2px solid transparent;
    padding: 0 0 5px 0;
    font-weight: 700;
    color: #fa9308;
    background: transparent; }
    @media (max-width: 767.98px) {
      .nav-tabs_links .nav-link {
        font-size: 14px; } }
    .nav-tabs_links .nav-link:before {
      display: none !important; }
    .nav-tabs_links .nav-link:hover {
      color: #333;
      border-bottom-color: transparent; }
    .nav-tabs_links .nav-link.active {
      color: #333;
      border-bottom-color: #fa9308; }

@media (max-width: 767.98px) {
  .adaptive-tabs__nav {
    display: none; } }

@media (max-width: 767.98px) {
  .adaptive-tabs__tab-content {
    padding-top: 0 !important; } }

@media (max-width: 767.98px) {
  .adaptive-tabs__tab-pane {
    display: block !important;
    opacity: 1 !important; } }

.adaptive-tabs__header {
  display: none;
  position: relative;
  margin: 0 0 -1px 0;
  padding: 18px 45px 17px 25px;
  border: 1px solid #f5f5f5;
  border-radius: 0;
  font-weight: 700;
  font-size: 15px;
  color: #222;
  background: #fff;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .adaptive-tabs__header {
      display: block; } }
  .adaptive-tabs__header:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: 25px;
    font-weight: 400;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }
  .adaptive-tabs__header:hover {
    color: #fa9308; }
    .adaptive-tabs__header:hover:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
  .adaptive-tabs__header:not(.collapsed) {
    color: #fa9308; }
    .adaptive-tabs__header:not(.collapsed):before {
      transform: rotateZ(180deg);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }

@media (min-width: 768px) {
  .adaptive-tabs__collapse {
    display: block !important; } }

@media (max-width: 767.98px) {
  .adaptive-tabs__inner {
    padding-top: 20px;
    padding-bottom: 20px; } }

.page-item.active .page-link {
  color: #fff; }

.page-link {
  font-weight: 700;
  border-radius: 2px;
  border: 0;
  color: #333;
  background: transparent; }
  .page-link:hover {
    color: #fa9308;
    background: transparent; }
  .page-link_arrow {
    height: 2.3em;
    width: 2.3em;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/20px 20px no-repeat !important;
    opacity: 0.7; }
    .page-link_arrow:hover {
      opacity: 1; }
    .page-link_arrow_left {
      transform: rotateZ(180deg); }

.captcha-row {
  position: relative;
  max-width: 418px;
  overflow: hidden; }

.line-height-1 {
  line-height: 1; }

.line-height-2 {
  line-height: 1.15; }

.line-height-3 {
  line-height: 1.35; }

.line-height-4 {
  line-height: 1.4; }

.line-height-5 {
  line-height: 1.5; }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 900px; } }

.modal .close {
  position: absolute;
  right: 33px;
  top: 50%;
  padding: 0;
  margin: -10px 0 0;
  font-size: 0;
  width: 20px;
  height: 20px; }
  .modal .close:before, .modal .close:after {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: block;
    width: 100%;
    height: 2px;
    background: #888; }
  .modal .close:before {
    transform: rotateZ(45deg); }
  .modal .close:after {
    transform: rotateZ(-45deg); }

.modal-content {
  border-radius: 0;
  border: 0;
  background-clip: border-box; }

.modal-header {
  position: relative;
  padding: 34px 75px 35px 35px;
  border-bottom: 1px solid #f2f2f2; }
  .modal-header_bordered {
    border-bottom: 2px solid #fa9308; }
  .modal-header_slim {
    padding-top: 25px;
    padding-bottom: 25px; }
  .modal-header .modal-title {
    font-size: 22px; }

.modal-title {
  font-size: 26px;
  line-height: 1.1;
  color: #383838; }

.modal-body {
  padding: 30px 35px 35px; }
  .modal-body_grey {
    border-top: 1px solid #f2f2f2;
    background: #f8f8f8; }
  @media (max-width: 400px) {
    .modal-body {
      padding-left: 20px;
      padding-right: 20px; } }

.modal-footer {
  background: #f7f7f7;
  border-top: 1px solid #f2f2f2;
  padding: 28px 35px 37px;
  display: block; }
  @media (max-width: 400px) {
    .modal-footer {
      padding-left: 20px;
      padding-right: 20px; } }

.page-preloader {
  display: none;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff url("../images/template/lazy.svg") center center/450px 300px no-repeat; }
  body.is-page-loading .page-preloader {
    display: block; }

.page-to-up {
  display: block;
  position: fixed;
  right: 73px;
  bottom: 55px;
  width: 44px;
  height: 44px;
  opacity: 0;
  border-radius: 50%;
  text-align: center;
  text-decoration: none;
  background: #fa9308 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='12' y1='19' x2='12' y2='5'%3E%3C/line%3E%3Cpolyline points='5 12 12 5 19 12'%3E%3C/polyline%3E%3C/svg%3E") center/55% 55% no-repeat;
  z-index: 995;
  cursor: pointer;
  transition: opacity .3s; }
  @media (max-width: 767.98px) {
    .page-to-up {
      display: none; } }
  .page-to-up.active {
    opacity: 1; }

.loading-animation {
  background: scroll rgba(0, 0, 0, 0) url("../images/template/loading.svg") center center/20px 20px no-repeat !important;
  border-color: transparent !important;
  color: transparent !important;
  cursor: default !important; }
  .loading-animation * {
    background: rgba(0, 0, 0, 0) !important;
    border-color: transparent !important;
    color: transparent !important;
    cursor: default !important; }

.input-clear {
  position: relative; }
  .input-clear__btn {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 37px;
    border: 0;
    padding: 0;
    color: #a1a1a1;
    background: transparent;
    box-shadow: none !important;
    outline: none !important;
    transition: 300ms color ease; }
    .input-clear__btn:after, .input-clear__btn:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 2px;
      height: 14px;
      background: currentColor;
      content: ''; }
    .input-clear__btn:after {
      transform: rotate(45deg); }
    .input-clear__btn:before {
      transform: rotate(-45deg); }
    .input-clear__btn:hover {
      color: #333; }

.slider .tooltip {
  display: none; }

.slider-handle {
  display: inline-block;
  top: -2px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  background: #fff;
  border-radius: 16px;
  border: 3px solid #fa9308; }

.slider-track {
  box-shadow: none;
  background: #f2f2f2; }

.slider-selection {
  background: #fa9308;
  box-shadow: none; }

.slider.slider-horizontal {
  display: block;
  margin-left: 9px;
  margin-right: 9px;
  width: auto; }
  .slider.slider-horizontal .slider-track {
    height: 4px; }
  .slider.slider-horizontal .slider-handle {
    margin-left: -9px; }
  .slider.slider-horizontal .min-slider-handle {
    margin-left: -9px; }
  .slider.slider-horizontal .max-slider-handle {
    margin-right: -9px; }

label {
  color: #888;
  font-size: 13px;
  margin-bottom: 6px; }

.label-required {
  color: #e32330;
  font-size: 12px;
  font-weight: 400; }

.custom-control-input:not(:disabled):hover ~ .custom-control-label::before {
  background-color: #fa9308;
  border-color: #fa9308; }

.custom-control-label {
  cursor: pointer; }
  .custom-control-label:before, .custom-control-label:after {
    margin-top: -0.15em; }
  .custom-control-label:before {
    background-color: #fff;
    border-color: #ccc; }
  .custom-control-label:after {
    background-size: 65% 65%; }

.custom-control-lg {
  padding-left: 2.2rem; }
  .custom-control-lg .custom-control-label {
    font-size: 15px; }
    .custom-control-lg .custom-control-label::before, .custom-control-lg .custom-control-label::after {
      left: -2.2rem;
      top: .1rem;
      width: 1.5rem;
      height: 1.5rem; }

.custom-control_strong .custom-control-label {
  font-weight: 700;
  color: #333; }

.custom-control-lg-padding {
  padding-left: 2.2rem; }

.dropdown-menu {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0; }

.shine {
  position: relative;
  overflow: hidden; }
  .shine:before {
    position: absolute;
    top: 0;
    left: -45%;
    z-index: 2;
    display: block;
    content: '';
    width: 25%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg); }
  .shine:hover:before {
    animation: shine .5s;
    opacity: 1; }

@keyframes shine {
  100% {
    left: 125%; } }

.blink:hover {
  animation: flash .8s; }

@keyframes flash {
  0% {
    opacity: .55; }
  100% {
    opacity: 1; } }

.colorbox {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 0;
  line-height: 0; }
  .colorbox:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #f2f2f2; }
  .colorbox__item {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .colorbox__item:nth-child(2) {
      left: 50%;
      width: 50%; }
    .colorbox__item:nth-child(3) {
      left: 50%;
      top: 50%;
      width: 50%;
      height: 50%; }
    .colorbox__item:nth-child(4) {
      left: 0%;
      top: 50%;
      width: 50%;
      height: 50%; }

.close-button {
  display: inline-block;
  padding: 0;
  width: 28px;
  height: 28px;
  box-shadow: none !important;
  outline: none !important;
  border: 0;
  background: url("../vendors/feather/icons/x.svg") center/100% 100% no-repeat; }

.sign-in-socials__title {
  font-size: 15px;
  color: #222;
  margin-bottom: 15px;
  line-height: 1.2; }

.breadcrumb {
  padding: 0;
  padding-bottom: 11px;
  margin: 0;
  background: transparent; }
  .breadcrumb-item {
    font-size: 12px;
    color: #b6b5b5; }
    .breadcrumb-item + .breadcrumb-item:before {
      padding-right: 5px;
      content: "-"; }
    .breadcrumb-item > a {
      color: #b6b5b5; }
      .breadcrumb-item > a:hover {
        color: #fa9308; }
    .breadcrumb-item > a,
    .breadcrumb-item > span {
      cursor: pointer; }
    .breadcrumb-item.active {
      color: #b6b5b5; }
    .breadcrumb-item_dropdown {
      padding-right: 18px; }
      .breadcrumb-item_dropdown:after {
        content: "";
        display: block;
        position: absolute;
        top: 0.58em;
        bottom: 0;
        border: 3px solid transparent;
        border-top-color: #888;
        right: 0.5em;
        margin: auto 0; }
      .breadcrumb-item_dropdown:not(:hover) > a {
        border-bottom: 1px dotted #b4b4b4; }

.breadcrumb-dropdown-menu {
  border-top: 2px solid #fa9308;
  padding: 12px 12px 5px;
  font-size: 11px;
  line-height: 1.2; }
  .breadcrumb-dropdown-menu__item {
    padding: 0;
    margin-bottom: 7px; }
    .breadcrumb-dropdown-menu__item.active, .breadcrumb-dropdown-menu__item:active {
      background: #fff; }
      .breadcrumb-dropdown-menu__item.active .breadcrumb-dropdown-menu__link, .breadcrumb-dropdown-menu__item:active .breadcrumb-dropdown-menu__link {
        color: #fa9308; }
  .breadcrumb-dropdown-menu__link {
    display: block;
    color: #1d2029; }
    .breadcrumb-dropdown-menu__link:hover {
      color: #fa9308; }

.hint-toggle {
  border: 1px solid #e5e5e5;
  display: inline-block;
  text-align: center;
  padding: 0;
  height: 17px;
  width: 17px;
  line-height: 15px;
  border-radius: 50%;
  color: #444;
  font-size: 12px; }
  .hint-toggle:hover {
    color: #fff;
    background: #fa9308;
    border-color: #fa9308; }

.countdown {
  height: 2.866em;
  display: inline-flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 2px;
  font-size: 15px;
  margin-bottom: 5px; }
  .countdown-item {
    display: inline-block;
    position: relative;
    height: 2.866em;
    width: 2.666em;
    padding: 0.4666em 0 0;
    margin: 0;
    vertical-align: top;
    font-weight: 700;
    font-size: 1em;
    text-align: center;
    background: #eee;
    border-radius: 0;
    line-height: 1; }
    .countdown-item:before, .countdown-item:after {
      content: "";
      background: #333;
      width: 3px;
      height: 3px;
      display: block;
      position: absolute;
      right: -1px;
      border-radius: 6px;
      z-index: 10; }
    .countdown-item:before {
      top: 1em; }
    .countdown-item:after {
      bottom: 1em; }
    .countdown-item__text {
      opacity: .6;
      font-weight: 400;
      font-size: 0.8em;
      line-height: 1.2;
      display: block;
      width: auto;
      text-transform: lowercase; }
    .countdown-item:last-child:before, .countdown-item:last-child:after {
      display: none; }

.filter-price__ranges {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .filter-price__ranges:before {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 12px;
    left: 50%;
    margin-left: -6px;
    background: #ccc; }

.filter-price__range-place {
  flex: 0 0 50%;
  max-width: 50%; }
  .filter-price__range-place_left {
    padding-right: 10px; }
  .filter-price__range-place_right {
    padding-left: 10px; }

.filter-price__range {
  width: 100%;
  background: #fff;
  padding: 8px;
  color: #666; }

.lazy-youtube {
  position: absolute;
  background: #424242 center / cover no-repeat;
  cursor: pointer; }
  .lazy-youtube:before {
    content: "";
    position: absolute;
    height: 100px;
    width: 100%;
    background-repeat: repeat-x;
    background-position: top;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    pointer-events: none; }
  .lazy-youtube__btn {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 68px;
    height: 48px;
    margin-left: -34px;
    margin-top: -24px;
    background: #212121;
    opacity: 0.8;
    border-radius: 15px;
    padding: 0;
    border: 0;
    transition: color 0.25s cubic-bezier(0, 0, 0.2, 1), opacity 0.25s cubic-bezier(0, 0, 0.2, 1); }
    .lazy-youtube__btn:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      border: 11px solid transparent;
      border-left: 20px solid #fff;
      margin-top: -11px;
      margin-left: -7px; }
  .lazy-youtube:hover .lazy-youtube__btn {
    background: #f00;
    opacity: 1; }

.share {
  display: inline-block; }
  .share__menu {
    position: absolute !important;
    width: 145px;
    left: 0 !important;
    right: 0 !important;
    margin: 10px auto 10px;
    padding: 20px 20px 15px 23px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    border-color: transparent; }
    .share__menu:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      border-bottom-color: #fff;
      left: 50%;
      top: -16px;
      margin-left: -11px; }
    .share__menu[x-placement^="top"]:before {
      top: auto;
      bottom: -16px;
      transform: scaleY(-1); }
    .share__menu_in-header {
      left: auto !important;
      right: -10px !important;
      transform: none !important;
      top: 100% !important;
      bottom: auto !important; }
      .share__menu_in-header:before {
        top: -16px !important;
        transform: none !important;
        bottom: auto !important;
        right: 20px !important;
        left: auto !important; }
  .share__btn {
    padding: 8px 20px 10px 20px;
    background: #fff;
    border: 1px solid #f2f2f2;
    line-height: 22px;
    box-shadow: none;
    z-index: 2;
    border-radius: 2px;
    color: #333; }
    .share__btn:hover {
      background: #f2f2f2; }
    .share__btn_with-icon {
      position: relative;
      padding-right: 64px; }
      .share__btn_with-icon:before {
        content: "";
        position: absolute;
        top: 0;
        right: 44px;
        display: block;
        background: #f2f2f2;
        width: 1px;
        height: 100%;
        z-index: 1; }
      .share__btn_with-icon:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 12px;
        margin: auto 0;
        display: block;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='18' cy='5' r='3'%3E%3C/circle%3E%3Ccircle cx='6' cy='12' r='3'%3E%3C/circle%3E%3Ccircle cx='18' cy='19' r='3'%3E%3C/circle%3E%3Cline x1='8.59' y1='13.51' x2='15.42' y2='17.49'%3E%3C/line%3E%3Cline x1='15.41' y1='6.51' x2='8.59' y2='10.49'%3E%3C/line%3E%3C/svg%3E") center/100% 100% no-repeat;
        width: 17px;
        height: 18px; }
      .share__btn_with-icon:hover:before {
        background: #fff; }
  .share .ya-share2__badge {
    border-radius: 3px; }
  .share .ya-share2__container_size_m .ya-share2__icon {
    height: 28px;
    width: 28px; }
  .share .ya-share2__container_size_m .ya-share2__item {
    margin-right: 5px;
    margin-bottom: 5px; }
  @media (max-width: 767.98px) {
    .share_adaptive .share__btn {
      font-size: 0;
      padding: 0;
      width: 46px;
      height: 46px; }
      .share_adaptive .share__btn:before {
        display: none; }
    .share_adaptive .share__menu {
      right: 0 !important;
      left: auto !important;
      transform: none !important;
      top: 100% !important; }
      .share_adaptive .share__menu:before {
        left: 85%; } }

.share-pop__content {
  position: absolute;
  background: #f2f2f2;
  min-width: 50px; }

.divider-line {
  height: 2px;
  background: #fa9308;
  margin: 20px 0; }

.file-type {
  position: relative;
  margin-top: 15px;
  padding-left: 46px; }
  .file-type__icon {
    position: absolute;
    display: block;
    width: 45px;
    height: 35px;
    left: 0;
    top: 3px;
    background: url("../images/template/icons/file_type.png") top left/auto 100% no-repeat; }
    .file-type__icon:before {
      content: "FILE";
      position: absolute;
      right: 10px;
      top: 10px;
      min-width: 27px;
      font-size: 9px;
      text-transform: uppercase;
      color: #fff;
      background: #999;
      border: 1px solid #fff;
      text-align: center; }
  .file-type__text {
    display: block;
    word-break: break-word; }
  .file-type__size {
    color: #888; }
  .file-type_pdf .file-type__icon:before {
    content: "PDF";
    background-color: #e9372f; }
  .file-type_doc .file-type__icon:before {
    content: "DOC";
    background-color: #3591d7; }
  .file-type_jpg .file-type__icon:before {
    content: "JPG";
    background-color: #ffa200; }
  .file-type_png .file-type__icon:before {
    content: "PNG";
    background-color: #5870eb; }
  .file-type_ppt .file-type__icon:before {
    content: "PPT";
    background-color: #ff6600; }
  .file-type_txt .file-type__icon:before {
    content: "TXT";
    background-color: #999; }
  .file-type_tif .file-type__icon:before {
    content: "TIF";
    background-color: #1254c3; }
  .file-type_xls .file-type__icon:before {
    content: "XLS";
    background-color: #7bad1b; }

.iconblock {
  display: flex;
  align-items: center; }
  @media (max-width: 767.98px) {
    .iconblock {
      display: block;
      text-align: center; } }
  .iconblock__image-place {
    display: block;
    flex: 0 0 70px;
    max-width: 70px;
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    background: #fff;
    border-radius: 50%; }
    @media (max-width: 767.98px) {
      .iconblock__image-place {
        display: inline-block;
        margin-bottom: 10px; } }
  .iconblock__img {
    display: inline-block;
    max-width: 44px;
    max-height: 44px;
    vertical-align: middle; }
  .iconblock__text {
    flex: 1 1 1px;
    display: block;
    font-size: 13px;
    line-height: 1.2;
    padding-left: 20px;
    padding-right: 10px; }
    @media (max-width: 767.98px) {
      .iconblock__text {
        padding-left: 10px; } }
    .iconblock__text a {
      color: #555; }
      .iconblock__text a:hover {
        color: #fa9308; }

.icon-badge {
  display: inline-block;
  border-radius: 27px;
  padding: 0 12px;
  height: 30px;
  font-size: 13px;
  line-height: 29px;
  color: #333;
  background: #efefef;
  margin: 2px 0;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }
  .icon-badge__icon {
    max-width: 20px;
    max-height: 20px;
    margin-right: 2px; }

.circle-iconblock {
  display: flex;
  margin-bottom: 30px; }
  .circle-iconblock__icon-place {
    flex: 0 0 45px;
    max-width: 45px;
    height: 45px;
    padding: 10px;
    border-radius: 50%;
    background: #fa9308;
    margin-right: 20px;
    text-align: center;
    line-height: 21px;
    color: #fff;
    font-size: 18px;
    overflow: hidden; }
  .circle-iconblock__icon {
    max-width: 100%;
    max-height: 100%; }
  .circle-iconblock__body {
    font-size: 12px; }

.page-section {
  margin-top: 15px; }
  .page-section__header {
    padding: 25px 0; }
  .page-section__title {
    font-size: 19px;
    color: #333; }
    @media (max-width: 599.98px) {
      .page-section__title {
        text-align: center; } }
  .page-section__body {
    padding-bottom: 25px; }
  .page-section_with-slider .page-section__header {
    padding-right: 80px; }
  .page-section_border {
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

.media-item {
  display: block;
  position: relative;
  min-height: 78px;
  text-decoration: none;
  color: #2f383d !important;
  border-top: 1px solid #f6f6f6;
  padding: 5px 0; }
  .media-item__row {
    display: flex;
    align-items: center;
    height: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .media-item__image-place {
    flex-shrink: 0;
    padding-left: 25px;
    width: 100px;
    height: 90px;
    line-height: 90px;
    text-align: left; }
  .media-item__image {
    display: inline-block;
    max-width: 100%;
    max-height: 100%; }
  .media-item__content {
    padding-left: 25px;
    line-height: 1.1; }
  .media-item__info {
    margin-top: 10px; }
  .media-item__price {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-right: 7px; }
  .media-item__price-discount {
    display: inline-block;
    font-size: 13px;
    line-height: 15px;
    font-weight: 400;
    color: #888;
    text-decoration: line-through;
    margin-right: 7px; }

.burger-btn {
  position: relative;
  display: inline-block;
  padding: 0;
  height: 40px;
  width: 40px;
  border: 0;
  background: transparent;
  vertical-align: middle; }
  .burger-btn__icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 21px;
    width: 21px;
    margin: -10.5px 0 0 -10.5px; }
    .burger-btn__icon_close {
      display: none; }
  .burger-btn:hover {
    color: #fa9308; }
  .burger-btn.active .burger-btn__icon_open {
    display: none; }
  .burger-btn.active .burger-btn__icon_close {
    display: block; }

.icon-link {
  display: inline-flex;
  align-items: flex-start;
  height: 24px;
  margin: 0 5px; }
  .icon-link__icon {
    position: relative;
    flex-shrink: 0;
    color: #222; }
    .icon-link__icon-img {
      display: inline-block;
      width: 24px;
      height: 24px;
      opacity: 0.5; }
  .icon-link__count {
    font-size: 11px;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    width: 20px;
    height: 20px;
    display: inline-block;
    line-height: 20px;
    position: absolute;
    top: -7px;
    left: 16px;
    box-shadow: 0 0 0 2px #fff;
    background: #b0b0b0; }
  .icon-link__value {
    flex-shrink: 0;
    margin-left: 6px; }
  .icon-link__title {
    display: block;
    font-size: 13px;
    line-height: 1.1;
    font-weight: 700;
    margin-bottom: 4px; }
  .icon-link__desc {
    display: block;
    font-size: 11px;
    line-height: 1.2;
    color: #999;
    max-width: 62px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .icon-link_only-icon .icon-link__value {
    display: none; }
  .icon-link_with-count .icon-link__icon {
    margin-right: 14px; }
  .icon-link:hover {
    color: #fa9308; }
    .icon-link:hover .icon-link__icon {
      color: #fa9308; }
  .icon-link.active .icon-link__icon-img {
    opacity: 1; }
  .icon-link.active .icon-link__count {
    background: #fa9308; }

.count-link {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center; }
  .count-link__icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 20px; }
  .count-link__count {
    display: inline-block;
    width: 17px;
    height: 17px;
    line-height: 17px;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
    color: #fff;
    background: #b0b0b0;
    font-weight: initial;
    margin-left: 7px;
    box-shadow: 0 0 0 2px #fff; }
  .count-link.active .count-link__count {
    background: #fa9308; }

.section {
  margin-bottom: 30px; }

/*
 * Header
 */
.header {
  position: relative; }
  .header-top {
    display: none;
    position: relative;
    border-bottom: 1px solid #f6f6f6; }
    @media (min-width: 992px) {
      .header-top {
        display: block; } }
    .header-top__container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 12px;
      padding-bottom: 12px; }
    .header-top__actions {
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end; }
  .header-middle {
    display: none; }
    @media (min-width: 992px) {
      .header-middle {
        display: block; } }
    .header-middle__container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .header-bottom {
    display: none;
    border-bottom: 1px solid #f6f6f6; }
    @media (min-width: 992px) {
      .header-bottom {
        display: block; } }
  .header-sticky {
    display: none;
    position: fixed;
    visibility: hidden;
    z-index: 1030;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.136);
    transform: translateY(-100%);
    transition: transform 300ms linear, visibility 0ms linear 300ms; }
    @media (min-width: 992px) {
      .header-sticky {
        display: block; } }
    .header-sticky_fixed {
      visibility: visible;
      transform: translateY(0%);
      transition: transform 300ms linear; }
    .header-sticky__row {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .header-sticky__actions {
      display: flex;
      margin: 0 -5px;
      padding-left: 10px; }
    .header-sticky__smartmenu-place {
      flex-grow: 1;
      padding: 0 25px; }
  .header-sticky-search {
    display: none !important; }
    @media (min-width: 600px) {
      .header-sticky-search {
        display: block !important; } }
    .header-sticky-search.show .header-sticky-search__wrapper {
      visibility: visible;
      transform: translateY(0%);
      transition: transform 300ms linear; }
    .header-sticky-search.show .header-sticky-search__results.show {
      display: block; }
    .header-sticky-search.show .header-sticky-search__backdrop {
      display: block; }
    .header-sticky-search__wrapper {
      position: fixed;
      visibility: hidden;
      z-index: 1030;
      top: 0;
      left: 0;
      width: 100%;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.136);
      transform: translateY(-100%);
      transition: transform 300ms linear, visibility 0ms linear 300ms;
      background: #fff; }
    .header-sticky-search__backdrop {
      position: fixed;
      display: none;
      z-index: 1029;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: rgba(58, 63, 68, 0.7); }
    .header-sticky-search__results {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      overflow: auto;
      max-height: 400px;
      background: #fff; }
      .header-sticky-search__results::-webkit-scrollbar {
        width: 8px;
        height: 8px; }
      .header-sticky-search__results::-webkit-scrollbar-track {
        border-radius: 8px; }
      .header-sticky-search__results::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #999;
        border: 2px solid #fff; }
      .header-sticky-search__results::-webkit-scrollbar-thumb:window-inactive {
        background: #999;
        border: 2px solid #fff; }

.header-logo {
  display: inline-block;
  width: 16.6%;
  max-width: 205px;
  height: 104px;
  line-height: 101px;
  padding-right: 15px;
  min-width: 150px; }
  .header-logo__img {
    max-height: 100%; }
  .header-logo_in-sticky {
    max-width: 170px;
    height: 40px;
    line-height: 40px; }

.header-city {
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  margin-left: 30px;
  padding: 0 10px 0 20px; }
  .header-city__title {
    font-weight: 700;
    color: #333;
    font-size: 14px; }
  .header-city__icon {
    position: absolute;
    left: 0;
    width: 17px;
    height: 17px; }
  .header-city__value {
    position: relative;
    display: block;
    padding-right: 14px;
    font-size: 12px; }
    .header-city__value:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 3px;
      border: 3px solid transparent;
      border-top-color: #333; }
  .header-city__dropdown-place {
    line-height: 0;
    font-size: 0;
    text-align: right; }
  .header-city__dropdown {
    display: inline-block;
    margin-right: 4px; }
    .header-city__dropdown .dropdown-menu {
      transform: translateX(-50%) translateY(10px) !important; }
      .header-city__dropdown .dropdown-menu:before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -7px;
        margin-bottom: -7px;
        display: inline-block;
        width: 15px;
        height: 15px;
        background: #fff;
        box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, 0.03);
        transform: rotateZ(45deg); }

.header-search {
  position: relative;
  flex-grow: 1;
  margin-left: 15px; }
  .header-search__input {
    padding: 0 44px 0 17px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    background: #f5f5f5;
    border: 1px solid #eee; }
    .header-search__input:focus {
      box-shadow: none;
      outline: none;
      background: #f5f5f5;
      border-color: #eee; }
  .header-search__btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 0 15px;
    border: 0;
    outline: 0 !important;
    box-shadow: none !important; }
    .header-search__btn-icon {
      width: 16px;
      height: 16px; }
  .header-search__results {
    display: none;
    position: absolute;
    z-index: 1001;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: auto;
    max-height: 400px;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); }
    .header-search__results::-webkit-scrollbar {
      width: 8px;
      height: 8px; }
    .header-search__results::-webkit-scrollbar-track {
      border-radius: 8px; }
    .header-search__results::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #999;
      border: 2px solid #fff; }
    .header-search__results::-webkit-scrollbar-thumb:window-inactive {
      background: #999;
      border: 2px solid #fff; }
    .header-search__results.show {
      display: block; }

.header-actions {
  display: flex;
  padding-left: 30px;
  margin: 0 -5px; }

.top-menu__item {
  font-size: 12px;
  margin-right: 20px; }
  .top-menu__item:first-child {
    margin-left: 0; }
  .top-menu__item.active .top-menu__link {
    color: #fa9308; }

.top-menu__link {
  color: #777;
  padding: 0; }

.top-call {
  display: inline-block;
  padding: 0 8px;
  line-height: 18px; }
  .top-call__phone {
    display: inline-block;
    padding: 0 7px;
    font-weight: 700; }
    .top-call__phone-icon {
      position: relative;
      top: -1px;
      display: inline-block;
      width: 15px;
      height: 13px;
      margin-right: 7px;
      vertical-align: middle; }
    .top-call__phone-value {
      color: #222; }
  .top-call__order {
    display: inline-block;
    padding: 0 7px;
    font-size: 12px; }

.top-login {
  display: inline-block;
  padding-left: 15px;
  color: #222;
  font-size: 12px;
  white-space: nowrap; }
  .top-login__icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin-right: 12px; }
  .top-login__value {
    display: inline-block;
    vertical-align: middle;
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis; }

.smartmenu {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #f6f6f6; }
  .smartmenu__list {
    display: flex;
    width: 100%; }
  .smartmenu-item {
    flex-grow: 1;
    max-width: 300px; }
    .smartmenu-item__link {
      display: block;
      position: relative;
      padding: 18px 18px 25px;
      height: 59px;
      color: #222;
      font-weight: 700;
      font-size: 15px;
      text-align: center; }
      .smartmenu-item__link_with-icon {
        padding-left: 50px; }
      .smartmenu-item.active .smartmenu-item__link, .smartmenu-item__link:hover {
        color: #fa9308; }
        .smartmenu-item.active .smartmenu-item__link .smartmenu-item__link-value:after, .smartmenu-item__link:hover .smartmenu-item__link-value:after {
          width: 100%; }
    .smartmenu-item__link-value {
      position: relative;
      display: inline-block;
      white-space: nowrap; }
      .smartmenu-item__link-value:after {
        content: "";
        position: absolute;
        left: 0;
        background: #fa9308;
        width: 0%;
        height: 2px;
        bottom: -19px;
        transition: width .15s ease-out; }
    .smartmenu-item__link-icon {
      position: absolute;
      display: block;
      top: 50%;
      left: 19px;
      width: 22px;
      height: 22px;
      transform: translateY(-50%); }
    .smartmenu-item_dropdown .smartmenu-item__link {
      padding-right: 50px; }
      .smartmenu-item_dropdown .smartmenu-item__link:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 20px;
        width: 14px;
        height: 14px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23222' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }
      .smartmenu-item_dropdown .smartmenu-item__link:hover:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
    .smartmenu-item_dropdown.active .smartmenu-item__link:after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
    .smartmenu-item_in-drop:hover .smartmenu-item-dropdown {
      display: block; }
    .smartmenu-item_in-drop .smartmenu-item__link {
      padding: 14px 29px 14px 19px;
      font-weight: 400;
      height: auto;
      line-height: 1.4;
      text-align: left; }
      .smartmenu-item_in-drop .smartmenu-item__link-value:after {
        display: none; }
      .smartmenu-item_in-drop .smartmenu-item__link:after {
        transform: rotateZ(-90deg);
        right: 7px;
        position: absolute;
        top: 50%;
        margin-top: -5px;
        height: 11px;
        width: 11px; }
    .smartmenu-item_dropdown-simple .smartmenu-item-dropdown {
      max-width: 200px; }
    .smartmenu-item_in-drop, .smartmenu-item_dropdown-simple {
      position: relative; }
      .smartmenu-item_in-drop .smartmenu-item-dropdown, .smartmenu-item_dropdown-simple .smartmenu-item-dropdown {
        top: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        max-width: none;
        max-height: none;
        margin-top: -2px;
        border-top: 2px solid #fa9308; }
        .smartmenu-item_in-drop .smartmenu-item-dropdown__container, .smartmenu-item_dropdown-simple .smartmenu-item-dropdown__container {
          display: block;
          padding: 0; }
        .smartmenu-item_in-drop .smartmenu-item-dropdown-item, .smartmenu-item_dropdown-simple .smartmenu-item-dropdown-item {
          display: block;
          max-width: 100%;
          margin: 0;
          padding: 0;
          min-height: 0; }
          .smartmenu-item_in-drop .smartmenu-item-dropdown-item__image, .smartmenu-item_dropdown-simple .smartmenu-item-dropdown-item__image {
            display: none; }
          .smartmenu-item_in-drop .smartmenu-item-dropdown-item__title, .smartmenu-item_dropdown-simple .smartmenu-item-dropdown-item__title {
            display: block;
            font-weight: 400;
            font-size: 15px;
            padding: 14px 29px 14px 19px; }
          .smartmenu-item_in-drop .smartmenu-item-dropdown-item-list, .smartmenu-item_dropdown-simple .smartmenu-item-dropdown-item-list {
            display: none; }
          .smartmenu-item_in-drop .smartmenu-item-dropdown-item:not(:first-child), .smartmenu-item_dropdown-simple .smartmenu-item-dropdown-item:not(:first-child) {
            border-top: 1px solid #f0f0f0; }
    .smartmenu-item_in-drop .smartmenu-item-dropdown {
      top: 0 !important;
      right: 100% !important;
      left: auto !important;
      margin-top: -1px;
      border-top: 1px solid #f2f2f2; }
    .smartmenu-item_in-drop:not(:first-child) {
      border-top: 1px solid #f0f0f0; }
    .smartmenu-item_in-sticky:not(.smartmenu-item_in-drop) .smartmenu-item__link {
      padding-left: 12px;
      padding-right: 12px; }
    .smartmenu-item_in-sticky:not(.smartmenu-item_in-drop).smartmenu-item_dropdown .smartmenu-item__link {
      padding-right: 40px; }
  .smartmenu-dropdown {
    flex-shrink: 0; }
    .smartmenu-dropdown__link {
      display: block;
      width: 60px;
      height: 59px;
      line-height: 59px;
      color: #222;
      text-align: center; }
    .smartmenu-dropdown__menu {
      padding: 0;
      min-width: 220px;
      white-space: nowrap;
      transform: none !important;
      top: 100% !important;
      right: 0 !important;
      left: auto !important;
      margin-top: -1px;
      border-radius: 0;
      border: none;
      border-top: 2px solid #fa9308; }
  .smartmenu_in-sticky {
    position: static;
    border-top: 0; }

.smartmenu-item-dropdown {
  left: 0 !important;
  width: 100%;
  max-height: 485px;
  top: 100% !important;
  transform: none !important;
  margin-top: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #f2f2f2;
  overflow: auto; }
  .smartmenu-item-dropdown::-webkit-scrollbar {
    width: 8px;
    height: 8px; }
  .smartmenu-item-dropdown::-webkit-scrollbar-track {
    border-radius: 8px; }
  .smartmenu-item-dropdown::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #999;
    border: 2px solid #fff; }
  .smartmenu-item-dropdown::-webkit-scrollbar-thumb:window-inactive {
    background: #999;
    border: 2px solid #fff; }
  .smartmenu-item-dropdown__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 25px 30px 10px; }
  .smartmenu-item-dropdown-item {
    position: relative;
    flex: 0 0 25%;
    max-width: 25%;
    margin: 0 0 30px;
    padding: 0 20px 0 80px;
    min-height: 60px;
    line-height: 1.4; }
    @media (max-width: 1199.98px) {
      .smartmenu-item-dropdown-item {
        flex: 0 0 33.33%;
        max-width: 33.33%; } }
    .smartmenu-item-dropdown-item__image {
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      height: 60px;
      background: left top / contain no-repeat; }
    .smartmenu-item-dropdown-item__title {
      display: block;
      font-weight: 700;
      font-size: 13px;
      white-space: normal;
      padding: 2px 29px 5px 19px;
      color: #222; }
    .smartmenu-item-dropdown-item-list {
      list-style: none;
      padding-left: 0;
      margin: 0; }
      .smartmenu-item-dropdown-item-list__item.active .smartmenu-item-dropdown-item-list__link {
        color: #fa9308; }
      .smartmenu-item-dropdown-item-list__link {
        display: inline-block;
        color: #666;
        font-size: 13px;
        padding: 4px 0 4px 20px; }
        .smartmenu-item-dropdown-item-list__link:hover {
          color: #fa9308; }
    .smartmenu-item-dropdown-item:hover .smartmenu-item-dropdown-item__title, .smartmenu-item-dropdown-item.active .smartmenu-item-dropdown-item__title {
      color: #fa9308; }

.search-row {
  display: flex;
  align-items: center; }
  .search-row__input-place {
    flex-grow: 1; }
  .search-row__actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding-right: 10px; }
  .search-row__input {
    width: 100%;
    height: 128px;
    background: #fff;
    border: 0;
    font-size: 28px;
    color: #444;
    padding: 0 200px 0 25px;
    box-shadow: none !important;
    outline: 0 !important; }
  .search-row__btn {
    font-size: 15px;
    font-weight: 700;
    padding: 9px 25px; }
  .search-row__close-button {
    margin-left: 30px; }

/*************************
 * Mobile Header and Side
 ************************/
.mobile-header__burger-btn {
  height: 100%;
  min-height: 58px;
  width: 58px; }

.mobile-header-logo {
  display: inline-block;
  width: 160px;
  height: 60px;
  line-height: 60px;
  padding-right: 5px; }
  .mobile-header-logo__img {
    max-width: 100%;
    max-height: 100%; }
  @media (max-width: 400px) {
    .mobile-header-logo {
      width: 112px; } }

@media (min-width: 992px) {
  .mobile-header {
    display: none; } }

.mobile-header-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5; }
  .mobile-header-line__actions {
    margin: 0 -5px;
    padding-left: 10px; }

.mobile-menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  max-width: 100%;
  z-index: 1030;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  transform: translateX(-100%);
  transition: transform .4s ease 0s, opacity .4s ease 0s; }
  .mobile-menu.show {
    transform: translateX(0); }
  .mobile-menu__scroller {
    overflow: hidden; }
  .mobile-menu__wrapper {
    transition: transform 200ms linear; }
  .mobile-menu-overlay {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    z-index: 1029;
    height: 100%;
    width: 100%;
    background: rgba(58, 63, 68, 0.7);
    z-index: 99; }
  .mobile-menu::-webkit-scrollbar {
    width: 8px;
    height: 8px; }
  .mobile-menu::-webkit-scrollbar-track {
    border-radius: 8px; }
  .mobile-menu::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #999;
    border: 2px solid #fff; }
  .mobile-menu::-webkit-scrollbar-thumb:window-inactive {
    background: #999;
    border: 2px solid #fff; }

.mobile-menu-list {
  padding: 0;
  margin: 0;
  list-style: none; }
  .mobile-menu-list__item {
    display: block;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #f2f2f2; }
    .mobile-menu-list__item_grey {
      background: #fbfbfb; }
    .mobile-menu-list__item_back .mobile-menu-list__link {
      padding-left: 57px; }
      .mobile-menu-list__item_back .mobile-menu-list__link:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 20px;
        width: 22px;
        height: 22px;
        margin-top: -12px;
        background: url("../vendors/feather/icons/arrow-left.svg") center/contain no-repeat; }
    .mobile-menu-list__item_title {
      background-color: #fbfbfb; }
      .mobile-menu-list__item_title .mobile-menu-list__link {
        font-weight: 700 !important;
        color: #fa9308; }
    .mobile-menu-list__item_parent > .mobile-menu-list__link {
      padding-right: 46px; }
      .mobile-menu-list__item_parent > .mobile-menu-list__link:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        margin: -3px 0 0 -3px;
        border: 3px solid transparent;
        border-left-color: currentColor; }
  .mobile-menu-list__link {
    padding: 19px 20px 20px 19px;
    display: block;
    position: relative;
    color: #222;
    font-weight: 700;
    font-size: 15px; }
    a.mobile-menu-list__link:hover {
      color: #fa9308; }
    .mobile-menu-list__link_active:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      width: 2px;
      height: 100%;
      background: #fa9308; }
  .mobile-menu-list__link-icon {
    position: relative;
    top: -2px;
    max-width: 17px;
    max-height: 17px;
    margin-right: 20px; }
  .mobile-menu-list_child {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%; }
    .mobile-menu-list_child .mobile-menu-list__link {
      font-weight: 400; }
      .mobile-menu-list_child .mobile-menu-list__link_active:before {
        height: 4px;
        width: 4px;
        left: 9px;
        border-radius: 50%; }
  .mobile-menu-list_expanded {
    display: block; }

.mobile-menu-block__title {
  font-weight: 700;
  padding: 19px 20px 6px 19px; }

.mobile-menu-block_socials {
  padding: 6px 0 30px; }

.mobile-contacts {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-bottom: 10px; }
  .mobile-contacts__item {
    list-style: none; }
  .mobile-contacts__link {
    display: block;
    padding: 12px 20px 0 50px;
    position: relative;
    line-height: 18px;
    font-size: 13px;
    color: #333; }
    a.mobile-contacts__link:hover {
      color: #fa9308; }
  .mobile-contacts__icon {
    display: inline-block;
    position: absolute;
    left: 20px;
    top: 12px;
    width: 18px;
    height: 18px; }
  .mobile-contacts__socials .mobile-contacts__icon {
    top: 11px;
    width: 20px;
    height: 20px; }

.footer {
  background: #f6f6f7; }
  .footer-main {
    padding: 34px 0 28px; }
    @media (max-width: 767.98px) {
      .footer-main {
        text-align: center; } }
  @media (max-width: 767.98px) {
    .footer-bottom {
      text-align: center; } }
  .footer-bottom__container {
    padding-top: 36px;
    padding-bottom: 30px;
    border-top: 1px solid #eee; }

.footer-block {
  margin-bottom: 20px; }
  .footer-block__title {
    color: #222;
    font-weight: 700;
    margin-bottom: 14px;
    font-size: 15px; }
    .footer-block__title a {
      color: #222; }
      .footer-block__title a:hover {
        color: #fa9308; }
  @media (min-width: 1200px) {
    .footer-block_contacts {
      padding-left: 12%; } }

.footer-list {
  padding: 0;
  margin: 0;
  list-style: none;
  line-height: 1.4; }
  .footer-list__item {
    list-style: none; }
    .footer-list__item.active .footer-list__link {
      color: #fa9308; }
  .footer-list__link {
    display: block;
    color: #555;
    margin-bottom: 10px; }
    .footer-list__link:hover {
      color: #fa9308; }

.footer-input-group__input {
  background: #fff;
  height: 42px; }
  .footer-input-group__input:focus {
    background: #fff; }

.footer-input-group__btn {
  height: 42px;
  width: 42px;
  line-height: 40px;
  padding: 0; }

.footer-input-group__icon {
  display: inline-block;
  width: 23px;
  height: 23px; }

.footer-subscribe {
  background: #f9f9f9; }
  @media (max-width: 767.98px) {
    .footer-subscribe {
      text-align: center; } }
  .footer-subscribe__container {
    position: relative;
    padding-top: 51px;
    padding-bottom: 51px; }
    .footer-subscribe__container:before {
      content: "";
      position: absolute;
      left: 15px;
      top: 0;
      width: 185px;
      height: 100%;
      background: url("../images/template/plane-black.png") -6px 0 no-repeat;
      opacity: .05; }
  .footer-subscribe__inner {
    max-width: 1089px;
    margin: 0 auto; }
  .footer-subscribe__text {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 700; }
    @media (max-width: 991.98px) {
      .footer-subscribe__text {
        margin-bottom: 20px; } }

.footer-slider__container {
  padding-top: 10px;
  padding-bottom: 25px; }

.footer-slider__header {
  padding-top: 25px;
  padding-right: 70px; }

.footer-slider__title {
  font-size: 18px; }

.footer-slider__slider .slick-list {
  margin: 0 -10px;
  padding: 0 5px; }

.footer-slider__slider .slick-track {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 0; }

.footer-slider__slider .slick-slide {
  position: relative;
  height: auto;
  margin: 0 5px; }
  .footer-slider__slider .slick-slide.slick-active:hover {
    z-index: 3; }

.footer-slider__item {
  background: #f0f0f1;
  box-shadow: inset 0 0 0 1px #eee; }

.socials-icons {
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 0; }
  .socials-icons__item {
    display: inline-block; }
  .socials-icons__link {
    display: block;
    height: 40px;
    width: 40px;
    margin: 0 10px 5px 0;
    overflow: hidden;
    border-radius: 100%;
    font-size: 0;
    background: url("../images/template/socials_sprite.png") no-repeat; }
    .socials-icons__link_ok {
      background-position: -4px -4px; }
    .socials-icons__link_vk {
      background-position: -253px -4px; }
    .socials-icons__link_fb {
      background-position: -53px -4px; }
    .socials-icons__link_ya {
      background-position: -404px -54px; }
    .socials-icons__link_tw {
      background-position: -104px -4px; }
    .socials-icons__link_mail {
      background-position: -3px -54px; }
    .socials-icons__link_inst {
      background-position: -154px -4px; }
    .socials-icons__link_google {
      background-position: -453px -4px; }
    .socials-icons__link_youtube {
      background-position: -204px -4px; }
    .socials-icons__link:hover {
      opacity: 0.8; }
  .socials-icons_in-footer {
    text-align: right; }
    @media (max-width: 767.98px) {
      .socials-icons_in-footer {
        text-align: center; } }

.pay-system-icon {
  display: inline-block;
  height: 20px;
  width: 34px;
  background: url("../images/template/pay_sprite.png") no-repeat; }
  .pay-system-icon_cash {
    background-position: 0px -1px; }
  .pay-system-icon_master {
    background-position: -42px -1px; }
  .pay-system-icon_visa {
    background-position: -89px -1px; }
  .pay-system-icon_sberbank {
    background-position: -259px -1px; }
  .pay-system-icon_alfa {
    background-position: -305px -1px; }
  .pay-system-icon_qiwi {
    background-position: -222px -1px;
    width: 21px; }
  .pay-system-icon_webmoney {
    background-position: -182px -1px;
    width: 21px; }
  .pay-system-icon_yandex {
    background-position: -142px -1px;
    width: 21px; }

.footer-contacts {
  padding: 0;
  margin: 0;
  list-style: none; }
  .footer-contacts__item {
    list-style: none; }
    @media (max-width: 767.98px) {
      .footer-contacts__item {
        display: inline-block;
        margin-left: 20px;
        margin-right: 20px; } }
  .footer-contacts__link {
    position: relative;
    display: block;
    color: #333;
    padding: 0 0 0 32px;
    margin-bottom: 22px; }
    a.footer-contacts__link:hover {
      color: #fa9308; }
    .footer-contacts__link:hover .footer-contacts__icon {
      color: currentColor; }
  .footer-contacts__icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    color: #000; }

.footer-pay-systems {
  padding: 0;
  margin: 0;
  list-style: none; }
  @media (min-width: 768px) {
    .footer-pay-systems {
      text-align: right; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .footer-pay-systems {
      max-width: 240px;
      margin-left: auto; } }
  @media (max-width: 767.98px) {
    .footer-pay-systems {
      margin-bottom: 20px; } }
  .footer-pay-systems__item {
    display: inline-block;
    margin: 0 11px 9px 0; }
    .footer-pay-systems__item:last-child {
      margin-right: 0; }

.footer__copyright {
  color: #888;
  padding-right: 20px;
  padding-top: 10px; }
  @media (max-width: 767.98px) {
    .footer__copyright {
      padding-right: 0;
      padding-bottom: 20px; } }

.page-title__place {
  word-wrap: break-word; }
  @media (max-width: 767.98px) {
    .page-title__place .page-title {
      text-align: center; } }
  @media (max-width: 599.98px) {
    .page-title__place .page-title {
      font-size: 26px; } }

.inner-page {
  padding-top: 30px;
  padding-bottom: 50px; }
  @media (max-width: 767.98px) {
    .inner-page {
      padding-bottom: 20px; } }
  .inner-page-header {
    padding-bottom: 30px;
    min-height: 60px; }
    .inner-page-header__inner {
      position: relative;
      padding-top: 5px; }
    .inner-page-header__actions {
      float: right; }
      @media (max-width: 767.98px) {
        .inner-page-header__actions {
          float: none;
          text-align: right; } }
    .inner-page-header__btn {
      position: relative;
      border: 0;
      padding: 12px; }

.l-page-grid {
  position: relative;
  display: flex; }
  .l-page-grid__side {
    display: none;
    flex: 0 0 258px;
    max-width: 258px;
    margin-right: 30px; }
    @media (min-width: 992px) {
      .l-page-grid__side {
        display: block; } }
    @media (max-width: 1299px) {
      .l-page-grid__side {
        flex-basis: 234px;
        max-width: 234px; } }
    @media (max-width: 1199.98px) {
      .l-page-grid__side {
        flex-basis: 210px;
        max-width: 210px; } }
    .l-page-grid__side_right {
      margin-right: 0;
      margin-left: 30px;
      order: 2; }
  .l-page-grid__main {
    flex: 1 1 1px;
    max-width: 100%; }
  .l-page-grid_with-sidebar .l-page-grid__main {
    max-width: 100%; }
    @media (min-width: 992px) {
      .l-page-grid_with-sidebar .l-page-grid__main {
        max-width: calc(100% - 258px - 30px); } }
    @media (max-width: 1299px) {
      .l-page-grid_with-sidebar .l-page-grid__main {
        max-width: calc(100% - 234px - 30px); } }
    @media (max-width: 1199.98px) {
      .l-page-grid_with-sidebar .l-page-grid__main {
        max-width: calc(100% - 210px - 30px); } }
    @media (max-width: 991.98px) {
      .l-page-grid_with-sidebar .l-page-grid__main {
        max-width: 100%; } }

.catalog-root-item {
  font-size: 13px;
  padding: 30px 30px;
  border: 1px solid #f2f2f2;
  height: 100%;
  margin: 0 0 -1px -1px;
  transition: box-shadow ease .2s, border ease-out .2s; }
  @media (max-width: 400px) {
    .catalog-root-item {
      padding: 22px 22px 17px;
      height: auto; } }
  .catalog-root-item__image-place {
    display: block;
    flex: 0 0 140px;
    max-width: 140px;
    padding: 0 25px 10px 0; }
    @media (max-width: 991.98px) {
      .catalog-root-item__image-place {
        max-width: 90px;
        flex-basis: 90px; } }
    @media (max-width: 400px) {
      .catalog-root-item__image-place {
        flex: auto;
        max-width: 100%;
        padding: 0;
        max-height: 120px;
        text-align: center; } }
  .catalog-root-item__image {
    max-width: 100%;
    max-height: 100%; }
  .catalog-root-item__header {
    display: flex; }
    @media (max-width: 400px) {
      .catalog-root-item__header {
        display: block; } }
  .catalog-root-item__name {
    display: inline-block;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.1;
    color: #222; }
  .catalog-root-item-sections {
    padding: 0;
    margin: 0;
    list-style: none; }
    .catalog-root-item-sections__item {
      display: inline-block;
      padding: 0 8px 0 0; }
    .catalog-root-item-sections__link {
      color: #666; }
      .catalog-root-item-sections__link:hover .catalog-root-item-sections__count {
        color: currentColor; }
    .catalog-root-item-sections__count {
      color: #222; }
  .catalog-root-item__desc {
    display: block;
    padding: 0;
    margin: 11px 0 0;
    color: #888;
    font-size: 13px; }
    @media (max-width: 400px) {
      .catalog-root-item__desc {
        display: none; } }
  .catalog-root-item:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-color: #fff; }

.side-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 30px; }
  .side-menu__item {
    position: relative;
    display: block; }
    .side-menu__item:hover .side-menu__link {
      color: #fa9308;
      background: #fff; }
      .side-menu__item:hover .side-menu__link:before {
        background: #fa9308; }
      .side-menu__item:hover .side-menu__link:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
    .side-menu__item:hover .side-menu-dropdown {
      display: flex; }
    .side-menu__item_dropdown .side-menu__link:after {
      content: "";
      display: block;
      position: absolute;
      width: 13px;
      height: 13px;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto 0;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23888' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }
    .side-menu__item.active .side-menu__link {
      background: #fa9308;
      color: #fff; }
      .side-menu__item.active .side-menu__link:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
  .side-menu__link {
    position: relative;
    display: block;
    border: 1px solid #f2f2f2;
    background: #f8f8f9;
    padding: 11px 31px 13px 17px;
    color: #333;
    font-size: 13px;
    cursor: pointer; }
    .side-menu__link:not(:last-child) {
      border-bottom-width: 0; }
    .side-menu__link:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 3px;
      height: 100%;
      background: transparent; }

.side-menu-dropdown {
  display: none;
  position: absolute;
  left: 100%;
  top: 2px;
  padding: 20px 0 20px;
  min-width: 500px;
  background: #fff;
  border: 0;
  margin-top: -1px;
  flex-wrap: wrap; }
  .side-menu-dropdown-item {
    position: relative;
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 0 0 100px;
    margin-bottom: 20px;
    min-height: 50px; }
    .side-menu-dropdown-item__link {
      display: block;
      position: relative;
      padding: 9px 0 0 0;
      font-weight: 700;
      font-size: 13px; }
      .side-menu-dropdown-item__link:hover {
        color: #333; }
    .side-menu-dropdown-item__image {
      position: absolute;
      left: 30px;
      top: 0;
      width: 50px;
      height: 50px;
      background: left top / contain no-repeat; }
    .side-menu-dropdown-item-list {
      padding: 0;
      margin: 0;
      margin-top: 9px;
      list-style: none; }
      .side-menu-dropdown-item-list__item {
        padding: 0 10px 0 0;
        display: inline-block; }
        .side-menu-dropdown-item-list__item.active .side-menu-dropdown-item-list__link {
          color: #fa9308; }
      .side-menu-dropdown-item-list__link {
        color: #555;
        line-height: 1.2; }
    .side-menu-dropdown-item.active .side-menu-dropdown-item__link {
      color: #333; }

.side-subs {
  border: 1px solid #f2f2f2;
  background: #f9f9f9;
  border-radius: 2px;
  position: relative;
  margin: 0 0 30px; }
  .side-subs__header {
    padding: 13px 20px 14px;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 700;
    color: #333; }
  .side-subs__body {
    background: #fff;
    padding: 10px; }
  .side-subs-list {
    padding: 0 10px;
    margin: 0;
    list-style: none;
    font-size: 13px; }
    .side-subs-list__item {
      padding: 3px 0; }
      .side-subs-list__item.active .side-subs-list__link {
        color: #fa9308; }
    .side-subs-list__link {
      display: block;
      position: relative;
      color: #333; }
      .side-subs-list__link:hover {
        color: #fa9308; }
    .side-subs-list__name {
      padding-right: 30px;
      display: inline-block; }
    .side-subs-list__value {
      position: absolute;
      right: 0;
      top: 0;
      width: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right; }

.side-form {
  margin-bottom: 30px;
  padding: 17px 20px 22px;
  border-radius: 2px; }
  .side-form__title {
    font-size: 16px;
    font-weight: 700; }
  .side-form__text {
    font-size: 13px;
    line-height: 1.2;
    opacity: 0.8; }
  .side-form__input-group {
    margin-top: 11px; }
  .side-form__ps {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.2; }
  .side-form_subscribe {
    position: relative;
    z-index: 1;
    background: #ee8b05;
    color: #fff; }
    .side-form_subscribe:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../images/template/envelope.png") 113px 17px no-repeat;
      opacity: 0.2; }
    .side-form_subscribe .side-form__ps a {
      color: #eee; }
      .side-form_subscribe .side-form__ps a:hover {
        color: #fff; }

.side-news {
  margin: 0 0 30px;
  padding: 13px 0 0;
  border: 1px solid #f2f2f2;
  border-radius: 2px; }
  .side-news__header {
    padding: 0 19px 12px;
    margin: 0; }
  .side-news__title {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.2; }
  .side-news__link {
    float: right; }
  .side-news-list-item {
    position: relative;
    padding: 16px 19px 18px;
    margin: 0 0 0;
    overflow: hidden;
    border-bottom: 1px solid #ebeef1;
    color: #666;
    font-size: 13px; }
    .side-news-list-item:first-child {
      padding-top: 0; }
    .side-news-list-item:last-child {
      border-bottom-width: 0; }
    .side-news-list-item:before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 2px;
      background: #fa9308;
      bottom: 0;
      transition: top .3s ease; }
    .side-news-list-item__text {
      display: block; }
    .side-news-list-item:hover:before {
      top: 0; }

.panel {
  background: #fff;
  border: 1px solid #f2f2f2;
  margin-bottom: 20px;
  margin-top: -1px; }
  .panel__header {
    position: relative;
    padding: 29px 19px 29px 79px;
    margin-bottom: -1px; }
  .panel__title {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 600;
    color: #333; }
    @media (max-width: 599.98px) {
      .panel__title {
        font-size: 18px; } }
  .panel__icon {
    position: absolute;
    left: 27px;
    top: 23px;
    width: 30px;
    height: 30px;
    color: #fa9308; }
  .panel__content {
    padding: 0 29px 25px 79px;
    font-size: 15px; }
    @media (max-width: 991.98px) {
      .panel__content {
        padding-left: 29px; } }
  .panel__body {
    padding: 25px; }
  .panel_gray .panel__header {
    background: #fbfbfb;
    border-bottom: 1px solid #f2f2f2; }

.simple-text-block blockquote {
  position: relative;
  margin: 43px 0 45px;
  min-height: 40px;
  padding-left: 25px;
  vertical-align: middle;
  border: none; }
  .simple-text-block blockquote:before {
    position: absolute;
    left: 0;
    bottom: 3px;
    content: " ";
    display: block;
    top: 3px;
    width: 3px;
    background: #fa9308; }

.simple-text-block h2, .simple-text-block h3, .simple-text-block h4, .simple-text-block h5, .simple-text-block h6 {
  margin: 40px 0 26px; }
  @media (max-width: 767.98px) {
    .simple-text-block h2, .simple-text-block h3, .simple-text-block h4, .simple-text-block h5, .simple-text-block h6 {
      text-align: center; } }

.simple-text-block p {
  margin-bottom: 20px; }

.simple-text-block ul {
  list-style: none;
  padding: 0;
  padding-left: 15px;
  margin-bottom: 27px; }
  .simple-text-block ul ul, .simple-text-block ul ol {
    padding-left: 0; }
  .simple-text-block ul > li {
    margin: 0 0 4px;
    padding-left: 20px;
    position: relative; }
    .simple-text-block ul > li:before {
      content: "";
      position: absolute;
      width: 7px;
      height: 7px;
      left: 0;
      top: 0.6em;
      border-radius: 50%;
      background: #fa9308; }

.simple-text-block ol {
  list-style: none;
  padding: 0;
  padding-left: 15px;
  counter-reset: li;
  margin-bottom: 27px; }
  .simple-text-block ol ul, .simple-text-block ol ol {
    padding-left: 0; }
  .simple-text-block ol > li {
    margin: 0 0 4px;
    padding-left: 20px;
    position: relative;
    counter-increment: li; }
    .simple-text-block ol > li:before {
      content: counter(li) ". ";
      position: absolute;
      width: 20px;
      top: 0;
      left: 0;
      border-radius: 50%; }

.simple-text-block ol ol, .simple-text-block ol ul, .simple-text-block ul ol, .simple-text-block ul ul {
  margin-bottom: 0; }

.simple-text-block figure {
  margin: 30px 0; }

.simple-text-block figcaption {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  color: #999; }

.item-card {
  position: relative;
  background: #fff; }
  .item-card__image-place {
    width: 100%;
    margin: 0 auto; }
  .item-card__img {
    display: inline-block;
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .item-card__body {
    padding: 20px 0 22px; }
  .item-card__title {
    font-weight: 700;
    margin-bottom: 6px;
    line-height: 1.3;
    word-wrap: break-word; }
    .item-card__title a {
      color: #333; }
  .item-card__text {
    font-size: 13px;
    margin-top: 9px;
    line-height: 1.7; }
  .item-card__date {
    font-size: .929em;
    color: #9a9a9a;
    font-weight: 400;
    margin-top: 3px; }
  .item-card__margin {
    margin-bottom: 30px; }
  .item-card__gradient {
    display: none;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 100%); }
  .item-card:hover {
    z-index: 1; }
    .item-card:hover .item-card__title a {
      color: #fa9308; }
  .item-card_card {
    box-shadow: 0 0 0 1px #f2f2f2;
    transition: box-shadow ease .1s, border-color ease .1s; }
    .item-card_card .item-card__body {
      padding: 20px; }
    .item-card_card:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }
  .item-card_card-2 {
    box-shadow: 0 0 0 1px #f2f2f2;
    transition: box-shadow ease .1s, border-color ease .1s; }
    .item-card_card-2 .item-card__body {
      padding: 20px; }
    .item-card_card-2 .item-card__date {
      margin-top: 0;
      margin-bottom: 4px; }
    .item-card_card-2:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }
  .item-card_split {
    display: flex;
    border: 1px solid #f2f2f2;
    padding: 30px;
    transition: box-shadow .3s ease;
    margin-right: -1px; }
    @media (max-width: 767.98px) {
      .item-card_split {
        display: block; } }
    .item-card_split .item-card__image-place {
      flex: 0 0 165px;
      max-width: 165px;
      margin-right: 30px; }
      @media (max-width: 767.98px) {
        .item-card_split .item-card__image-place {
          flex: auto;
          max-width: 100%;
          width: 165px;
          margin-right: 0;
          margin-left: 0; } }
    .item-card_split .item-card__body {
      padding-top: 0;
      padding-bottom: 0;
      flex: 1 1 1px; }
      @media (max-width: 767.98px) {
        .item-card_split .item-card__body {
          flex: auto;
          width: 100%;
          padding-top: 10px; } }
    .item-card_split:hover {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }
  .item-card_blog .item-card__img {
    width: 100%;
    max-width: 100%; }
  .item-card_blog .item-card__image-place {
    overflow: hidden; }
  .item-card_blog-first .item-card__title {
    font-size: 18.6px; }
  .item-card_blog-first .item-card__text {
    font-size: 14px;
    margin-top: 16px;
    margin-bottom: 9px; }
  .item-card_blog-first .item-card__image-place {
    max-height: 428px;
    overflow: hidden; }
  .item-card_index-first {
    position: relative;
    z-index: 1;
    background-position: center;
    background-size: cover; }
    @media (min-width: 992px) {
      .item-card_index-first {
        padding-bottom: 70%; }
        .item-card_index-first .item-card__gradient {
          display: block; }
        .item-card_index-first .item-card__image-place {
          display: none; }
        .item-card_index-first .item-card__title {
          font-size: 19px;
          font-weight: 700; }
          .item-card_index-first .item-card__title a {
            color: #fff !important; }
        .item-card_index-first .item-card__date {
          padding-top: 8px;
          color: #fff; }
        .item-card_index-first .item-card__body {
          position: absolute;
          left: 0;
          bottom: 0; } }
    @media (min-width: 1200px) {
      .item-card_index-first {
        padding-bottom: 65%; } }
    @media (max-width: 991.98px) {
      .item-card_index-first {
        background-image: none !important; } }

.item-row {
  position: relative;
  display: flex;
  padding: 14px;
  border: 1px solid #eee;
  transition: box-shadow ease-out .2s, border ease-out .2s;
  margin-bottom: -1px;
  border-radius: 4px;
  background: #fff; }
  .item-row__image-place {
    flex: 0 0 91px;
    max-width: 91px;
    height: 91px;
    line-height: 91px;
    margin: 0 auto; }
  .item-row__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle; }
  .item-row__body {
    flex: 1 1 1px;
    padding: 10px 0 0 15px; }
  .item-row__title {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 6px;
    max-height: 57px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: -o-ellipsis-lastline;
    -webkit-line-clamp: 50;
    -webkit-box-orient: vertical; }
    .item-row__title a {
      color: #333; }
  .item-row__price {
    font-weight: 700;
    margin-top: 8px; }
  .item-row__margin {
    margin-bottom: 30px; }
  .item-row:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
    z-index: 2; }
    .item-row:hover .item-row__title a {
      color: #fa9308; }

.item {
  height: 100%;
  padding: 30px 10px 30px;
  border: 1px solid #f2f2f2;
  text-align: center;
  transition: all ease .2s;
  margin: 0 0 0 -1px; }
  .item__wrapper {
    margin-bottom: -1px; }
  .item__image-place {
    width: 120px;
    height: 120px;
    line-height: 120px;
    max-width: 100%;
    margin: 0 auto;
    text-align: center; }
  .item__img {
    max-width: 100%;
    max-height: 100%; }
  .item__title {
    font-weight: 700;
    font-size: 1.071em;
    line-height: 20px;
    word-break: break-word;
    margin-top: 22px; }
  .item__margin {
    margin-bottom: 30px; }
  .item_thin .item__title {
    font-weight: 400; }
  .item:hover {
    border-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }
  .item_brand {
    padding-top: 50px;
    padding-bottom: 50px; }
    .item_brand .item__image-place {
      width: 100%;
      height: 60px;
      line-height: 60px; }

.tizer-card {
  position: relative;
  padding-bottom: 33.959%;
  background: center / cover no-repeat;
  font-size: 15px;
  overflow: hidden; }
  .tizer-card__container {
    display: flex;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .tizer-card__caption {
    color: #333;
    padding-left: 8.4%;
    margin-bottom: 0.4em; }
  .tizer-card__title {
    font-size: 1.5em;
    line-height: 1.2;
    margin-bottom: 0.2em; }
  .tizer-card__sub {
    font-size: 1.15em;
    line-height: 1.2; }
  .tizer-card_square {
    padding-bottom: 73.5%; }
    .tizer-card_square .tizer-card__container {
      align-items: flex-start; }
    .tizer-card_square .tizer-card__caption {
      margin-top: 1.85em;
      padding-right: 6%;
      padding-left: 9.2%; }
    .tizer-card_square .tizer-card__title {
      font-size: 2.15em; }
    .tizer-card_square .tizer-card__sub {
      font-size: 1.4em; }
  .tizer-card.light .tizer-card__caption {
    color: #fff; }

.menu-tabs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -11px 40px; }
  .menu-tabs:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 11px;
    right: 11px;
    height: 1px;
    background: #f2f2f2; }
  .menu-tabs__item {
    display: inline-block;
    margin: 0 11px; }
    .menu-tabs__item.active .menu-tabs__link {
      border-bottom-color: #fa9308;
      color: #fa9308; }
  .menu-tabs__link {
    display: block;
    padding: 10px 0 24px;
    border-bottom: 2px solid transparent;
    color: #333;
    line-height: 1; }
    a.menu-tabs__link:hover {
      color: #fa9308; }

.brand-block {
  border: 1px solid #f2f2f2; }
  .brand-block__image-place {
    display: inline-block;
    border: 1px solid #f2f2f2;
    padding: 30px 36px 30px 28px;
    margin: -1px; }
  .brand-block__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%; }
  .brand-block__body {
    padding: 28px 28px 19px 28px;
    color: #666;
    font-size: .933em; }
  .brand-block__text {
    padding: 0 0 15px 0; }
  .brand-block-list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .brand-block-list__item {
      padding: 0 0 8px 15px;
      position: relative; }
      .brand-block-list__item:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 14px;
        height: 14px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23919191' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }

.reviews {
  margin-bottom: 30px; }
  .reviews-note {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 0 0 20px; }
  .reviews-item {
    padding: 20px 25px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 13px;
    background: #f5f5f5; }
    .reviews-item__name {
      display: inline-block;
      font-weight: 700;
      color: #333; }
    .reviews-item__date {
      display: inline-block;
      margin-left: 11px;
      font-size: 13px;
      line-height: 1.1;
      color: #777; }
    .reviews-item__body {
      margin: 10px 0 14px; }
    .reviews-item__footer-link {
      display: inline-block;
      font-size: 13px;
      margin-right: 10px;
      color: #999; }
      .reviews-item__footer-link:hover {
        color: #fa9308; }
    .reviews-item-images__item {
      display: inline-block;
      margin-right: 6px;
      margin-top: 6px;
      width: 30px;
      height: 30px;
      background: center / cover no-repeat;
      border: 1px solid #f2f2f2;
      border-radius: 2px; }
  .reviews-form__btn {
    margin-bottom: 15px; }
  .reviews-form__footer {
    margin-top: 20px; }

.catalog-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px; }
  .catalog-header__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  @media (max-width: 599.98px) {
    .catalog-header .view-types {
      display: none; } }

.catalog-no-products {
  position: relative;
  padding: 10px 0 15px 97px;
  min-height: 70px;
  margin-left: 30px; }
  @media (max-width: 767.98px) {
    .catalog-no-products {
      margin-left: 0;
      padding-left: 70px; } }
  .catalog-no-products__wrapper {
    padding-top: 30px;
    border-top: 1px solid #f2f2f2; }
  .catalog-no-products__icon {
    content: "";
    position: absolute;
    width: 54px;
    height: 54px;
    line-height: 52px;
    text-align: center;
    background: #fa9308;
    color: #fff;
    left: 0;
    top: 8px;
    border-radius: 100%; }
  .catalog-no-products__icon-img {
    display: inline-block;
    width: 35px;
    height: 35px;
    stroke-width: 3; }
  .catalog-no-products__title {
    font-size: 19px;
    font-weight: 300;
    line-height: 1.1; }
  .catalog-no-products__text {
    font-size: 15px; }

.catalog-footer {
  padding-top: 15px;
  padding-bottom: 40px; }

.catalog__show-more {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #f2f2f2;
  text-align: center; }
  .catalog__show-more:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 0.3em;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    vertical-align: baseline;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='23 4 23 10 17 10'%3E%3C/polyline%3E%3Cpath d='M20.49 15a9 9 0 1 1-2.12-9.36L23 10'%3E%3C/path%3E%3C/svg%3E") center/100% 100% no-repeat;
    transition: transform .5s ease; }
  .catalog__show-more:hover {
    border-color: #d2d2d4;
    color: #fa9308; }
    .catalog__show-more:hover:before {
      transform: rotateZ(360deg); }
  .catalog__show-more.loading:before {
    animation: spinner 0.5s linear infinite; }

.catalog__pagination {
  justify-content: center; }

.catalog-show-count {
  padding: 5px 0; }

.catalog__col {
  margin-bottom: -1px; }

@keyframes spinner {
  from {
    transform: rotateZ(0deg); }
  to {
    transform: rotateZ(360deg); } }

.sorts {
  padding: 7px 0; }
  .sorts-item {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    padding-right: 20px;
    color: #a5a3a3; }
    @media (max-width: 767.98px) {
      .sorts-item {
        margin-right: 10px; } }
    @media (max-width: 599.98px) {
      .sorts-item {
        margin-right: 5px;
        padding-right: 17px; } }
    @media (max-width: 400px) {
      .sorts-item {
        margin-right: 0px; } }
    .sorts-item__name {
      display: inline-block; }
      @media (max-width: 767.98px) {
        .sorts-item__name {
          display: none; } }
    .sorts-item__icon {
      display: none;
      width: 18px;
      height: 18px; }
      @media (max-width: 767.98px) {
        .sorts-item__icon {
          display: inline-block; } }
    .sorts-item:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 4px;
      margin: auto 0;
      height: 13px;
      width: 13px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23a5a3a3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }
    .sorts-item:hover, .sorts-item.active {
      color: #333; }
      .sorts-item:hover:after, .sorts-item.active:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
    .sorts-item_asc:after {
      transform: rotateZ(180deg); }

.view-types {
  white-space: nowrap; }
  .view-types-btn {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    margin: 0 0 0 8px;
    vertical-align: top;
    text-align: center;
    background: #f5f5f5;
    color: #333;
    border-radius: 2px; }
    @media (max-width: 599.98px) {
      .view-types-btn {
        margin-left: 0; } }
    @media (max-width: 400px) {
      .view-types-btn {
        width: 30px;
        height: 30px;
        line-height: 30px; } }
    .view-types-btn__icon {
      width: 17px;
      height: 17px; }
    .view-types-btn.active {
      background: #fa9308;
      color: #fff; }

.good-marker {
  position: relative;
  padding: 22px 0 19px 58px;
  font-size: 13px;
  border-top: 1px solid #f2f2f2;
  min-height: 90px; }
  .good-marker__icon {
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    background-color: #4fad00;
    left: 0;
    top: 26px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50%; }
  .good-marker__title {
    font-size: 15px;
    font-weight: 700; }
  .good-marker__text {
    margin: 5px 0 4px;
    color: #666; }
  .good-marker_hit .good-marker__icon {
    background-color: #2992d9; }
  .good-marker_sale .good-marker__icon {
    background-color: #e52929; }
  .good-marker_recommend .good-marker__icon {
    background-color: #893ca9; }

.catalog-item__view-block {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 10px 0 0; }

.catalog-item__quantity-place {
  width: 40px; }

.catalog-item__countdown-title, .catalog-item__quantity-title {
  margin: 0 0 5px;
  font-size: 11px;
  line-height: 1.3;
  color: #666; }

.catalog-item__countdown {
  width: 8em;
  border-radius: 2px 0 0 2px; }
  .catalog-item__countdown .countdown-item:nth-child(3):before, .catalog-item__countdown .countdown-item:nth-child(3):after {
    display: none; }

.catalog-item-sale {
  font-size: 0; }
  .catalog-item-sale__value {
    display: inline-block;
    background: #e32330;
    color: #fff;
    border-radius: 3px;
    padding: 5px 8px 4px;
    margin: 3px 2px;
    font-size: 12px;
    line-height: 1; }
  .catalog-item-sale__name {
    display: inline-block;
    background: #f6bc00;
    padding: 5px 8px 4px;
    border-radius: 3px;
    margin: 3px 2px;
    font-size: 12px;
    line-height: 1; }

.catalog-item-store {
  display: inline-block;
  margin: 0 6px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.2;
  color: #666; }
  .catalog-item-store__icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin: -2px 3px 0 0;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234fad00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }
  .catalog-item-store__text {
    display: inline-block; }
  .catalog-item-store_little .catalog-item-store__icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-trending-down'%3E%3Cpolyline points='23 18 13.5 8.5 8.5 13.5 1 6'%3E%3C/polyline%3E%3Cpolyline points='17 18 23 18 23 12'%3E%3C/polyline%3E%3C/svg%3E"); }
  .catalog-item-store_missing .catalog-item-store__icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e32330' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E"); }

.catalog-item__char {
  display: inline-block;
  font-size: 12px;
  line-height: 1.2;
  color: #999;
  margin: 0 6px; }

.catalog-item__price {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 8px;
  margin-right: 7px;
  margin-left: 7px;
  white-space: nowrap; }
  .catalog-item__price_discount {
    font-size: 13px;
    line-height: 1;
    font-weight: 400;
    color: #888;
    text-decoration: line-through; }

.catalog-item-actions-btn {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 0 5px;
  text-align: center;
  transition: opacity .3s ease;
  font-size: 13px;
  opacity: .7; }
  .catalog-item-actions-btn__icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: #fff center / 16px 16px no-repeat;
    opacity: 1;
    border-radius: 50%; }
    .catalog-item-actions-btn__icon_favorite {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'%3E%3C/path%3E%3C/svg%3E"); }
    .catalog-item-actions-btn__icon_compare {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='20' x2='18' y2='10'%3E%3C/line%3E%3Cline x1='12' y1='20' x2='12' y2='4'%3E%3C/line%3E%3Cline x1='6' y1='20' x2='6' y2='14'%3E%3C/line%3E%3C/svg%3E"); }
  .catalog-item-actions-btn__name {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px; }
  .catalog-item-actions-btn:hover {
    cursor: pointer;
    opacity: 1; }
    .catalog-item-actions-btn:hover .catalog-item-actions-btn__icon {
      opacity: 1;
      background-color: #e3e3e3; }
  .catalog-item-actions-btn.active {
    opacity: 1; }
    .catalog-item-actions-btn.active .catalog-item-actions-btn__icon {
      background-color: #fa9308; }
      .catalog-item-actions-btn.active .catalog-item-actions-btn__icon_favorite {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z'%3E%3C/path%3E%3C/svg%3E"); }
      .catalog-item-actions-btn.active .catalog-item-actions-btn__icon_compare {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='20' x2='18' y2='10'%3E%3C/line%3E%3Cline x1='12' y1='20' x2='12' y2='4'%3E%3C/line%3E%3Cline x1='6' y1='20' x2='6' y2='14'%3E%3C/line%3E%3C/svg%3E"); }
    .catalog-item-actions-btn.active .catalog-item-actions-btn__name {
      color: #fa9308; }

.catalog-item-props {
  margin-bottom: 8px; }
  .catalog-item-props:last-child {
    margin-bottom: 0; }
  .catalog-item-props__title {
    color: #888;
    font-size: 12px; }
  .catalog-item-props-list {
    margin: 4px 0 0;
    padding: 0;
    list-style: none;
    font-size: 0; }
    .catalog-item-props-list__item {
      display: inline-block;
      padding: 7px 10px;
      margin: 0 5px 5px 0;
      border: 1px solid #888;
      border-radius: 2px;
      font-size: 11px;
      text-align: center;
      cursor: pointer; }
      .catalog-item-props-list__item.active {
        color: #FFF;
        border-color: #fa9308;
        background-color: #fa9308; }
      .catalog-item-props-list__item_color {
        padding: 0;
        width: 26px;
        height: 26px;
        border: 0;
        vertical-align: top;
        box-shadow: 0 0 0 1px #f2f2f2 inset; }
        .catalog-item-props-list__item_color.active {
          box-shadow: 0 0 0 1px #fff inset, 0px 0px 0px 2px #fa9308; }

.catalog-item-chars__title {
  display: block;
  margin-top: 10px;
  font-size: 13px;
  color: #666; }
  a.catalog-item-chars__title:hover {
    color: #fa9308; }
  .catalog-item-chars__title.collapsed .catalog-item-chars__arrow {
    transform: rotateZ(180deg); }

.catalog-item-chars__arrow {
  position: relative;
  top: -2px;
  display: inline-block;
  border: 3px solid transparent;
  border-top-width: 0;
  border-bottom-color: currentColor;
  margin-left: 3px;
  vertical-align: middle; }

.catalog-item-chars-list {
  padding: 10px 0 0;
  margin: 0;
  font-size: 13px;
  color: #666; }
  .catalog-item-chars-list-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 2px 0 2px;
    min-height: 26px;
    word-wrap: break-word; }
    .catalog-item-chars-list-item:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      height: 1px;
      border-top: 1px dotted #ccc; }
    .catalog-item-chars-list-item__name {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-right: 10px;
      background: #fff; }
    .catalog-item-chars-list-item__value {
      position: relative;
      z-index: 1;
      display: inline;
      padding-left: 10px;
      background: #fff;
      text-align: right; }
  .catalog-item-chars-list_column .catalog-item-chars-list-item {
    display: block;
    margin-bottom: 9px; }
    .catalog-item-chars-list_column .catalog-item-chars-list-item:before {
      display: none; }
    .catalog-item-chars-list_column .catalog-item-chars-list-item__name {
      display: block;
      padding-right: 0;
      text-align: left;
      color: #888; }
    .catalog-item-chars-list_column .catalog-item-chars-list-item__value {
      display: block;
      padding-left: 0;
      text-align: left;
      color: #333; }

.catalog-item__buy-total {
  margin-top: 9px;
  font-size: 13px;
  color: #666; }

.catalog-item__description {
  font-size: 13px;
  color: #888; }

.catalog-item-missing__btn {
  opacity: 0.8; }
  .catalog-item-missing__btn:hover {
    opacity: 1;
    background-color: #fa9308;
    border-color: #fa9308;
    color: #fff; }

.catalog-item-missing__text {
  color: #b3b3b3;
  font-size: 11px;
  line-height: 1.2;
  margin: 16px 0 0; }

.catalog-item__fast-view {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 5;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: rgba(229, 229, 229, 0.9);
  text-decoration: none;
  border-radius: 2px;
  cursor: pointer;
  color: #666;
  font-size: 13px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all .2s ease-in-out;
  padding: 0 14px 0 36px; }
  .catalog-item__fast-view:before {
    content: "";
    opacity: .5;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='11' y1='8' x2='11' y2='14'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E") center/100% 100% no-repeat; }
  .catalog-item__fast-view:hover {
    color: #666; }

.catalog-item-table-sizes {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  font-size: 13px;
  line-height: 1.2;
  color: #fa9308; }
  .catalog-item-table-sizes:before {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 19px;
    height: 19px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpath d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3'%3E%3C/path%3E%3Cline x1='12' y1='17' x2='12' y2='17'%3E%3C/line%3E%3C/svg%3E") center/100% 100% no-repeat; }
  .catalog-item-table-sizes:hover {
    color: #333; }
  .catalog-item-table-sizes__title {
    display: inline-block;
    border-bottom: 1px dotted currentColor; }

.catalog-item-cheaper {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  font-size: 13px;
  line-height: 1.2;
  color: #666; }
  .catalog-item-cheaper:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 17px;
    height: 18px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='1' y='4' width='22' height='16' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='1' y1='10' x2='23' y2='10'%3E%3C/line%3E%3C/svg%3E") center/100% 100% no-repeat; }
  .catalog-item-cheaper:hover {
    color: #333; }
  .catalog-item-cheaper__title {
    display: inline-block;
    border-bottom: 1px dotted currentColor; }

.catalog-item-favorite-to-cart {
  position: relative;
  margin-bottom: 10px;
  padding-left: 40px; }
  .catalog-item-favorite-to-cart__icon {
    position: absolute;
    top: -4px;
    left: 0; }

.catalog-item-quantity-item {
  display: inline-block;
  position: relative;
  height: 2.8666em;
  width: 2.666em;
  padding: 0.4666em 0 0;
  margin: 0;
  vertical-align: top;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  background: #fa9308;
  border-radius: 0;
  line-height: 1;
  color: #fff; }
  .catalog-item-quantity-item__text {
    opacity: .6;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 1.2;
    display: block;
    width: auto;
    text-transform: lowercase; }

.counter {
  display: inline-flex;
  vertical-align: middle;
  white-space: nowrap;
  font-size: 0;
  background: #f3f3f5; }
  .counter__btn {
    display: inline-block;
    line-height: 36px;
    height: 37px;
    width: 24px;
    cursor: pointer;
    border: 0;
    font-size: 0;
    background: transparent; }
    .counter__btn_minus {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E") center/13px 13px no-repeat; }
    .counter__btn_plus {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='12' y1='5' x2='12' y2='19'%3E%3C/line%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E") center/15px 15px no-repeat; }
    .counter__btn:hover {
      opacity: 0.8; }
    .counter__btn:active {
      box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05); }
  .counter__input {
    display: inline-block;
    width: 34px;
    border: 0;
    border-left: 1px solid #f3f3f5;
    border-right: 1px solid #f3f3f5;
    background: #f3f3f5;
    color: #666;
    font-size: 13px;
    height: 37px;
    border-radius: 0;
    text-align: center;
    line-height: 13px;
    -moz-appearance: textfield;
    /* Firefox */ }
    .counter__input::-webkit-inner-spin-button, .counter__input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .counter__input:focus {
      outline: none; }
  .counter-lg .counter__input {
    height: 40px;
    width: 65px;
    font-size: 14px; }
  .counter-lg .counter__btn {
    width: 28px;
    height: 40px; }
    .counter-lg .counter__btn_plus {
      background-size: 17px 17px;
      background-position: center left 2px; }
    .counter-lg .counter__btn_minus {
      background-size: 17px 17px;
      background-position: center right 2px; }

.stickers__item {
  display: inline-block;
  position: relative;
  width: auto;
  margin: 0 3px 6px;
  padding: 4px 10px 5px;
  border-radius: 2px;
  font-size: 10px;
  line-height: 12px;
  background-color: #4fad00;
  letter-spacing: .5px;
  color: #fff;
  text-transform: uppercase; }
  .stickers__item_hit {
    background-color: #2992d9; }
  .stickers__item_sale {
    background-color: #e52929; }
  .stickers__item_recommend {
    background-color: #893ca9; }
  .stickers__item_archive {
    background-color: #ccc; }
  .stickers__item_event {
    background-color: #fa9308; }
  .stickers__item_bonus {
    background-color: #893ca9; }
  .stickers__item_action {
    background-color: #004085; }

.markers__item {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 3px 6px;
  padding: 0;
  font-size: 13px;
  font-weight: 700;
  line-height: 40px;
  background-color: #4fad00;
  letter-spacing: .5px;
  color: #fff;
  text-transform: uppercase;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  transition: all 250ms ease; }
  .markers__item_hit {
    background-color: #2992d9; }
  .markers__item_sale {
    background-color: #e52929; }
  .markers__item_recommend {
    background-color: #893ca9; }

.rating {
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  font-size: 0; }
  .rating__star {
    display: inline-block;
    width: 15px;
    height: 14px;
    overflow: hidden;
    margin: 0 1px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23cccccc' stroke='%23cccccc' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'%3E%3C/polygon%3E%3C/svg%3E") center/100% 100% no-repeat; }
    .rating__star.active {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23f6bc00' stroke='%23f6bc00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2'%3E%3C/polygon%3E%3C/svg%3E"); }
  .rating-lg .rating__star {
    width: 17px;
    height: 17px; }

.stores-list {
  padding: 10px 0 0;
  margin: 0;
  font-size: 15px;
  color: #666; }
  .stores-list-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 2px 0 2px;
    margin: 5px 0;
    min-height: 26px;
    word-wrap: break-word; }
    @media (max-width: 599.98px) {
      .stores-list-item {
        display: block; } }
    .stores-list-item__dots {
      display: block;
      position: absolute;
      top: 12px;
      left: 0;
      right: 0;
      height: 1px;
      border-top: 1px dotted #ccc; }
      @media (max-width: 599.98px) {
        .stores-list-item__dots {
          position: static;
          margin: 5px 0; } }
    .stores-list-item__name {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-right: 10px;
      background: #fff; }
      @media (max-width: 991.98px) {
        .stores-list-item__name {
          font-size: 13px; } }
      @media (max-width: 599.98px) {
        .stores-list-item__name {
          display: block;
          font-size: 12px; } }
    .stores-list-item__value {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-left: 10px;
      background: #fff;
      text-align: right;
      white-space: nowrap; }
      @media (max-width: 599.98px) {
        .stores-list-item__value {
          display: block;
          text-align: left;
          font-size: 13px;
          padding-left: 0; } }
    .stores-list-item__value-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin: 0;
      background: center / 100% 100% no-repeat;
      margin-right: 3px; }
      @media (max-width: 599.98px) {
        .stores-list-item__value-icon {
          width: 15px;
          height: 15px; } }
      .stores-list-item__value-icon_check {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%234fad00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E"); }
      .stores-list-item__value-icon_little {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-trending-down'%3E%3Cpolyline points='23 18 13.5 8.5 8.5 13.5 1 6'%3E%3C/polyline%3E%3Cpolyline points='17 18 23 18 23 12'%3E%3C/polyline%3E%3C/svg%3E"); }
      .stores-list-item__value-icon_missing {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e32330' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E"); }

.dots-list {
  border: 1px solid #e5e5e5;
  padding: 20px 38px 20px;
  color: #666; }
  .dots-list-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    min-height: 20px;
    word-wrap: break-word; }
    @media (max-width: 599.98px) {
      .dots-list-item {
        display: block; } }
    .dots-list-item__dots {
      display: block;
      position: absolute;
      top: 11px;
      left: 0;
      right: 0;
      height: 1px;
      border-top: 1px dotted #ccc; }
      @media (max-width: 599.98px) {
        .dots-list-item__dots {
          position: static;
          margin: 5px 0; } }
    .dots-list-item__name {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-right: 28px;
      padding-left: 10px;
      background: #fff; }
      .dots-list-item__name:before {
        content: "";
        display: block;
        position: absolute;
        background: #fa9308;
        left: 1px;
        top: 10px;
        width: 2px;
        height: 2px; }
      @media (max-width: 991.98px) {
        .dots-list-item__name {
          font-size: 13px; } }
      @media (max-width: 599.98px) {
        .dots-list-item__name {
          display: block; } }
    .dots-list-item__value {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding-left: 29px;
      background: #fff;
      text-align: right;
      white-space: nowrap;
      font-weight: 700; }
      @media (max-width: 599.98px) {
        .dots-list-item__value {
          display: block;
          text-align: left;
          font-size: 13px;
          padding-left: 10px; } }

.catalog-card {
  position: relative;
  z-index: 0;
  min-height: 100%;
  margin: 0 -1px 0 0;
  padding: 20px 19px 26px 20px;
  text-align: center;
  box-shadow: 0 0 0 1px #f2f2f2 inset;
  background: #fff; }
  .catalog-card__wrapper {
    height: 100%; }
    @media (max-width: 599.98px) {
      .catalog-card__wrapper {
        height: auto !important; } }
  .catalog-card__view-place {
    position: relative;
    margin-bottom: 10px;
    margin-top: 6px; }
  .catalog-card__image-place {
    display: block;
    min-width: 70px;
    height: 170px;
    line-height: 170px;
    margin: 0 auto;
    padding: 0;
    vertical-align: middle;
    text-align: center; }
  .catalog-card__img {
    max-width: 100%;
    max-height: 100%; }
  .catalog-card__stickers {
    position: absolute;
    z-index: 5;
    top: 4px;
    z-index: 3;
    text-align: left;
    padding-right: 35px;
    font-size: 0; }
  .catalog-card__markers {
    position: absolute;
    z-index: 5;
    bottom: -4px;
    right: -2px;
    text-align: right; }
  .catalog-card__catalog-item-actions {
    position: absolute;
    z-index: 5;
    right: 0;
    top: 0; }
    .catalog-card__catalog-item-actions .catalog-item-actions-btn {
      display: block;
      opacity: 0;
      visibility: hidden; }
      .catalog-card__catalog-item-actions .catalog-item-actions-btn__name {
        display: none; }
      .catalog-card__catalog-item-actions .catalog-item-actions-btn.active {
        opacity: .7;
        visibility: visible; }
  .catalog-card__info {
    padding-bottom: 10px; }
  .catalog-card__title {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden; }
  .catalog-card__rating {
    margin: 0 0 9px; }
  .catalog-card__prices {
    margin-top: 11px; }
  .catalog-card__more-content {
    display: none; }
  .catalog-card__buy {
    margin: 0 3px 0 3px; }
  .catalog-card__counter {
    margin: 11px 5px 0 5px; }
  .catalog-card__buy-btn {
    margin: 11px 5px 0 5px;
    padding-left: 7px;
    padding-right: 7px; }
  .catalog-card .catalog-item__fast-view {
    padding-left: 14px; }
    @media (max-width: 991.98px) {
      .catalog-card .catalog-item__fast-view {
        display: none; } }
    .catalog-card .catalog-item__fast-view:before {
      display: none; }
  @media (max-width: 1300px) and (min-width: 992px) {
    .catalog-card .catalog-item__view-block {
      margin-left: -15px;
      margin-right: -15px; }
    .catalog-card .catalog-item__countdown {
      font-size: 13px; }
    .catalog-card .catalog-item-quantity-item {
      font-size: 13px; }
    .catalog-card .catalog-item__countdown-title {
      font-size: 10px; }
    .catalog-card .catalog-item__quantity-title {
      font-size: 10px; }
    .catalog-card .catalog-item__quantity-place {
      width: 35px; } }
  .catalog-card:hover {
    z-index: 2;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .catalog-card:hover .catalog-item__fast-view {
      visibility: visible;
      opacity: 1; }
    .catalog-card:hover .catalog-item-actions-btn {
      opacity: 0.7;
      visibility: visible; }
      .catalog-card:hover .catalog-item-actions-btn:hover {
        opacity: 1; }
      .catalog-card:hover .catalog-item-actions-btn.active {
        opacity: 1; }
        .catalog-card:hover .catalog-item-actions-btn.active .catalog-item-actions-btn__icon {
          opacity: 1; }
  .catalog-card_show-more .catalog-card__more-content {
    display: block; }
  .catalog-card_not-available .catalog-card__img {
    opacity: 0.2; }

.mobile .catalog-card__more-content {
  display: block; }

.mobile .catalog-card__catalog-item-actions .catalog-item-actions-btn {
  opacity: .7;
  visibility: visible; }

.catalog-row {
  display: flex;
  position: relative;
  z-index: 0;
  box-shadow: 0 0 0 1px #f2f2f2 inset;
  background: #fff;
  margin-bottom: -1px; }
  @media (max-width: 767.98px) {
    .catalog-row {
      display: block;
      text-align: center; } }
  .catalog-row__view {
    flex: 0 0 240px;
    max-width: 240px;
    padding: 19px; }
    @media (max-width: 767.98px) {
      .catalog-row__view {
        flex: auto;
        max-width: 100%; } }
  .catalog-row__view-place {
    text-align: center;
    position: relative; }
    @media (max-width: 767.98px) {
      .catalog-row__view-place {
        width: 200px;
        margin: 0 auto; } }
    @media (max-width: 400px) {
      .catalog-row__view-place {
        width: auto; } }
  .catalog-row__image-place {
    display: block;
    max-width: 100%;
    height: 200px;
    line-height: 200px;
    margin: 0 auto;
    padding: 0;
    vertical-align: middle;
    text-align: center; }
  .catalog-row__img {
    max-width: 100%;
    max-height: 100%; }
  .catalog-row__stickers {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    text-align: left;
    padding-right: 50px;
    font-size: 0; }
  .catalog-row__info {
    padding: 19px 20px 19px 12px;
    flex: 1 1 1px; }
    @media (max-width: 767.98px) {
      .catalog-row__info {
        flex-wrap: wrap; } }
  .catalog-row__info-bottom {
    margin: 23px 0 0;
    border-top: 1px solid #f2f2f2;
    padding: 14px 0 0; }
  .catalog-row__title {
    font-size: 16px;
    font-weight: 700;
    overflow: hidden; }
  .catalog-row__data {
    margin-top: 8px; }
  .catalog-row__rating {
    display: inline-block;
    vertical-align: middle;
    font-size: 0; }
  .catalog-row__catalog-item-actions .catalog-item-actions-btn {
    margin-right: 30px; }
  .catalog-row__prices .catalog-item__price {
    margin-left: 0; }
  .catalog-row__more-content {
    flex: 0 0 240px;
    max-width: 240px;
    padding: 25px 29px 27px 6px; }
    @media (max-width: 767.98px) {
      .catalog-row__more-content {
        flex: auto;
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto; } }
  .catalog-row__buy {
    margin-top: 15px; }
  .catalog-row__counter {
    margin-right: 10px; }
  .catalog-row .catalog-item-sale {
    margin-bottom: 8px;
    margin-left: -3px;
    margin-right: -3px; }
  .catalog-row .catalog-item-props__area {
    margin-top: 10px; }
  .catalog-row .catalog-item__description {
    margin-top: 10px; }
  .catalog-row .catalog-item__fast-view {
    position: relative;
    transform: none;
    top: 0;
    left: 0; }
    @media (max-width: 991.98px) {
      .catalog-row .catalog-item__fast-view {
        display: none; } }
  .catalog-row:hover {
    z-index: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .catalog-row:hover .catalog-item__fast-view {
      opacity: 1;
      visibility: visible; }
  .catalog-row_not-available .catalog-row__img {
    opacity: 0.2; }

.catalog-line {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  box-shadow: 0 0 0 1px #f2f2f2 inset;
  background: #fff; }
  @media (max-width: 599.98px) {
    .catalog-line {
      display: block;
      text-align: center; } }
  .catalog-line__view {
    flex: 0 0 70px;
    max-width: 70px;
    padding: 19px;
    padding-right: 0;
    position: relative; }
    @media (max-width: 599.98px) {
      .catalog-line__view {
        max-width: 100%;
        padding: 10px 10px 0; } }
  .catalog-line__image-place {
    display: block;
    max-width: 100%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    padding: 0;
    vertical-align: middle;
    text-align: center; }
    @media (max-width: 599.98px) {
      .catalog-line__image-place {
        width: 50px; } }
  .catalog-line__img {
    max-width: 100%;
    max-height: 100%; }
  .catalog-line__info {
    padding: 19px 10px 19px 20px;
    flex: 1 1 1px; }
    @media (max-width: 599.98px) {
      .catalog-line__info {
        padding-top: 3px;
        padding-bottom: 3px; } }
  .catalog-line__title {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    word-break: break-word; }
    @media (max-width: 767.98px) {
      .catalog-line__title {
        font-size: 12px; } }
  .catalog-line__data {
    margin-top: 3px; }
    @media (max-width: 599.98px) {
      .catalog-line__data {
        display: none; } }
  .catalog-line__rating {
    display: inline-block;
    vertical-align: middle;
    font-size: 0; }
  .catalog-line__catalog-item-actions .catalog-item-actions-btn {
    margin-right: 5px; }
    .catalog-line__catalog-item-actions .catalog-item-actions-btn__name {
      display: none; }
  .catalog-line__price-info {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 19px 0; }
    @media (max-width: 767.98px) {
      .catalog-line__price-info {
        flex-basis: 130px;
        max-width: 130px;
        padding-left: 3px;
        padding-right: 3px; } }
    @media (max-width: 599.98px) {
      .catalog-line__price-info {
        max-width: 100%;
        padding-top: 3px;
        padding-bottom: 3px; } }
  .catalog-line__prices .catalog-item__price {
    margin-left: 0;
    font-size: 15px;
    margin-bottom: 5px; }
    .catalog-line__prices .catalog-item__price_discount {
      font-size: 13px; }
  .catalog-line__buttons-cell {
    flex: 0 0 215px;
    max-width: 215px;
    padding: 19px 0; }
    @media (max-width: 767.98px) {
      .catalog-line__buttons-cell {
        display: none; } }
  .catalog-line__buttons-adaptive {
    display: none;
    margin: 10px 0 10px; }
    @media (max-width: 767.98px) {
      .catalog-line__buttons-adaptive {
        display: block; } }
  .catalog-line__actions-cell {
    flex: 0 0 92px;
    max-width: 92px;
    position: relative;
    padding: 19px 5px 19px 5px;
    text-align: center; }
    @media (max-width: 991.98px) {
      .catalog-line__actions-cell {
        flex-basis: 40px;
        max-width: 40px; } }
    @media (max-width: 767.98px) {
      .catalog-line__actions-cell {
        display: none; } }
  .catalog-line__buy {
    position: relative; }
  .catalog-line__counter {
    margin-right: 10px;
    margin-bottom: 7px; }
  .catalog-line__buy-btn {
    margin-bottom: 7px; }
  .catalog-line .catalog-item-sale {
    margin-bottom: 8px;
    margin-left: -3px;
    margin-right: -3px; }
  .catalog-line .catalog-item__buy-total {
    margin: -4px 0 0;
    position: absolute;
    font-size: 12px; }
  .catalog-line .catalog-item__fast-view {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    font-size: 0;
    padding: 0; }
    @media (max-width: 991.98px) {
      .catalog-line .catalog-item__fast-view {
        display: none; } }
  .catalog-line:hover {
    z-index: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
    .catalog-line:hover .catalog-item__fast-view {
      visibility: visible;
      opacity: 1; }
  .catalog-line_not-available .catalog-line__img {
    opacity: 0.2; }

.catalog-modal {
  display: flex;
  position: relative;
  z-index: 0;
  background: #fff;
  min-height: 510px; }
  .catalog-modal:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 269px;
    height: 100%;
    width: 1px;
    background: #f2f2f2; }
    @media (max-width: 991.98px) {
      .catalog-modal:before {
        display: none; } }
  @media (max-width: 991.98px) {
    .catalog-modal {
      display: block;
      text-align: center; } }
  .catalog-modal__view {
    flex: 0 0 310px;
    max-width: 310px;
    padding: 30px; }
    @media (max-width: 991.98px) {
      .catalog-modal__view {
        flex: auto;
        max-width: 100%;
        padding-bottom: 0; } }
  .catalog-modal__view-place {
    text-align: center;
    position: relative; }
  .catalog-modal__image-place {
    display: block;
    max-width: 100%;
    height: 340px;
    line-height: 337px;
    margin: 0 auto;
    padding: 0;
    vertical-align: middle;
    text-align: center; }
    @media (max-width: 991.98px) {
      .catalog-modal__image-place {
        height: 220px;
        line-height: 220px; } }
  .catalog-modal__img {
    max-width: 100%;
    max-height: 100%; }
  .catalog-modal__stickers {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    text-align: left;
    padding-right: 50px;
    font-size: 0; }
  .catalog-modal__info {
    flex: 1 1 1px;
    padding: 27px 30px 0 30px;
    max-height: 480px;
    overflow-y: auto;
    overflow-x: hidden; }
    .catalog-modal__info::-webkit-scrollbar {
      width: 8px;
      height: 8px; }
    .catalog-modal__info::-webkit-scrollbar-track {
      border-radius: 8px; }
    .catalog-modal__info::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #999;
      border: 2px solid #fff; }
    .catalog-modal__info::-webkit-scrollbar-thumb:window-inactive {
      background: #999;
      border: 2px solid #fff; }
    @media (max-width: 991.98px) {
      .catalog-modal__info {
        flex-wrap: wrap; } }
  .catalog-modal__title {
    font-size: 18px;
    line-height: 1.2;
    overflow: hidden; }
  .catalog-modal__data {
    margin-bottom: 20px; }
  .catalog-modal__rating {
    display: block;
    vertical-align: middle;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: -1px;
    font-size: 0; }
  .catalog-modal__catalog-item-actions {
    padding-top: 14px; }
    .catalog-modal__catalog-item-actions .catalog-item-actions-btn {
      display: block;
      text-align: left; }
      .catalog-modal__catalog-item-actions .catalog-item-actions-btn:not(.active) {
        opacity: 0.7; }
        .catalog-modal__catalog-item-actions .catalog-item-actions-btn:not(.active):hover {
          opacity: 1; }
      .catalog-modal__catalog-item-actions .catalog-item-actions-btn.active .catalog-item-actions-btn__name {
        color: #333; }
  .catalog-modal__price-info {
    margin-bottom: 15px; }
  .catalog-modal__prices .catalog-item__price {
    margin-left: 0; }
    .catalog-modal__prices .catalog-item__price_discount {
      font-size: 16px; }
  .catalog-modal__more-content {
    flex: 0 0 270px;
    max-width: 270px;
    padding: 26px 30px 0 27px;
    max-height: 507px;
    overflow-y: auto;
    overflow-x: hidden; }
    .catalog-modal__more-content::-webkit-scrollbar {
      width: 8px;
      height: 8px; }
    .catalog-modal__more-content::-webkit-scrollbar-track {
      border-radius: 8px; }
    .catalog-modal__more-content::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: #999;
      border: 2px solid #fff; }
    .catalog-modal__more-content::-webkit-scrollbar-thumb:window-inactive {
      background: #999;
      border: 2px solid #fff; }
    @media (max-width: 991.98px) {
      .catalog-modal__more-content {
        flex: auto;
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto; } }
  .catalog-modal__buy-justify {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .catalog-modal__counter {
    margin-top: 15px;
    margin-bottom: 12px; }
  .catalog-modal__buy-btn {
    margin-top: 15px;
    margin-bottom: 12px; }
  .catalog-modal__meta {
    margin: 0 -6px; }
  .catalog-modal .catalog-item-store,
  .catalog-modal .catalog-item__char {
    margin: 0;
    font-size: 13px;
    padding: 3px 6px 6px; }
  .catalog-modal .catalog-item-props__title {
    font-size: 13px; }
  .catalog-modal .catalog-item-chars-list {
    padding-top: 3px; }
  .catalog-modal .catalog-item-props-list__item {
    margin: 0 8px 8px 0; }
  .catalog-modal .catalog-item-sale__value {
    margin-left: 0;
    margin-right: 3px; }
  .catalog-modal .catalog-item-sale__name {
    margin-left: 0; }
  .catalog-modal .countdown-item {
    width: 3.066em; }
  .catalog-modal .catalog-item__countdown {
    width: 9.198em; }
  .catalog-modal .catalog-item__buy-total {
    margin-top: 15px;
    margin-left: -3px; }

.catalog-filters {
  position: relative;
  margin-bottom: 27px;
  border: 1px solid #f2f2f2;
  background: #f9f9f9;
  border-radius: 2px; }
  .catalog-filters__header {
    padding: 13px 19px 14px;
    border-bottom: 1px solid #f2f2f2; }
  .catalog-filters__title {
    font-weight: 700;
    font-size: 14px; }
  .catalog-filters__footer {
    text-align: center;
    padding: 19px 14px 19px 14px; }
  .catalog-filters__btn {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px; }
    .catalog-filters__btn_reset {
      margin-left: 5px; }
  .catalog-filters-group {
    padding: 13px 19px 14px;
    border-bottom: 1px solid #f2f2f2; }
    .catalog-filters-group__title {
      position: relative;
      display: block;
      padding: 0 35px 0 0;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
      color: #333;
      white-space: nowrap; }
      .catalog-filters-group__title:hover {
        color: #fa9308; }
        .catalog-filters-group__title:hover .catalog-filters-group__arrow {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
      .catalog-filters-group__title.collapsed .catalog-filters-group__arrow {
        transform: rotateZ(0deg); }
    .catalog-filters-group__name {
      display: inline-block;
      white-space: normal;
      word-break: break-word; }
    .catalog-filters-group__arrow {
      position: absolute;
      top: 3px;
      right: -3px;
      width: 13px;
      height: 13px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat;
      transform: rotateZ(180deg); }
    .catalog-filters-group__content {
      padding-top: 15px;
      max-height: 250px;
      overflow-y: auto; }
      .catalog-filters-group__content::-webkit-scrollbar {
        width: 8px;
        height: 8px; }
      .catalog-filters-group__content::-webkit-scrollbar-track {
        border-radius: 8px; }
      .catalog-filters-group__content::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: #999;
        border: 2px solid #fff; }
      .catalog-filters-group__content::-webkit-scrollbar-thumb:window-inactive {
        background: #999;
        border: 2px solid #fff; }
    .catalog-filters-group:hover {
      background: #fff; }
  .catalog-filters label {
    color: #333; }

.catalog-filters-tooltip {
  position: absolute;
  z-index: 1000;
  left: 100%;
  margin-left: -20px;
  margin-top: -20px;
  padding: 5px 10px 5px 10px;
  white-space: nowrap;
  font-size: 13px;
  background: #fff;
  border: 1px solid #fa9308;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transition: top 300ms ease-in-out; }
  @media (max-width: 767.98px) {
    .catalog-filters-tooltip {
      display: none !important; } }
  .catalog-filters-tooltip:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -7px;
    display: block;
    border: 7px solid transparent;
    border-right-color: #fa9308; }
  .catalog-filters-tooltip__btn {
    margin-left: 10px; }
  .catalog-filters-tooltip_2 {
    background: #fa9308;
    box-shadow: none;
    color: #fff;
    margin-left: 1px; }
    .catalog-filters-tooltip_2:before {
      display: none;
      border: 0;
      margin-left: 0; }

.filter-adaptive-place {
  display: none;
  font-size: 0;
  margin-right: 15px; }
  @media (max-width: 991.98px) {
    .filter-adaptive-place {
      display: block; } }
  @media (max-width: 599.98px) {
    .filter-adaptive-place {
      margin-right: 10px; } }
  .filter-adaptive-place__drop {
    display: none;
    position: absolute;
    z-index: 1000;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); }
    .filter-adaptive-place__drop.active {
      display: block; }
    .filter-adaptive-place__drop .catalog-filters {
      margin-bottom: 0; }

.filter-adaptive-btn {
  display: inline-block;
  position: relative;
  min-height: 35px;
  padding: 8px 14px 8px 35px;
  margin: 0;
  border-radius: 2px;
  background: #e5e5e5;
  border: 0;
  font-size: 13px;
  color: #383838;
  cursor: pointer; }
  @media (max-width: 599.98px) {
    .filter-adaptive-btn {
      padding: 0;
      width: 35px; } }
  .filter-adaptive-btn:before {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    left: 10px;
    width: 16px;
    height: 16px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3'%3E%3C/polygon%3E%3C/svg%3E") center/100% 100% no-repeat; }
  @media (max-width: 599.98px) {
    .filter-adaptive-btn__text {
      display: none; } }
  .filter-adaptive-btn.active {
    background: #fa9308;
    color: #fff; }
    .filter-adaptive-btn.active:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3'%3E%3C/polygon%3E%3C/svg%3E"); }

.good-page__main {
  position: relative;
  margin: 0 0 30px;
  padding: 0;
  border: 1px solid #f2f2f2; }
  .good-page__main .catalog-item__price {
    display: block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
    font-size: 26px;
    line-height: 1; }
    @media (max-width: 767.98px) {
      .good-page__main .catalog-item__price {
        font-size: 16px; } }
    .good-page__main .catalog-item__price_discount {
      margin-bottom: 10px;
      font-size: 16px; }
  .good-page__main .catalog-item-sale__value {
    margin-left: 0; }
  .good-page__main .countdown-item {
    width: 3.066em; }
  .good-page__main .catalog-item__countdown {
    width: 9.198em; }
  .good-page__main .catalog-item-store {
    margin-left: 0;
    font-size: 13px;
    margin-right: 30px; }
    .good-page__main .catalog-item-store__text {
      border-bottom: 1px dotted; }
  .good-page__main .catalog-item__view-block {
    margin-bottom: 12px; }
  .good-page__main .catalog-item-props__title {
    font-size: 13px; }
  .good-page__main .catalog-item-props-list__item {
    font-size: 13px;
    line-height: 1.1;
    margin: 0 8px 8px 0; }
  .good-page__main .catalog-item-table-sizes {
    margin-bottom: 27px; }
  .good-page__main .catalog-item-props {
    margin-bottom: 15px; }
  .good-page__main .catalog-item__buy-total {
    margin-top: 0; }
  .good-page__main .catalog-item-chars__title {
    font-size: 14px;
    font-weight: 500; }

.good-page__tab-content {
  padding-top: 40px; }

.good-page__grid {
  display: flex; }
  @media (max-width: 767.98px) {
    .good-page__grid {
      display: block; } }

.good-page__view {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 37px 25px 29px 37px; }
  @media (max-width: 767.98px) {
    .good-page__view {
      max-width: 100%;
      padding: 20px 25px 15px 25px;
      border-bottom: 1px solid #f2f2f2; } }

.good-page-content {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 10px;
  padding-right: 30px; }
  @media (max-width: 767.98px) {
    .good-page-content {
      max-width: 100%;
      padding-right: 10px; } }
  .good-page-content__top {
    padding: 30px 0 20px;
    border-bottom: 1px solid #f2f2f2; }
    @media (max-width: 991.98px) {
      .good-page-content__top {
        padding-top: 20px;
        padding-bottom: 10px; } }
  .good-page-content__middle {
    padding: 35px 0 15px; }
    @media (max-width: 991.98px) {
      .good-page-content__middle {
        padding-top: 25px; } }
  .good-page-content__bottom {
    border-top: 1px solid #f2f2f2;
    padding: 30px 0 39px; }

.good-page__view-place {
  position: relative; }

.good-page__stickers {
  position: absolute;
  top: 4px;
  z-index: 3;
  text-align: left;
  padding-right: 50px;
  font-size: 0; }
  .good-page__stickers .stickers__item {
    font-size: 11px; }

.good-page__catalog-item-actions {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0; }
  .good-page__catalog-item-actions .catalog-item-actions-btn {
    display: block; }
    .good-page__catalog-item-actions .catalog-item-actions-btn__name {
      display: none; }
    .good-page__catalog-item-actions .catalog-item-actions-btn.active {
      opacity: 1;
      visibility: visible; }
      .good-page__catalog-item-actions .catalog-item-actions-btn.active:hover {
        opacity: 1; }

.good-page__buy {
  margin-bottom: 15px; }

.good-page__disclaimer {
  color: #7e7e7e;
  font-size: 12px; }

.good-page-slider {
  padding: 44px 0 0 101px; }
  @media (max-width: 991.98px) {
    .good-page-slider {
      padding-left: 70px; } }
  @media (max-width: 767.98px) {
    .good-page-slider {
      padding-left: 0;
      padding-top: 5px; } }
  .good-page-slider-main {
    text-align: center; }
    .good-page-slider-main__wrapper {
      max-width: 500px;
      height: 500px;
      margin-bottom: 22px;
      overflow: hidden; }
      @media (max-width: 767.98px) {
        .good-page-slider-main__wrapper {
          height: 270px;
          max-width: 100%;
          margin-bottom: 0;
          padding-bottom: 30px; } }
    .good-page-slider-main-item {
      position: relative;
      height: 500px;
      line-height: 497px; }
      @media (max-width: 767.98px) {
        .good-page-slider-main-item {
          height: 240px;
          line-height: 240px; } }
    .good-page-slider-main-item__img {
      display: inline-block !important;
      width: auto !important;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; }
    .good-page-slider-main__fancy {
      display: block;
      height: 100%;
      line-height: inherit; }
      .good-page-slider-main__fancy:before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        margin-left: -25px;
        margin-top: -25px;
        border-radius: 50%;
        background: #fa9308 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='11' y1='8' x2='11' y2='14'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E") left 10px top 10px/27px 27px no-repeat;
        opacity: 0;
        transition: opacity 150ms linear; }
      .good-page-slider-main__fancy:hover:before {
        opacity: 1; }
    .good-page-slider-main .slick-dots {
      position: absolute;
      z-index: 2;
      bottom: -35px;
      left: 0;
      right: 0;
      text-align: center;
      padding: 0 15px;
      margin: 0; }
      .good-page-slider-main .slick-dots li {
        position: relative;
        display: inline-block;
        height: 8px;
        width: 8px;
        margin: 4px;
        border-radius: 50%;
        background: #b9b9b9; }
        .good-page-slider-main .slick-dots li:before {
          content: "";
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px; }
        .good-page-slider-main .slick-dots li button {
          display: none; }
        .good-page-slider-main .slick-dots li.slick-active {
          background-color: #fa9308; }
    .good-page-slider-main .slick-arrow {
      position: absolute;
      z-index: 2;
      top: 50%;
      margin-top: -21px;
      font-size: 0;
      line-height: 42px;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 0;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") left 7px center/30px 30px no-repeat; }
      .good-page-slider-main .slick-arrow:hover {
        background-color: #fa9308; }
        .good-page-slider-main .slick-arrow:hover:not(.slick-disabled) {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
        .good-page-slider-main .slick-arrow:hover.slick-disabled {
          background-color: #eee; }
      .good-page-slider-main .slick-arrow button {
        display: none; }
    .good-page-slider-main .slick-prev {
      left: 0;
      transform: scaleX(-1); }
    .good-page-slider-main .slick-next {
      right: 0; }
  .good-page-slider__more {
    display: none;
    position: absolute;
    top: 80px;
    left: 3px;
    width: 54px;
    height: 400px; }
    @media (min-width: 768px) {
      .good-page-slider__more {
        display: block; } }
  .good-page-slider__thumbs-wrapper {
    max-height: 310px;
    overflow: hidden; }
  .good-page-slider-thumbs {
    padding: 0;
    margin: 0;
    list-style: none; }
    .good-page-slider-thumbs__item {
      position: relative;
      display: block;
      width: 54px !important;
      height: 54px !important;
      line-height: 50px;
      padding-left: 0;
      margin: 0 0 10px;
      overflow: hidden;
      vertical-align: bottom;
      list-style: none;
      text-align: center;
      border: 2px solid #fff !important;
      transition: border-color ease-out .2s;
      cursor: pointer; }
      .good-page-slider-thumbs__item.active {
        border-color: #fa9308 !important; }
    .good-page-slider-thumbs__img {
      display: inline-block !important;
      width: auto !important;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; }
    .good-page-slider-thumbs .slick-arrow {
      display: block;
      z-index: 2;
      top: 50%;
      font-size: 0;
      line-height: 20px;
      width: 100%;
      height: 20px;
      border: 0;
      background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/15px 15px no-repeat; }
      .good-page-slider-thumbs .slick-arrow:hover {
        background-color: #fa9308; }
        .good-page-slider-thumbs .slick-arrow:hover:not(.slick-disabled) {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
        .good-page-slider-thumbs .slick-arrow:hover.slick-disabled {
          background-color: #eee; }
      .good-page-slider-thumbs .slick-arrow button {
        display: none; }
    .good-page-slider-thumbs .slick-prev {
      left: 0;
      transform: scaleY(-1); }
    .good-page-slider-thumbs .slick-next {
      right: 0; }
  .good-page-slider-videos__link {
    position: relative;
    display: block;
    padding: 5px 0 0;
    margin-top: 10px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #222;
    text-align: center; }
    .good-page-slider-videos__link:before {
      content: "";
      position: relative;
      display: inline-block;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23222' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='5 3 19 12 5 21 5 3'%3E%3C/polygon%3E%3C/svg%3E") center/100% 100% no-repeat;
      width: 20px;
      height: 20px;
      margin-bottom: 3px;
      margin: auto; }

.good-page__brand-col {
  text-align: right; }
  @media (max-width: 991.98px) {
    .good-page__brand-col {
      text-align: left; } }
  @media (max-width: 767.98px) {
    .good-page__brand-col {
      text-align: center; } }

.good-page__catalog-item__char {
  font-size: 13px; }

.good-page__price-info {
  margin-bottom: 5px; }

.good-page__meta {
  margin-bottom: 15px; }

.good-page__counter {
  margin-bottom: 12px;
  margin-right: 12px; }

.good-page__buy-btn {
  font-size: 14px;
  margin-bottom: 12px;
  padding: .608rem 1.8rem; }

.good-page__link-to-chars {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  line-height: 1.2;
  color: #fa9308;
  border-bottom: 1px dotted; }
  .good-page__link-to-chars:hover {
    color: #333; }
    .good-page__link-to-chars:hover:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E"); }
  .good-page__link-to-chars:before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    right: -20px;
    top: 0.2em;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fa9308' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }

.good-page__tizer {
  border-top: 1px solid #f2f2f2;
  border-bottom: 0;
  padding: 20px 0 0; }

.good-page__iconblock {
  padding-left: 20px;
  margin-bottom: 20px; }
  @media (max-width: 991.98px) {
    .good-page__iconblock {
      padding-left: 10px; } }
  @media (max-width: 991.98px) {
    .good-page__iconblock .iconblock__text {
      padding-left: 10px;
      font-size: 11px; } }

.good-page-section {
  margin-top: 15px; }
  .good-page-section__header {
    padding: 25px 0; }
    @media (max-width: 599.98px) {
      .good-page-section__header {
        text-align: center; } }
  .good-page-section__title {
    font-size: 19px;
    color: #333; }
  .good-page-section__body {
    padding-bottom: 25px; }
  .good-page-section__catalog-item-chars .catalog-item-chars-list-item__value {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: left; }
  .good-page-section_with-slider .good-page-section__header {
    padding-right: 65px; }
    @media (max-width: 599.98px) {
      .good-page-section_with-slider .good-page-section__header {
        text-align: left; } }

.catalog-cards-slider {
  position: relative;
  z-index: 1;
  margin-top: -25px;
  display: none; }
  .catalog-cards-slider.slick-initialized {
    display: block; }
  .catalog-cards-slider .catalog-card {
    margin-top: 0; }
  .catalog-cards-slider .catalog-card__img {
    display: inline-block !important; }
  .catalog-cards-slider .slick-track {
    display: flex;
    margin-left: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    transform: none; }
  .catalog-cards-slider .slick-slide {
    position: relative;
    height: auto; }
    .catalog-cards-slider .slick-slide.slick-active:hover {
      z-index: 3; }
  .catalog-cards-slider .slick-list {
    margin: 0 -15px;
    padding: 0 15px; }
    .catalog-cards-slider .slick-list:before, .catalog-cards-slider .slick-list:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 14px;
      z-index: 2;
      background: #fff; }
    .catalog-cards-slider .slick-list:before {
      left: -1px; }
    .catalog-cards-slider .slick-list:after {
      right: -2px; }

.slick_outer-arrows .slick-list {
  padding-top: 10px; }

.slick_outer-arrows .slick-arrow {
  position: absolute;
  top: -30px;
  width: 33px;
  height: 35px;
  border: 1px solid #e5e5e5;
  font-size: 0;
  padding: 0;
  opacity: 0.8;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/20px 20px no-repeat;
  transition: opacity 100ms ease; }
  .slick_outer-arrows .slick-arrow:hover {
    opacity: 1;
    background-color: #f5f5f5; }
  .slick_outer-arrows .slick-arrow:active {
    background-color: #eee; }
  .slick_outer-arrows .slick-arrow.slick-disabled {
    opacity: 0.5;
    pointer-events: none; }

.slick_outer-arrows .slick-prev {
  right: 32px;
  transform: scaleX(-1);
  border-radius: 2px 0 0 2px; }

.slick_outer-arrows .slick-next {
  right: 0;
  border-radius: 0 2px 2px 0; }

.slick_side-arrows {
  padding-left: 30px;
  padding-right: 30px; }
  .slick_side-arrows .slick-arrow {
    position: absolute;
    z-index: 3;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 33px;
    border: 0;
    font-size: 0;
    padding: 0;
    opacity: 0.8;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/20px 20px no-repeat;
    transition: opacity 100ms ease; }
    .slick_side-arrows .slick-arrow:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.02); }
    .slick_side-arrows .slick-arrow:active {
      background-color: #eee; }
    .slick_side-arrows .slick-arrow.slick-disabled {
      opacity: 0;
      pointer-events: none; }
  .slick_side-arrows .slick-prev {
    left: 0;
    transform: scaleX(-1); }
  .slick_side-arrows .slick-next {
    right: 0; }

.cart-page__gifts {
  margin: 30px 0; }

.cart-page-empty__icon {
  display: block;
  width: 140px;
  height: 140px;
  margin: 35px auto 20px;
  color: #b3b3b3; }
  @media (max-width: 599.98px) {
    .cart-page-empty__icon {
      width: 125px;
      height: 125px;
      margin-top: 15px; } }

.cart-page-empty__title {
  font-size: 36px;
  line-height: 1.1;
  text-align: center;
  color: #bababa;
  margin-bottom: 30px; }
  @media (max-width: 599.98px) {
    .cart-page-empty__title {
      font-size: 24px;
      margin-bottom: 25px; } }

.cart-page-empty__text {
  text-align: center;
  color: #000;
  font-size: 16px;
  margin-bottom: 42px; }

.cart-page-panel {
  display: flex;
  padding: 19px 27px 25px;
  margin-bottom: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid #f2f2f2;
  border-radius: 2px; }
  @media (max-width: 991.98px) {
    .cart-page-panel {
      display: block; } }
  .cart-page-panel__coupon {
    flex: 1 1 1px;
    padding-left: 5px; }
  .cart-page-panel__coupon-container {
    min-width: 225px;
    padding-right: 5px; }
    @media (max-width: 767.98px) {
      .cart-page-panel__coupon-container {
        min-width: 1px; } }
  .cart-page-panel__checkout {
    display: flex;
    justify-content: flex-end;
    flex: 3 1 1px;
    padding-left: 5px;
    padding-right: 5px; }
    @media (max-width: 991.98px) {
      .cart-page-panel__checkout {
        justify-content: flex-start; } }
    @media (max-width: 767.98px) {
      .cart-page-panel__checkout {
        flex-wrap: wrap; } }
  .cart-page-panel__total {
    padding-top: 21px;
    padding-right: 20px; }
  .cart-page-panel__prices {
    padding-top: 17px; }
    @media (max-width: 767.98px) {
      .cart-page-panel__prices {
        padding-right: 15px; } }
  .cart-page-panel__buttons {
    white-space: nowrap; }
    @media (max-width: 991.98px) {
      .cart-page-panel__buttons {
        white-space: normal; } }
    @media (max-width: 767.98px) {
      .cart-page-panel__buttons {
        width: 50%; } }
    @media (max-width: 599.98px) {
      .cart-page-panel__buttons {
        width: 100%; } }
  .cart-page-panel__btn-place {
    display: inline-block;
    padding-top: 13px;
    padding-left: 15px; }
    @media (max-width: 767.98px) {
      .cart-page-panel__btn-place {
        display: block;
        padding-left: 0; } }
  @media (max-width: 767.98px) {
    .cart-page-panel__btn {
      width: 100%; } }

.cart-coupon__result {
  font-size: 13px;
  margin-top: 6px; }

.cart-page-total__title {
  font-weight: 700;
  font-size: 16px; }

.cart-page-total__desc {
  font-size: 12px;
  color: #a1a1a1; }

.cart-page-prices__current {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.1;
  color: #2f3435;
  white-space: nowrap; }
  @media (max-width: 767.98px) {
    .cart-page-prices__current {
      font-size: 24px;
      white-space: normal; } }

.cart-page-prices__old {
  font-size: 13px;
  line-height: 1.1;
  color: #777;
  white-space: nowrap;
  text-decoration: line-through; }
  @media (max-width: 767.98px) {
    .cart-page-prices__old {
      white-space: normal; } }

.cart-page-prices-difference {
  font-size: 12px;
  color: #888;
  padding: 8px 0 10px; }
  .cart-page-prices-difference__value {
    display: inline-block;
    background: #ffd02e;
    padding: 3px 8px 2px;
    color: #333;
    line-height: 16px;
    border-radius: 3px;
    margin: 2px 0 0 0; }

.cart-table {
  border: 1px solid #f2f2f2;
  margin-bottom: 20px; }
  .cart-table-header {
    display: flex;
    padding: 13px 15px;
    background: #f9f9f9;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .cart-table-header {
        display: block; } }
    .cart-table-header__left {
      flex: 1 1 1px; }
      @media (max-width: 767.98px) {
        .cart-table-header__left {
          width: 100%;
          text-align: center; } }
    .cart-table-header__right {
      flex: 1 1 1px;
      padding-left: 10px;
      text-align: right; }
      @media (max-width: 767.98px) {
        .cart-table-header__right {
          padding-left: 0;
          padding-top: 10px;
          text-align: center; } }
  .cart-table-search {
    min-width: 235px; }
  .cart-table-tab-links {
    display: inline-block;
    padding: 0 10px; }
    .cart-table-tab-links__item {
      display: inline-block;
      position: relative;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 700;
      margin: 0 10px;
      border: none;
      transition: color 300ms ease; }
      @media (max-width: 767.98px) {
        .cart-table-tab-links__item {
          margin-bottom: 12px;
          white-space: nowrap; } }
      .cart-table-tab-links__item:before {
        content: "";
        position: absolute;
        bottom: -6px;
        left: 0;
        right: 0;
        height: 2px;
        background: #fa9308;
        opacity: 0;
        transition: opacity 300ms ease; }
      .cart-table-tab-links__item.active {
        color: #333; }
        .cart-table-tab-links__item.active:before {
          opacity: 1; }
  .cart-table__btn-clean {
    border-color: #a6a6a6;
    color: #888; }
    .cart-table__btn-clean:before {
      content: "";
      display: inline-block;
      height: 12px;
      width: 12px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23555' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E") center/100% 100% no-repeat;
      vertical-align: middle;
      margin: 0 7px 0 -5px; }
    .cart-table__btn-clean:hover {
      color: #fa9308;
      border-color: #fa9308;
      background: #fff; }
    .cart-table__btn-clean:active {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.cart-table__body {
  width: 100%; }
  @media (max-width: 767.98px) {
    .cart-table__body {
      display: block; }
      .cart-table__body tbody {
        display: block;
        width: 100%; } }

.cart-table-row {
  position: relative; }
  @media (max-width: 767.98px) {
    .cart-table-row {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-wrap: wrap; } }
  .cart-table-row__column {
    vertical-align: top;
    border-top: 1px solid #f2f2f2; }
    @media (max-width: 767.98px) {
      .cart-table-row__column {
        display: inline-block;
        border-top-width: 0; } }
    .cart-table-row__column_description {
      width: 100%;
      padding: 29px 0 33px 41px; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .cart-table-row__column_description {
          padding-left: 15px; } }
      @media (max-width: 767.98px) {
        .cart-table-row__column_description {
          display: block;
          padding-bottom: 10px;
          border-top-width: 1px; } }
      @media (max-width: 599.98px) {
        .cart-table-row__column_description {
          padding-left: 15px;
          padding-right: 15px; } }
    .cart-table-row__column_price-one {
      padding-top: 29px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 33px; }
      @media (max-width: 767.98px) {
        .cart-table-row__column_price-one {
          display: none; } }
    .cart-table-row__column_count {
      padding-top: 25px;
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 33px; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .cart-table-row__column_count {
          padding-right: 15px;
          padding-left: 15px; } }
      @media (max-width: 767.98px) {
        .cart-table-row__column_count {
          padding-top: 5px; } }
      @media (max-width: 599.98px) {
        .cart-table-row__column_count {
          padding-left: 10px;
          padding-right: 10px; } }
    .cart-table-row__column_price-total {
      padding-top: 29px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 33px; }
      @media (max-width: 767.98px) {
        .cart-table-row__column_price-total {
          padding-top: 5px; } }
    .cart-table-row__column_notify {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 41px;
      padding-right: 10px; }
      @media (max-width: 767.98px) {
        .cart-table-row__column_notify {
          display: block;
          width: 100%;
          border-top-width: 1px;
          padding-right: 40px; } }
      @media (max-width: 599.98px) {
        .cart-table-row__column_notify {
          padding-right: 15px;
          padding-left: 15px; } }
  .cart-table-row__view {
    display: flex;
    width: 100%; }
    @media (max-width: 599.98px) {
      .cart-table-row__view {
        display: block; } }
  .cart-table-row__image-place {
    flex: 1 1 1px;
    width: 100%;
    max-width: 150px;
    min-width: 80px;
    padding-right: 15px;
    text-align: center; }
    @media (max-width: 599.98px) {
      .cart-table-row__image-place {
        margin-left: auto;
        margin-right: auto;
        max-width: 230px;
        margin: 10px auto 15px;
        padding-right: 0; } }
  .cart-table-row__image-link {
    position: relative;
    display: block;
    width: 100%; }
  .cart-table-row__label {
    position: absolute;
    bottom: -2px;
    right: -2px;
    display: inline-block;
    margin-bottom: 2px;
    background: #999;
    color: #fff;
    vertical-align: middle;
    text-align: center;
    transition: all 250ms ease;
    width: 40px;
    height: 40px;
    font-size: 13px;
    line-height: 40px;
    border-radius: 50%;
    font-weight: 700; }
    .cart-table-row__label_sale {
      background-color: #e32330; }
  .cart-table-row__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%; }
    @media (max-width: 599.98px) {
      .cart-table-row__img {
        max-height: 160px; } }
  .cart-table-row__info {
    flex: 3 1 1px;
    padding-left: 26px;
    padding-right: 55px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .cart-table-row__info {
        padding-right: 10px;
        padding-left: 15px; } }
    @media (max-width: 599.98px) {
      .cart-table-row__info {
        padding-right: 5px;
        padding-left: 5px; } }
  .cart-table-row__title {
    padding-right: 20px;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 8px;
    word-break: break-word; }
    .cart-table-row__title a {
      color: #333; }
      .cart-table-row__title a:hover {
        color: #fa9308; }
    @media (max-width: 599.98px) {
      .cart-table-row__title {
        text-align: center; } }
  .cart-table-row-prop {
    font-size: 13px;
    padding-bottom: 4px; }
    .cart-table-row-prop__name {
      color: #888;
      padding-top: 6px;
      margin-right: 5px; }
    .cart-table-row-prop__value {
      padding-top: 6px; }
  .cart-table-row-price {
    white-space: nowrap; }
    .cart-table-row-price__current {
      font-size: 16px;
      font-weight: 700;
      color: #333; }
    .cart-table-row-price__old {
      font-size: 13px;
      color: #777;
      text-decoration: line-through; }
    .cart-table-row-price__title {
      white-space: normal;
      color: #a1a1a1;
      font-size: 12px; }
  .cart-table-row-price-difference {
    font-size: 12px;
    color: #888;
    padding: 8px 0 10px;
    white-space: normal; }
    .cart-table-row-price-difference__value {
      display: inline-block;
      background: #ffd02e;
      padding: 3px 8px 2px;
      color: #333;
      line-height: 16px;
      border-radius: 3px;
      margin: 2px 0 0 0; }
  .cart-table-row__counter-desc {
    text-align: center;
    font-size: 12px;
    color: #a1a1a1; }
  .cart-table-row__clean-btn {
    position: relative;
    z-index: 2;
    top: 0;
    height: 30px;
    right: 0;
    width: 30px;
    padding: 0;
    border: 0;
    color: #a1a1a1;
    background: transparent;
    box-shadow: none !important;
    outline: none !important;
    transition: 300ms color ease; }
    @media (max-width: 767.98px) {
      .cart-table-row__clean-btn {
        position: absolute;
        top: 20px; } }
    .cart-table-row__clean-btn:after, .cart-table-row__clean-btn:before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      width: 1px;
      height: 9px;
      background: currentColor;
      content: ''; }
    .cart-table-row__clean-btn:after {
      transform: rotate(45deg); }
    .cart-table-row__clean-btn:before {
      transform: rotate(-45deg); }
    .cart-table-row__clean-btn:hover {
      color: #333; }
  .cart-table-row .catalog-item-props__area {
    margin-bottom: 8px; }
  .cart-table-row .catalog-item-props__title {
    font-size: 13px; }
  .cart-table-row .catalog-item-props-list__item {
    font-size: 12px; }
  .cart-table-row-notify {
    display: flex;
    padding: 10px 0;
    border-radius: 2px; }
    .cart-table-row-notify__text {
      flex: 1 1 1px;
      padding-right: 15px; }
    @media (max-width: 599.98px) {
      .cart-table-row-notify {
        display: block; } }
  .cart-table-row:first-child .cart-table-row__column {
    border-top: 0; }

.order-coupon {
  display: flex; }
  .order-coupon__label-place {
    flex: 0 0 90x;
    max-width: 90px; }
  .order-coupon__form {
    flex: 1 1 1px; }

.order-table {
  width: 100%; }
  @media (max-width: 991.98px) {
    .order-table {
      display: block; }
      .order-table tbody,
      .order-table thead {
        display: block;
        width: 100%; } }
  .order-table-row {
    position: relative; }
    @media (max-width: 991.98px) {
      .order-table-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 20px 20px 20px 110px; }
        .order-table-row:not(:last-child) {
          border-bottom: 1px solid #f2f2f2; } }
    @media (max-width: 599.98px) {
      .order-table-row {
        padding-left: 20px; } }
    .order-table-row-item {
      display: flex;
      align-items: center; }
      @media (max-width: 767.98px) {
        .order-table-row-item {
          display: block; } }
      .order-table-row-item__image-place {
        flex: 0 0 70px;
        max-width: 70px;
        width: 70px;
        height: 70px;
        line-height: 70px; }
        @media (max-width: 991.98px) {
          .order-table-row-item__image-place {
            position: absolute;
            left: 20px;
            top: 25px; } }
        @media (max-width: 599.98px) {
          .order-table-row-item__image-place {
            position: relative;
            top: 0 !important;
            left: 0;
            flex: none;
            max-width: 100%;
            margin-bottom: 15px; } }
      .order-table-row-item__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle; }
      .order-table-row-item__info {
        flex: 1 1 1px;
        padding-left: 20px; }
        @media (max-width: 991.98px) {
          .order-table-row-item__info {
            padding-left: 0; } }
        @media (max-width: 599.98px) {
          .order-table-row-item__info {
            flex: none;
            width: 100%; } }
      .order-table-row-item__title {
        margin-bottom: 6px; }
        .order-table-row-item__title a {
          color: #333 !important; }
    .order-table-row-value__title {
      display: none;
      font-size: 12px;
      color: #999; }
      @media (max-width: 991.98px) {
        .order-table-row-value__title {
          display: block; } }
    .order-table-row__price {
      font-weight: 700;
      font-size: 15px;
      line-height: 1.05;
      white-space: nowrap; }
    .order-table-row__price-old {
      font-size: 13px;
      color: #777;
      text-decoration: line-through;
      margin-left: 4px;
      white-space: nowrap; }
    @media (max-width: 991.98px) {
      .order-table-row_head {
        display: none; } }
    .order-table-row:last-child .order-table__column:not(.order-table__column_head) {
      border-bottom: 0; }
  .order-table__column {
    padding: 39px 10px 25px;
    border-top: none;
    border-bottom: 1px solid #f2f2f2;
    vertical-align: middle; }
    @media (max-width: 991.98px) {
      .order-table__column {
        display: block;
        border-bottom: 0;
        padding: 0 !important; } }
    .order-table__column_head {
      padding: 7px 10px 8px;
      font-size: 13px;
      color: #999; }
    .order-table__column_item {
      min-width: 300px; }
      @media (max-width: 991.98px) {
        .order-table__column_item {
          flex: 0 0 100%;
          max-width: 100%;
          min-width: 1px; } }
    .order-table__column_value {
      text-align: right; }
      @media (max-width: 991.98px) {
        .order-table__column_value {
          margin-top: 10px;
          flex: 0 0 25%;
          max-width: 25%;
          text-align: left; } }
      @media (max-width: 767.98px) {
        .order-table__column_value {
          flex: 0 0 50%;
          max-width: 50%; } }
    .order-table__column:first-child {
      padding-left: 30px; }
    .order-table__column:last-child {
      padding-right: 24px; }
  .order-table_slim .order-table__column {
    padding-top: 10px;
    padding-bottom: 10px; }
  @media (max-width: 991.98px) {
    .order-table_slim .order-table-row {
      padding-top: 10px;
      padding-bottom: 10px; }
    .order-table_slim .order-table-row-item__image-place {
      top: 12px; } }

.order-form-list-item {
  margin-top: 5px; }
  .order-form-list-item__title {
    display: inline;
    margin: 0 3px 0 0;
    color: #777;
    word-wrap: break-word; }
  .order-form-list-item__value {
    padding: 5px 7px 5px 9px;
    background: #f0f0f0;
    border: none;
    color: #333;
    border-radius: 10px;
    display: inline-block;
    line-height: 10px;
    vertical-align: top;
    margin: 0 0 0 5px; }

.order-form__description {
  max-width: 400px;
  margin: 8px 0 9px 0;
  color: #777;
  font-size: 13px; }

.order-form-pickup {
  border-top: 1px solid #fdd59e;
  font-size: 13px;
  word-break: break-word;
  background: #fff; }
  .order-form-pickup:last-child {
    border-bottom: 1px solid #fdd59e; }
  .order-form-pickup__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px; }
    @media (max-width: 599.98px) {
      .order-form-pickup__header {
        display: block; } }
  .order-form-pickup__title {
    padding: 16px 10px 16px 0;
    font-size: 13px;
    font-weight: 700;
    color: #fa9308; }
  .order-form-pickup__body {
    display: none;
    padding: 5px 35px 16px 35px;
    min-height: 80px; }
  .order-form-pickup__city {
    font-weight: 700; }
  .order-form-pickup.active {
    background: #fffaf3; }
    .order-form-pickup.active .order-form-pickup__choose {
      display: none; }
    .order-form-pickup.active .order-form-pickup__body {
      display: block; }

.order-total {
  border: 1px solid #f2f2f2;
  background: #fff;
  padding-bottom: 40px; }
  @media (max-width: 991.98px) {
    .order-total {
      margin-bottom: 20px; } }
  .order-total__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaebec;
    padding: 30px;
    margin-bottom: 40px; }
  .order-total__title {
    font-size: 16px;
    color: #333;
    font-weight: 700; }
  .order-total__body {
    padding-left: 30px;
    padding-right: 30px; }
  .order-total-list-item {
    display: flex;
    justify-content: space-between;
    margin: 2px 0 0;
    font-size: 14px; }
    .order-total-list-item_highlight {
      background: #ffd02e;
      padding: 3px 8px 2px;
      border-radius: 3px;
      margin: 10px 0 10px; }
  .order-total-info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 25px 0;
    border-top: 1px solid #eaebec;
    font-weight: 700; }
    .order-total-info__name {
      font-size: 16px; }
    .order-total-info__value {
      font-size: 18px; }
  @media (max-width: 991.98px) {
    .order-total__btn {
      display: none; } }

.order-grid {
  display: block; }
  .order-grid__left {
    max-width: none;
    width: 100%;
    float: left;
    padding-right: 300px; }
  .order-grid__right {
    float: right;
    width: 300px;
    margin-left: -300px;
    padding-top: 24px;
    max-width: none; }

.order-complete-container {
  margin: 0 auto;
  max-width: 730px; }

.order-payment {
  border: 1px solid #f2f2f2;
  padding: 34px 39px 34px; }
  @media (max-width: 599.98px) {
    .order-payment {
      padding-left: 20px;
      padding-right: 20px; } }
  .order-payment__header {
    margin: 0 0 22px; }
  .order-payment__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.1;
    color: #333; }
  .order-payment__view {
    display: flex;
    align-items: center; }
    @media (max-width: 599.98px) {
      .order-payment__view {
        flex-wrap: wrap; } }
  .order-payment__image-place {
    display: inline-block;
    margin: 0 25px 0 0;
    width: 120px;
    height: 72px;
    line-height: 68px;
    text-align: center;
    border: 1px solid #f2f2f2;
    vertical-align: middle; }
  .order-payment__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle; }
  .order-payment__name {
    margin: 10px 0;
    flex: 1 1 1px; }
  .order-payment__body {
    margin-top: 20px;
    padding: 24px 38px 24px 38px;
    border: 1px solid #f2f2f2; }
    @media (max-width: 599.98px) {
      .order-payment__body {
        padding-left: 15px;
        padding-right: 15px; } }

.page-404 {
  padding: 20px 0 50px; }
  .page-404__image {
    max-height: 225px;
    color: #fa9308; }
  .page-404__content {
    text-align: center; }
  .page-404__title {
    font-size: 44px;
    line-height: 1.1;
    color: #333; }
  .page-404__subtitle {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 1.3; }
  .page-404__desc {
    font-size: 13px;
    margin-top: 20px; }
  .page-404__footer {
    margin-top: 20px; }

.contacts-row {
  display: flex;
  border-bottom: 1px solid #f2f2f2; }
  .contacts-row__image-place {
    flex: 0 0 15%;
    max-width: 15%;
    padding: 20px; }
    @media (max-width: 767.98px) {
      .contacts-row__image-place {
        display: none; } }
  .contacts-row__img {
    max-width: 100%;
    max-height: 100%; }
  .contacts-row__content {
    flex: 1 1 1px;
    padding: 20px; }
    @media (max-width: 767.98px) {
      .contacts-row__content {
        padding-left: 10px;
        padding-right: 10px; } }
  .contacts-row__contacts {
    flex: 0 0 200px;
    max-width: 200px;
    padding: 20px; }
    @media (max-width: 767.98px) {
      .contacts-row__contacts {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        padding-left: 10px;
        padding-right: 10px; } }
  .contacts-row__payments {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 20px;
    line-height: normal; }
    @media (max-width: 991.98px) {
      .contacts-row__payments {
        display: none; } }
  .contacts-row__title {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 5px; }

.contacts-props {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px; }
  .contacts-props-item {
    position: relative;
    padding-left: 24px;
    display: block;
    margin-bottom: 3px;
    min-height: 17px; }
    .contacts-props-item__icon {
      position: absolute;
      top: 1px;
      left: 0;
      max-width: 17px;
      max-height: 17px;
      color: #fa9308; }

.index-section {
  padding: 30px 0; }
  .index-section__header {
    margin-bottom: 24px; }
  .index-section__title {
    display: inline-block;
    font-size: 20px;
    margin-right: 40px;
    color: #333; }
  .index-section_gray {
    background-color: #f9f9fa; }
  .index-section_with-slider .index-section__header {
    margin-bottom: 0;
    padding-right: 65px; }

.index-slider {
  display: none !important;
  overflow: hidden;
  margin-bottom: 30px; }
  @media (min-width: 600px) {
    .index-slider {
      display: block !important; } }
  .index-slider-item {
    display: flex !important;
    align-items: center;
    height: 460px;
    background: center bottom / cover; }
    @media (max-width: 991.98px) {
      .index-slider-item {
        height: 350px; } }
    @media (max-width: 767.98px) {
      .index-slider-item {
        height: 300px; } }
    .index-slider-item__content {
      margin-left: 90px;
      flex: 0 0 50%;
      max-width: 400px; }
      @media (max-width: 991.98px) {
        .index-slider-item__content {
          margin-left: 80px;
          max-width: 275px; } }
      @media (max-width: 767.98px) {
        .index-slider-item__content {
          margin-left: 30px; } }
    .index-slider-item__title {
      color: #333;
      font-size: 30px;
      line-height: 1.1;
      overflow: hidden; }
      @media (max-width: 991.98px) {
        .index-slider-item__title {
          font-size: 17px;
          line-height: 22px; } }
    .index-slider-item__text {
      margin-top: 11px;
      color: #333;
      font-size: 15px;
      overflow: hidden; }
    .index-slider-item__buttons {
      margin-top: 25px; }
    .index-slider-item__btn {
      margin-right: 10px;
      margin-bottom: 4px;
      padding: 10px 20px; }
    .index-slider-item.light .index-slider-item__title,
    .index-slider-item.light .index-slider-item__text {
      color: #fff; }
  .index-slider .slick-dots {
    position: absolute;
    z-index: 2;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 15px;
    margin: 0; }
    .index-slider .slick-dots li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 3px;
      border-radius: 50%;
      border: 2px solid #fff;
      background: transparent;
      cursor: pointer; }
      .index-slider .slick-dots li:before {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px; }
      .index-slider .slick-dots li button {
        display: none; }
      .index-slider .slick-dots li.slick-active {
        background-color: #fff; }
  .index-slider .slick-arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -21px;
    font-size: 0;
    line-height: 42px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 0;
    background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") left 7px center/30px 30px no-repeat;
    transition: all ease .3s; }
    .index-slider .slick-arrow:hover {
      background-color: #fa9308;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
    .index-slider .slick-arrow button {
      display: none; }
    .index-slider .slick-arrow.slick-disabled {
      opacity: 0;
      pointer-events: none; }
  .index-slider .slick-prev {
    left: -50px;
    transform: scaleX(-1); }
  .index-slider .slick-next {
    right: -50px; }
  .index-slider:hover .slick-prev {
    left: 20px; }
  .index-slider:hover .slick-next {
    right: 20px; }

@media (max-width: 599.98px) {
  .index-banner-cards {
    max-width: 350px;
    margin: 0 auto; } }

.index-banner-card {
  position: relative;
  z-index: 1;
  height: 215px;
  margin-bottom: 30px;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .index-banner-card {
      padding-bottom: 65%;
      height: auto;
      min-height: 180px; } }
  .index-banner-card:before {
    content: "";
    z-index: 1;
    position: absolute;
    height: 60%;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 100%); }
  .index-banner-card__bg {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: transform .6s ease;
    background: center / cover no-repeat; }
    .index-banner-card__bg:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1); }
  .index-banner-card__content {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    padding: 0 33px 0 20px; }
  .index-banner-card__tag {
    display: inline-block;
    font-size: 10px;
    background: #fa9308;
    padding: 5px 8px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase; }
  .index-banner-card__title {
    display: block;
    font-size: 17px;
    line-height: 20px;
    margin: 10px 0 0;
    max-height: 80px;
    overflow: hidden;
    color: #333 !important; }
  .index-banner-card:hover .index-banner-card__bg {
    transform: scale(1.25); }
    .index-banner-card:hover .index-banner-card__bg:before {
      opacity: 1; }
  .index-banner-card:hover .index-banner-card__title {
    color: #fff !important; }
  .index-banner-card.light .index-banner-card__title {
    color: #fff !important; }
  .index-banner-card.light:hover .index-banner-card__title {
    color: #fff !important; }

.index-iconblocks {
  margin-top: 30px;
  margin-bottom: -30px; }
  .index-iconblocks__iconblock {
    padding-left: 20px;
    margin-bottom: 30px; }
    @media (max-width: 1199.98px) {
      .index-iconblocks__iconblock {
        padding-left: 10px; } }
    @media (max-width: 767.98px) {
      .index-iconblocks__iconblock {
        padding-left: 0px; } }
    .index-iconblocks__iconblock .iconblock__image-place {
      flex: 0 0 80px;
      max-width: 80px;
      width: 80px;
      height: 80px;
      line-height: 80px; }
    @media (max-width: 599.98px) {
      .index-iconblocks__iconblock .iconblock__text {
        font-size: 12px;
        padding-left: 0;
        padding-right: 0; } }

.index-news-slider .slick-list {
  margin: 0 -15px;
  padding: 0 5px; }

.index-news-slider .slick-track {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 0; }

.index-news-slider .slick-slide {
  height: auto;
  margin: 0 15px; }

@media (min-width: 1200px) {
  .index-grid-col-xl {
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 992px) {
  .index-grid-col-lg {
    flex: 0 0 20%;
    max-width: 20%; } }

.index-techno-grid_1 {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 992px) {
    .index-techno-grid_1 {
      flex: 0 0 40%;
      max-width: 40%; } }

.index-techno-grid_2 {
  flex: 0 0 50%;
  max-width: 50%; }
  @media (min-width: 992px) {
    .index-techno-grid_2 {
      flex: 0 0 20%;
      max-width: 20%; } }

@media (max-width: 400px) {
  .index-techno-grid {
    flex: 0 0 100%;
    max-width: 100%; } }

.tizer-line {
  display: block;
  background: center / cover no-repeat;
  height: 150px; }
  @media (max-width: 991.98px) {
    .tizer-line {
      height: auto;
      padding-bottom: 17%; } }

.brands-slider {
  margin: 0; }
  .brands-slider__wrapper {
    border: 1px solid #f2f2f2;
    border-left-width: 0;
    border-right-width: 0;
    padding: 25px 0; }
  .brands-slider__item {
    display: inline-block;
    padding: 0 10px;
    height: 48px;
    line-height: 48px;
    margin: 0 15px;
    text-align: center;
    transition: all ease-out .2s;
    opacity: .5;
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter i…0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>");
    filter: grayscale(100%); }
    .brands-slider__item:hover {
      filter: saturate(120%);
      opacity: 1; }
  .brands-slider__img {
    display: inline-block !important;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto !important; }

.insta-slider {
  padding: 0;
  margin: 0;
  overflow: hidden; }
  .insta-slider-item__content {
    position: relative;
    z-index: 1;
    display: block;
    background: center / cover no-repeat;
    padding-bottom: 95%;
    overflow: hidden; }
    .insta-slider-item__content:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      display: block;
      opacity: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.7);
      transition: opacity .3s ease; }
  .insta-slider-item__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -100%;
    max-height: 90%;
    padding: 0 20px;
    color: #fff;
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 16px;
    transition: bottom .3s ease; }
  .insta-slider-item:hover .insta-slider-item__text {
    bottom: 0; }
  .insta-slider-item:hover .insta-slider-item__content:before {
    opacity: 1; }
  .insta-slider .slick-track {
    margin-left: 0; }
  .insta-slider .slick-arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -21px;
    font-size: 0;
    line-height: 42px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 0;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") left 7px center/30px 30px no-repeat;
    transition: all ease .3s; }
    .insta-slider .slick-arrow:hover {
      background-color: #fa9308; }
      .insta-slider .slick-arrow:hover:not(.slick-disabled) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
      .insta-slider .slick-arrow:hover.slick-disabled {
        background-color: #eee; }
    .insta-slider .slick-arrow button {
      display: none; }
  .insta-slider .slick-prev {
    left: -50px;
    transform: scaleX(-1); }
  .insta-slider .slick-next {
    right: -50px; }
  .insta-slider:hover .slick-prev {
    left: 5px; }
  .insta-slider:hover .slick-next {
    right: 5px; }

.sale-card {
  display: flex;
  padding-bottom: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #f2f2f2; }
  @media (max-width: 599.98px) {
    .sale-card {
      display: block; } }
  .sale-card__image-place {
    flex: 0 0 300px;
    max-width: 300px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .sale-card__image-place {
        flex-basis: 250px;
        max-width: 250px; } }
    @media (max-width: 599.98px) {
      .sale-card__image-place {
        padding: 0;
        padding-bottom: 24px;
        max-width: 100%;
        margin: 0 auto; } }
  .sale-card__img {
    display: inline-block;
    max-width: 100%;
    width: 100%; }
  .sale-card__body {
    flex: 1 1 1px;
    padding-left: 45px;
    padding-top: 12px; }
    @media (max-width: 599.98px) {
      .sale-card__body {
        padding-left: 0; } }
  .sale-card__title {
    display: block;
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 5px; }
    .sale-card__title a {
      color: #333; }
      .sale-card__title a:hover {
        color: #fa9308; }
  .sale-card__date {
    margin: 2px 0 9px;
    font-size: 12px; }
  .sale-card__description {
    margin: 5px 0 20px; }
  .sale-card__more {
    display: inline-block;
    white-space: nowrap;
    color: #333;
    font-size: 13px; }
    .sale-card__more:after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/70% 70% no-repeat;
      margin: 0 6px;
      vertical-align: middle; }
    .sale-card__more:hover {
      color: #fa9308; }
      .sale-card__more:hover:after {
        background-color: #fa9308;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
        color: #fff; }

.swimming-question {
  padding: 45px 25px 45px;
  font-size: 13px;
  background: #fbfbfb;
  border: 1px solid #f5f5f5;
  max-width: 280px;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 991.98px) {
    .swimming-question {
      max-width: 100%; } }
  .swimming-question__icon {
    content: "";
    display: inline-block;
    width: 88px;
    height: 80px;
    left: 50%;
    top: 0;
    background: url("../images/template/ask_message.png") center/contain no-repeat;
    margin-bottom: 20px; }
  .swimming-question__footer {
    margin-top: 20px; }
  .swimming-question_in-side {
    max-width: 100%; }

.sale-page-banner {
  display: flex;
  align-items: center;
  height: 450px;
  overflow: hidden;
  background: center / auto 100% no-repeat; }
  @media (max-width: 991.98px) {
    .sale-page-banner {
      height: 350px; } }
  @media (max-width: 767.98px) {
    .sale-page-banner {
      background: transparent !important;
      height: auto; } }
  .sale-page-banner__wrapper {
    width: 100%; }
  .sale-page-banner__content {
    padding: 20px 15px;
    width: 42%;
    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-delay: 0.6s;
    animation-fill-mode: both; }
    @media (max-width: 991.98px) {
      .sale-page-banner__content {
        width: 50%; } }
    @media (max-width: 767.98px) {
      .sale-page-banner__content {
        width: 100%;
        text-align: center;
        padding-top: 60px; } }
  .sale-page-banner__title .page-title {
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 1.3;
    color: #333;
    text-align: left; }
    @media (max-width: 991.98px) {
      .sale-page-banner__title .page-title {
        font-size: 25px; } }
    @media (max-width: 767.98px) {
      .sale-page-banner__title .page-title {
        text-align: center; } }
  .sale-page-banner__text {
    padding: 0 0 25px; }
  @media (min-width: 768px) {
    .sale-page-banner.light {
      color: #fff; }
      .sale-page-banner.light .sale-page-banner__title .page-title {
        color: #fff; } }

.sale-page__date {
  font-size: 12px; }

.sale-page__content {
  padding: 15px 0; }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.brand-detail {
  float: right;
  margin: 0 0 50px 50px;
  line-height: normal;
  width: 288px;
  max-width: 100%; }
  @media (max-width: 599.98px) {
    .brand-detail {
      float: none;
      margin: 0 auto 30px; } }
  .brand-detail__image-place {
    box-shadow: inset 0 0 0 1px #f5f5f5;
    display: block;
    padding: 80px 30px;
    line-height: normal;
    text-align: center; }
    @media (max-width: 599.98px) {
      .brand-detail__image-place {
        padding-top: 30px;
        padding-bottom: 30px; } }
  .brand-detail__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%; }

.cabinet-link {
  display: block;
  padding: 25px 15px;
  text-align: center;
  background-color: #fbfbfb;
  border: 1px solid #f2f2f2;
  height: 100%;
  color: #333;
  transition: box-shadow ease .1s, border-color ease .1s; }
  .cabinet-link:hover {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.075); }
  @media (max-width: 767.98px) {
    .cabinet-link {
      padding-top: 15px;
      padding-bottom: 15px; } }
  .cabinet-link__margin {
    margin-bottom: 30px; }
  .cabinet-link__icon {
    width: 50px;
    height: 50px;
    margin-top: 8px;
    display: inline-block;
    color: #fa9308; }
  .cabinet-link__title {
    font-size: 17px;
    margin: 25px 0 16px; }
    @media (max-width: 767.98px) {
      .cabinet-link__title {
        margin-top: 16px; } }

.cabinet-payment-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .cabinet-payment-header__sub {
    flex: 1 1 100%;
    font-size: 12px;
    color: #888;
    margin-bottom: 8px; }
  .cabinet-payment-header__sum {
    font-size: 36px;
    line-height: 1;
    color: #333;
    font-weight: 700;
    white-space: nowrap;
    margin-right: 26px; }
    @media (max-width: 599.98px) {
      .cabinet-payment-header__sum {
        font-size: 28px; } }
  .cabinet-payment-header__currency {
    color: #955d00;
    line-height: 1;
    font-weight: 600; }
    .cabinet-payment-header__currency-small {
      font-size: 0.8em; }

@media (max-width: 599.98px) {
  .cabinet-payment {
    text-align: center; } }

.cabinet-payment__sum-btn {
  padding: 5px 10px;
  min-width: 55px;
  font-size: 13px; }

.cabinet-payment__input {
  text-align: right; }

.payment-type {
  display: flex;
  align-items: center;
  color: #333; }
  @media (max-width: 500px) {
    .payment-type {
      justify-content: center;
      flex-wrap: wrap;
      text-align: center; } }
  .payment-type-view {
    position: relative;
    flex: 0 0 120px;
    max-width: 120px;
    height: 72px;
    border: 1px solid #f2f2f2;
    background: #fbfbfb;
    text-align: center;
    line-height: 72px; }
    .payment-type-view:before {
      box-sizing: border-box;
      content: "";
      position: absolute;
      top: 7px;
      left: 7px;
      width: 20px;
      height: 20px;
      border: 1px solid #f2f2f2;
      background: #fff center / 70% 70% no-repeat;
      border-radius: 100%;
      z-index: 1; }
    .payment-type-view__input {
      display: none; }
    .payment-type-view__icon {
      display: inline-block;
      max-width: 60px;
      max-height: 100%;
      vertical-align: middle; }
  .payment-type__title {
    display: block;
    flex: 1 1 1px;
    padding-left: 20px;
    font-size: 15px;
    font-weight: 700; }
    @media (max-width: 500px) {
      .payment-type__title {
        flex-basis: 100%;
        padding-left: 0;
        margin-top: 10px; } }
  .payment-type:hover, .payment-type.active {
    cursor: pointer;
    color: #fa9308; }
    .payment-type:hover .payment-type-view, .payment-type.active .payment-type-view {
      border-color: #fa9308; }
      .payment-type:hover .payment-type-view:before, .payment-type.active .payment-type-view:before {
        background-color: #fa9308; }
  .payment-type.active .payment-type-view:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E"); }
  .payment-type_link .payment-type-view:before {
    display: none !important; }

.order-item-step__sub {
  color: #777;
  margin-bottom: 8px; }

.order-item-step__title {
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  color: #333;
  margin-right: 15px; }

.order-item-step__label {
  display: inline-block;
  padding: 3px 10px 3px 10px;
  margin: 3px 0;
  font-size: 13px;
  font-weight: 700; }

.order-item-step__image-place {
  border: 1px solid #f2f2f2;
  height: 51px;
  line-height: 47px;
  vertical-align: middle;
  text-align: center;
  margin-bottom: 10px; }

.order-item-step__img {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle; }

.order-item-step-prop {
  padding: 4px 0 0 0;
  font-size: 13px; }
  .order-item-step-prop__name {
    display: inline-block;
    margin-right: 15px; }
  .order-item-step-prop__value {
    display: inline-block; }

.order-info {
  font-size: 15px; }
  .order-info__title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px; }

.services-item {
  display: flex; }
  @media (max-width: 599.98px) {
    .services-item {
      display: block; } }
  .services-item__image-place {
    flex: 0 0 300px;
    max-width: 300px; }
    @media (max-width: 767.98px) {
      .services-item__image-place {
        flex-basis: 240px;
        max-width: 240px; } }
    @media (max-width: 599.98px) {
      .services-item__image-place {
        padding: 0 0 24px;
        max-width: 100%;
        margin: 0 auto; } }
  .services-item__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    width: 100%; }
  .services-item__body {
    flex: 1 1 1px;
    padding-left: 50px;
    padding-top: 12px; }
    @media (max-width: 599.98px) {
      .services-item__body {
        padding-left: 0; } }
  .services-item__title {
    font-size: 16px;
    margin-bottom: 28px; }
    .services-item__title a {
      color: #333; }
  .services-item__text {
    margin-top: 5px;
    margin-bottom: 25px; }
  .services-item:hover .services-item__title a {
    color: #fa9308; }

.blog-side-column {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .blog-side-column__place {
    flex: 1 1 1px; }

.side-categories {
  background: #f9f9f9;
  padding: 21px 30px 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 30px; }
  .side-categories__header {
    padding-bottom: 19px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f1f1f1; }
  .side-categories__title {
    font-weight: 700;
    color: #333;
    font-size: 15px; }
  .side-categories-list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .side-categories-list__link {
      display: block;
      padding: 4px 0;
      color: #333; }
      .side-categories-list__link:after {
        content: "";
        display: table;
        clear: both; }
      .side-categories-list__link:hover {
        color: #fa9308; }
    .side-categories-list__name {
      font-size: 13px;
      padding: 0 12px 0 0; }
    .side-categories-list__value {
      display: inline-block;
      font-size: 12px;
      float: right;
      color: #999; }

.side-section {
  margin-bottom: 30px; }
  .side-section__header {
    padding-bottom: 16px; }
  .side-section__title {
    font-size: 15px;
    font-weight: 700; }

.blog-row__image-place {
  max-width: 100%; }

.blog-row__img {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  max-height: 100%; }

.blog-row__body {
  padding-top: 12px;
  padding-left: 20px; }

.blog-row__title {
  font-size: 16px;
  margin-bottom: 6px; }
  .blog-row__title a {
    color: #333; }

.blog-row__text {
  margin-top: 16px;
  margin-bottom: 9px; }

.blog-row__date {
  font-size: .929em;
  color: #9a9a9a;
  font-weight: 400;
  margin-top: 3px; }

.blog-row__footer {
  margin-top: 25px; }

.blog-row:hover .blog-row__title a {
  color: #fa9308; }

.blog-page__view {
  margin-bottom: 35px; }

.blog-page__footer {
  margin-top: 40px; }

.blog-page__meta {
  margin-bottom: 20px; }

.blog-page__title-text {
  font-weight: 500;
  color: #333;
  margin-bottom: 20px; }

.blog-page__date {
  display: inline-block;
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px; }

.blog-page-prop {
  display: inline-block;
  font-size: 13px;
  margin-right: 15px; }
  .blog-page-prop__icon {
    display: inline-block;
    width: 17px;
    height: 17px;
    vertical-align: middle;
    color: #333;
    margin-right: 3px; }

.blog-page-slider {
  text-align: center;
  margin-bottom: 20px; }
  .blog-page-slider-item {
    position: relative; }
  .blog-page-slider-item__img {
    display: inline-block !important;
    width: auto !important;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle; }
  .blog-page-slider__fancy {
    display: block;
    height: 100%;
    line-height: inherit; }
    .blog-page-slider__fancy:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      height: 50px;
      width: 50px;
      margin-left: -25px;
      margin-top: -25px;
      border-radius: 50%;
      background: #fa9308 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='11' y1='8' x2='11' y2='14'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E") left 10px top 10px/27px 27px no-repeat;
      opacity: 0;
      transition: opacity 150ms linear; }
    .blog-page-slider__fancy:hover:before {
      opacity: 1; }
  .blog-page-slider .slick-dots {
    position: absolute;
    z-index: 2;
    bottom: -35px;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0 15px;
    margin: 0; }
    .blog-page-slider .slick-dots li {
      position: relative;
      display: inline-block;
      height: 8px;
      width: 8px;
      margin: 4px;
      border-radius: 50%;
      background: #b9b9b9; }
      .blog-page-slider .slick-dots li:before {
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px; }
      .blog-page-slider .slick-dots li button {
        display: none; }
      .blog-page-slider .slick-dots li.slick-active {
        background-color: #fa9308; }
  .blog-page-slider .slick-arrow {
    position: absolute;
    z-index: 2;
    top: 50%;
    margin-top: -21px;
    font-size: 0;
    line-height: 42px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 0;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") left 7px center/30px 30px no-repeat; }
    .blog-page-slider .slick-arrow:hover {
      background-color: #fa9308; }
      .blog-page-slider .slick-arrow:hover:not(.slick-disabled) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
      .blog-page-slider .slick-arrow:hover.slick-disabled {
        background-color: #eee; }
    .blog-page-slider .slick-arrow button {
      display: none; }
  .blog-page-slider .slick-prev {
    left: 7px;
    transform: scaleX(-1); }
  .blog-page-slider .slick-next {
    right: 7px; }

.blog-page-thumbs-slider {
  padding: 0;
  margin: 0;
  list-style: none; }
  .blog-page-thumbs-slider__item {
    position: relative;
    display: block;
    padding-left: 0;
    margin: 0 10px;
    overflow: hidden;
    vertical-align: bottom;
    list-style: none;
    text-align: center;
    border: 2px solid #fff !important;
    transition: border-color ease-out .2s;
    cursor: pointer; }
    .blog-page-thumbs-slider__item.active {
      border-color: #fa9308 !important; }
  .blog-page-thumbs-slider__img {
    display: block;
    background: center / cover no-repeat; }
    .blog-page-thumbs-slider__img:before {
      display: block;
      content: "";
      width: 100%;
      padding-bottom: 100%; }
  .blog-page-thumbs-slider .slick-list {
    margin: 0 -10px; }
  .blog-page-thumbs-slider .slick-slide.slick-active .blog-page-thumbs-slider__item {
    border-color: #fa9308 !important; }
  .blog-page-thumbs-slider .slick-arrow {
    position: absolute;
    display: block;
    z-index: 2;
    top: 0;
    font-size: 0;
    line-height: 20px;
    width: 20px;
    height: 100%;
    border: 0;
    background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/15px 15px no-repeat; }
    .blog-page-thumbs-slider .slick-arrow:hover {
      background-color: #fa9308; }
      .blog-page-thumbs-slider .slick-arrow:hover:not(.slick-disabled) {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E"); }
      .blog-page-thumbs-slider .slick-arrow:hover.slick-disabled {
        background-color: #eee; }
    .blog-page-thumbs-slider .slick-arrow button {
      display: none; }
  .blog-page-thumbs-slider .slick-prev {
    left: -10px;
    transform: scaleX(-1); }
  .blog-page-thumbs-slider .slick-next {
    right: -10px; }

.comments-item {
  padding: 35px 0 17px;
  border-top: 1px solid #f2f2f2;
  margin-top: 10px; }
  .comments-item__header {
    margin-bottom: 7px; }
  .comments-item__body {
    font-size: 14px; }
  .comments-item__footer {
    padding-top: 13px; }
  .comments-item__name {
    color: #333;
    font-weight: 700;
    padding-right: 12px; }
  .comments-item__date {
    display: inline-block;
    color: #888;
    font-size: .867em;
    padding: 2px 4px; }
  .comments-item__link {
    display: inline-block;
    color: #888;
    margin-right: 17px;
    margin-bottom: 3px; }
    .comments-item__link:hover, .comments-item__link.active {
      color: #333; }
  .comments-item__link-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-top: -6px; }
  .comments-item_new .comments-item__date {
    background-color: #fff4ca; }

.project-order {
  display: flex;
  padding: 40px;
  background: #f9f9f9;
  border: #f2f2f2;
  color: #333; }
  @media (max-width: 767.98px) {
    .project-order {
      flex-wrap: wrap; } }
  @media (max-width: 599.98px) {
    .project-order {
      padding: 20px; } }
  .project-order__image-place {
    height: 60px;
    flex: 0 0 60px;
    max-width: 60px;
    margin-bottom: 10px; }
    @media (max-width: 767.98px) {
      .project-order__image-place {
        display: none; } }
  .project-order__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%; }
    .project-order__img.svg-inline {
      font-size: 40px; }
  .project-order__text {
    flex: 1 1 1px;
    color: #333;
    padding: 5px 40px 0 25px; }
    @media (max-width: 767.98px) {
      .project-order__text {
        max-width: calc(100% - 60px);
        padding-right: 0; } }
    @media (max-width: 599.98px) {
      .project-order__text {
        max-width: 100%;
        flex-basis: 100%;
        padding-left: 0;
        text-align: center; } }
  .project-order__button-place {
    flex: 0 0 auto; }
    @media (max-width: 767.98px) {
      .project-order__button-place {
        flex: 1 1 100%;
        max-width: 100%;
        margin-top: 15px;
        text-align: center; } }

.service-page-banner {
  display: flex;
  align-items: center;
  height: 450px;
  overflow: hidden;
  background: center / auto 100% no-repeat;
  margin-bottom: 30px; }
  @media (max-width: 991.98px) {
    .service-page-banner {
      height: 350px; } }
  @media (max-width: 767.98px) {
    .service-page-banner {
      background: transparent !important;
      height: auto; } }
  .service-page-banner__wrapper {
    width: 100%; }
  .service-page-banner__content {
    padding: 20px 15px;
    width: 42%;
    animation-name: fadeInUp;
    animation-duration: 0.8s;
    animation-delay: 0.6s;
    animation-fill-mode: both; }
    @media (max-width: 991.98px) {
      .service-page-banner__content {
        width: 50%; } }
    @media (max-width: 767.98px) {
      .service-page-banner__content {
        width: 100%;
        text-align: center;
        padding-top: 60px; } }
  .service-page-banner__title .page-title {
    font-weight: 400;
    margin-bottom: 15px;
    font-size: 30px;
    line-height: 1.3;
    color: #333;
    text-align: left; }
    @media (max-width: 991.98px) {
      .service-page-banner__title .page-title {
        font-size: 25px; } }
    @media (max-width: 767.98px) {
      .service-page-banner__title .page-title {
        text-align: center; } }
  .service-page-banner__text {
    padding: 0 0 25px; }
  @media (min-width: 768px) {
    .service-page-banner.light {
      color: #fff; }
      .service-page-banner.light .sale-page-banner__title .page-title {
        color: #fff; } }

.zoom-lens__btn {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  margin-left: -25px;
  margin-top: -25px;
  border-radius: 50%;
  background: #fa9308 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3Cline x1='11' y1='8' x2='11' y2='14'%3E%3C/line%3E%3Cline x1='8' y1='11' x2='14' y2='11'%3E%3C/line%3E%3C/svg%3E") left 10px top 10px/27px 27px no-repeat;
  opacity: 0;
  transition: opacity 150ms linear; }

.zoom-lens:hover .zoom-lens__btn {
  opacity: 1; }

.gallery-item {
  display: block;
  padding-bottom: 66.14%;
  background: center / cover no-repeat; }

.staff-page-section {
  margin-bottom: 40px; }
  .staff-page-section__title {
    margin-bottom: 26px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.1; }
  .staff-page-section__text {
    margin-bottom: 30px; }

.staff-card {
  position: relative;
  text-align: center;
  display: block;
  padding: 40px 35px 24px;
  margin: 0 -1px 0 0;
  border: 1px solid #f5f5f5;
  transition: box-shadow .3s ease;
  height: 100%; }
  .staff-card__col {
    margin-bottom: -1px; }
  .staff-card__image-place {
    border-radius: 100%;
    overflow: hidden;
    width: 150px;
    height: 150px;
    line-height: 147px;
    margin: 0 auto 23px;
    text-align: center; }
  .staff-card__img {
    display: inline-block;
    max-width: 100%;
    width: auto;
    max-height: 100%; }
  .staff-card__top {
    mragin-bottom: 16px; }
  .staff-card__name {
    font-weight: 700;
    margin-bottom: 1px;
    color: #333; }
  .staff-card__post {
    font-size: 13px;
    color: #888; }
  .staff-card__text {
    font-size: 13px;
    margin-bottom: 14px; }
  .staff-card__socials {
    margin-top: 20px; }
  .staff-card-socials-item {
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50%;
    margin: 1px 3px 0;
    background: #fa9308;
    transition: background ease .3s;
    color: #fff;
    font-size: 0;
    overflow: hidden;
    text-align: center; }
    .staff-card-socials-item__icon {
      display: inline-block;
      width: 16px;
      height: 16px;
      vertical-align: middle; }
    .staff-card-socials-item:hover {
      background: #333;
      color: #fff; }
  .staff-card:hover {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }

.vacancy-page-section {
  margin-bottom: 40px; }
  .vacancy-page-section__title {
    font-size: 22px;
    margin-bottom: 26px;
    line-height: 1.1; }

.vacancy-item {
  border: 1px solid #f2f2f2;
  margin-bottom: -1px; }
  .vacancy-item__header {
    display: block;
    position: relative;
    padding: 35px 80px 35px 45px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    color: #333;
    cursor: pointer;
    background: #f5f5f5;
    transition: background 0s linear; }
    @media (max-width: 767.98px) {
      .vacancy-item__header {
        padding-left: 25px;
        padding-right: 60px; } }
    .vacancy-item__header:after {
      content: "";
      display: table;
      clear: both; }
    .vacancy-item__header.collapsed {
      background: #fff;
      transition-delay: 350ms; }
      .vacancy-item__header.collapsed .vacancy-item__arrow {
        transform: none; }
    .vacancy-item__header:hover .vacancy-item__arrow {
      background-color: #f5f5f5; }
  .vacancy-item__name {
    display: inline-block; }
  .vacancy-item__pay {
    display: inline-block;
    float: right; }
    @media (max-width: 767.98px) {
      .vacancy-item__pay {
        float: none; } }
  .vacancy-item__arrow {
    display: block;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    right: 29px;
    margin: auto 0;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") center/90% 90% no-repeat;
    transform: scaleY(-1); }
    @media (max-width: 767.98px) {
      .vacancy-item__arrow {
        right: 20px; } }
  .vacancy-item__content {
    background: #f5f5f5;
    padding: 0 80px 30px 45px; }
    @media (max-width: 767.98px) {
      .vacancy-item__content {
        padding-left: 25px;
        padding-right: 25px; } }
  .vacancy-item__footer {
    margin-top: 20px;
    border-top: 1px solid #f0f0f0;
    padding-top: 20px; }
  .vacancy-item__properties {
    margin-bottom: 15px; }
  .vacancy-item__prop {
    display: inline-block;
    color: #555;
    margin-right: 15px;
    margin-bottom: 10px;
    font-size: 13px; }

.compare {
  position: relative; }
  @media (max-width: 599.98px) {
    .compare__nav-tabs .nav-item {
      width: 100%;
      text-align: center;
      margin-bottom: 0; } }
  .compare-available {
    padding: 5px 0; }
    .compare-available__btn {
      margin: 0 4px 4px 0; }
  .compare-header {
    position: relative;
    margin-bottom: 25px; }
    @media (max-width: 599.98px) {
      .compare-header {
        margin-bottom: 5px; } }
    .compare-header__clear-place {
      position: absolute;
      top: -7px;
      right: 0; }
      @media (max-width: 767.98px) {
        .compare-header__clear-place {
          position: static;
          padding: 10px 0; } }
    .compare-header__clear {
      border-color: #999;
      color: #999; }
  .compare-view {
    margin: 0 0 0 230px; }
    @media (max-width: 599.98px) {
      .compare-view {
        margin-left: 100px; } }
    .compare-view__frame {
      position: relative;
      overflow: hidden; }
    .compare-view__list {
      display: flex; }
    .compare-view-item {
      position: relative;
      flex: 0 0 230px;
      max-width: 230px;
      padding: 40px 20px 20px;
      font-size: 13px;
      overflow: hidden; }
      .compare-view-item__image-place {
        min-width: 70px;
        height: 170px;
        line-height: 170px;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        display: block; }
        @media (max-width: 599.98px) {
          .compare-view-item__image-place {
            height: 120px;
            line-height: 120px; } }
      .compare-view-item__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle; }
      .compare-view-item__title {
        display: block;
        padding: 14px 0 0;
        font-weight: 700; }
      .compare-view-item__price {
        margin: 10px 5px 0 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 1; }
  .compare-data {
    margin: 0 0 0 230px; }
    @media (max-width: 599.98px) {
      .compare-data {
        margin-left: 100px; } }
    .compare-data__frame {
      position: relative;
      overflow: hidden; }
      .compare-data__frame:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 1px;
        background: #f2f2f2; }
    .compare-data__wrap {
      margin-left: -230px; }
      @media (max-width: 599.98px) {
        .compare-data__wrap {
          margin-left: -100px; } }
    .compare-data-clone {
      position: absolute;
      left: 0;
      width: 231px;
      overflow: hidden; }
      @media (max-width: 599.98px) {
        .compare-data-clone {
          width: 101px; } }
  .compare-table__row.hovered .compare-table__cell {
    background: #f7f7f7; }
  .compare-table__cell {
    position: relative;
    width: 230px;
    min-width: 230px;
    max-width: 230px;
    height: 40px;
    border: 1px solid #f2f2f2;
    padding: 10px 27px 13px 18px; }
    .compare-table__cell_title {
      font-weight: 700; }
      @media (max-width: 599.98px) {
        .compare-table__cell_title {
          width: 100px;
          min-width: 100px;
          max-width: 100px;
          font-size: 10px;
          word-wrap: break-word;
          padding-left: 5px;
          padding-right: 20px; } }
  .compare-table__close {
    display: block;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E") center/70% 70% no-repeat; }
    .compare-table__close:hover {
      background-color: #fa9308;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E"); }
    .compare-table__close_in-cell {
      position: absolute;
      right: 15px;
      top: 12px;
      display: none; }
      @media (max-width: 599.98px) {
        .compare-table__close_in-cell {
          right: 7px; } }
      .compare-table__row.hovered .compare-table__close_in-cell {
        display: block; }
      .mobile .compare-table__close_in-cell {
        display: block; }
    .compare-table__close_in-card {
      position: absolute;
      right: 20px;
      top: 23px; }

.compare-scroll__wrap {
  position: relative;
  margin: 21px 0 33px 230px; }
  @media (max-width: 599.98px) {
    .compare-scroll__wrap {
      margin-left: 0; } }

.compare-scroll-scrollbar {
  margin: 0 38px 1em;
  background: #f2f2f2;
  height: 1px;
  opacity: 1; }
  .compare-scroll-scrollbar__bar {
    height: 5px;
    line-height: 0;
    display: block;
    position: relative;
    top: -3px;
    cursor: pointer; }
  .compare-scroll-scrollbar__handle {
    color: #FFF;
    background: #fa9308;
    width: 100px;
    height: 3px;
    position: relative;
    top: 2px;
    cursor: pointer;
    border-radius: 3px; }
  .compare-scroll-scrollbar__mousearea {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
    height: 20px; }

.compare-scroll-nav {
  list-style: none;
  padding: 0;
  margin: 0; }
  .compare-scroll-nav__area {
    position: absolute;
    right: 0;
    top: -14px;
    width: 100%;
    z-index: 1;
    margin: 0 0 27px; }
  .compare-scroll-nav__arrow {
    position: absolute;
    z-index: 3;
    width: 29px;
    height: 29px;
    display: inline-block;
    cursor: pointer;
    opacity: .7;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") center/80% 80% no-repeat; }
    .compare-scroll-nav__arrow_prev {
      left: 0;
      transform: scaleX(-1); }
    .compare-scroll-nav__arrow_next {
      right: 0; }
    .compare-scroll-nav__arrow:hover {
      opacity: 1; }
    .compare-scroll-nav__arrow.disabled {
      opacity: .5; }

.toast {
  background: #f6f6f7 !important;
  color: #555 !important; }

.toast-container > div {
  opacity: 1;
  padding-left: 30px; }

.toast-container.toast-bottom-full-width > div, .toast-container.toast-top-full-width > div {
  width: 100%;
  border-radius: 0; }

.toast-container.toast-bottom-full-width .toast {
  margin-bottom: 0;
  margin-top: 6px; }

.toast-close-button {
  width: 17px;
  height: 17px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23555' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E") center/100% 100% no-repeat !important;
  font-size: 0; }

.toast-container > div:hover {
  box-shadow: 0 0 12px #00000070; }

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0.65; }
  to {
    transform: scale(4);
    opacity: 0; } }

.poparea {
  margin: 30px 0;
  text-align: left; }
  .poparea-container {
    display: inline-block;
    position: relative;
    border: 6px solid #f2f2f2; }
  .poparea-pops {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .poparea-pops-item {
      position: absolute; }
  .poparea .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem; }

.poparea-pop {
  position: absolute !important;
  z-index: 1000;
  user-select: none !important; }
  .poparea-pop.show {
    z-index: 1001; }
  .poparea-pop__btn {
    position: relative;
    z-index: 2;
    top: -13px;
    left: -13px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-color: transparent;
    background: #fff;
    padding: 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.4); }
    @media (max-width: 767.98px) {
      .poparea-pop__btn {
        top: -10px;
        left: -10px;
        width: 15px;
        height: 15px; } }
    .poparea-pop__btn:before, .poparea-pop__btn:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: #fff;
      opacity: .8;
      border: 0;
      animation: pulse 3s infinite;
      pointer-events: none; }
    .poparea-pop__btn:before {
      animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
      animation-delay: 1s; }
    .poparea-pop__btn:after {
      animation-timing-function: ease; }
  .poparea-pop:hover .poparea-pop__btn {
    background: #fff;
    border-color: transparent; }
  .poparea-pop-view {
    z-index: 1;
    background: #fff;
    padding: 25px 20px;
    min-width: 200px; }
    .poparea-pop-view[x-placement^="bottom"] {
      margin-top: -13px; }
    .poparea-pop-view[x-placement^="top"] {
      margin-bottom: -13px; }
    .poparea-pop-view__image-place {
      display: block;
      width: 100%;
      text-align: center;
      height: 120px;
      line-height: 120px;
      margin-bottom: 15px; }
    .poparea-pop-view__image {
      display: inline-block;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle; }
    .poparea-pop-view__title {
      color: #333;
      font-weight: 700;
      font-size: 14px; }
      .poparea-pop-view__title:hover {
        color: #fa9308; }
    .poparea-pop-view__info {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #ddd; }
    .poparea-pop-view__price {
      font-size: 20px;
      line-height: 1; }

.project-card__col {
  margin-bottom: 30px; }

.project-card__image-place {
  text-align: center;
  vertical-align: middle;
  display: block;
  position: relative;
  overflow: hidden;
  max-height: 350px; }
  .project-card__image-place:hover .project-card-dark {
    opacity: .8; }
    .project-card__image-place:hover .project-card-dark__content {
      transform: translateY(0); }

.project-card__img {
  max-height: 100%;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.project-card-dark {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background: #2e2e2f;
  transition: opacity .2s ease;
  overflow: hidden; }
  .project-card-dark__content {
    position: relative;
    top: 0;
    left: 0;
    transition: transform .35s ease-out;
    color: #fff;
    transform: translateY(50%);
    font-weight: 400;
    font-size: 15px; }
  .project-card-dark__text {
    position: relative;
    padding: 0 30px 0 0;
    display: inline-block; }
    .project-card-dark__text:after {
      content: "";
      position: absolute;
      top: -1px;
      right: 0;
      display: block;
      width: 25px;
      height: 25px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3Cpolyline points='12 5 19 12 12 19'%3E%3C/polyline%3E%3C/svg%3E") center/100% 100% no-repeat; }

.project-card__body {
  padding: 20px 0 22px; }

.project-card__title {
  font-weight: 700;
  margin-bottom: 6px; }
  .project-card__title a {
    color: #333; }
    .project-card__title a:hover {
      color: #fa9308; }

.project-card:hover .project-card__title a {
  color: #fa9308; }

.project-tabs {
  margin: 0 -11px 47px;
  position: relative; }
  .project-tabs:after {
    content: "";
    display: block;
    height: 1px;
    margin: 0 11px;
    background: #f2f2f2;
    margin-top: -1px; }
  .project-tabs__link {
    display: inline-block;
    color: #333;
    padding: 0 11px; }
    .project-tabs__link.active, .project-tabs__link.mixitup-control-active {
      color: #fa9308; }
      .project-tabs__link.active .project-tabs__text:after, .project-tabs__link.mixitup-control-active .project-tabs__text:after {
        right: 0;
        background: #fa9308; }
  .project-tabs__text {
    position: relative;
    display: block;
    padding: 10px 0 24px;
    text-decoration: none; }
    .project-tabs__text:after {
      content: "";
      position: absolute;
      height: 2px;
      background: #fff;
      left: 0;
      right: 100%;
      bottom: 0;
      display: block;
      transition-property: right;
      transition-duration: .15s;
      transition-timing-function: ease-out; }
