html {
    font-size: 14px;
}

body {
    font-family: $font-family-theme;
    overflow-x: hidden;
    min-width: 300px;
    color: #555;
}

.container {
    max-width: 1440px;
}

svg {
    display: inline-block;
}

.feather {
    display: inline-block;
    vertical-align: middle;
}

.svg-inline {
    height: 1.4em;
    width: 1.4em;
}

a {
    text-decoration: none;
    color: $primary;
    transition: color ease-out 70ms;

    &:hover {
        color: $dark;
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &.link_dark {
        color: $dark;

        &:hover {
            color: $primary;
        }
    }
}

.link-more {
    display: inline-block;
    white-space: nowrap;
    color: $dark;
    font-size: 13px;

    &:after {
        content: "";
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: transparent url(svg-encode(svg-icon-chevron-right($dark))) center / 70% 70% no-repeat;
        margin: 0 6px;
        vertical-align: middle;
    }

    &:hover {
        color: $primary;

        &:after {
            background-color: $primary;
            background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
            color: #fff;
        }
    }
}

.link-collapse {
    position: relative;
    padding-right: 25px;

    &:before {
        content: "";
        position: absolute;
        right: 5px;
        top: 2px;
        bottom: 0;
        margin: auto 0;
        width: 15px;
        height: 15px;
        background: url(svg-encode(svg-icon-chevron-down(#555))) center / 100% 100% no-repeat;
        transform: rotateZ(180deg);
    }

    &.collapsed {

        &:before {
            transform: rotateZ(0deg);
        }
    }

}


.link-tag {
    background: #f5f5f5;
    padding: 5px 11px 6px;
    color: #999;
    font-size: .8em;
    border-radius: 4px;
    margin: 0 1px 5px;
    line-height: 15px;
    display: inline-block;

    &:hover {
        background: $primary;
        color: #fff;
    }

    &:active {
        color: $dark;
    }
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

button:focus {
    outline: none;
}


.form-control_white {
    background-color: #fff;
    border-color: #f2f2f2;

    &[readonly] {
        background-color: #fff;
    }

    &:focus {
        background-color: #fff;
    }
}

.custom-select_clean {
    background-image: none;
}

.custom-select {
    cursor: pointer;

    background-image: url(svg-encode(svg-icon-chevron-down($dark))), linear-gradient(to bottom, #f2f2f2, #f2f2f2);
    background-size: 15px 15px, 1px 1px;
    background-position: right 7px center, right 2rem top;
    background-repeat: no-repeat, repeat-y;

    padding-right: 2.7rem;

    &:hover {
        background-size: 15px 15px, 2rem 100%;
        background-position: right 7px center, right top;
    }

    &:active {
        background-image: url(svg-encode(svg-icon-chevron-down($dark))), linear-gradient(to bottom, #dddddd, #dddddd);
    }
}

.btn {
    white-space: nowrap;
}

.btn__icon {
    width: 1.4em;
    height: 1.4em;
}

.btn-icon {
    padding-left: .5em;
    padding-right: .5em;
}

.btn-primary {
    color: #fff;
}

.btn-outline-primary:hover {
    color: #fff;
}

.btn-light {
    border-color: #f2f2f2;
}

.btn-link {
    text-decoration: none !important;
}

.alert-warning {
    background-color: #fffcf4;
}

.alert-success {
    background-color: #f7fbf4;
}

.alert-danger {
    background-color: #fdf6f7;
    color: #e62222;
}

.alert-secondary {
    background: #f3f4f5;
    color: #535c69;
}

.text-muted {
    color: #999 !important;
}

.bg-black {
    background: #000;
}

.alert-dismissible {
    padding-right: 2.7rem;
}

.tooltip {

    &-inner {
        box-shadow: 0 0 15px rgba(0, 0, 0, .12);
    }
}

.table_thin {

    th {
        font-weight: 400;
        color: #999;

        &:first-child {
            border-left: 1px solid #f2f2f2;
        }

        &:last-child {
            border-right: 1px solid #f2f2f2;
        }
    }

    thead th {
        border-bottom-width: 1px;
    }

    td {
        border-top: 0;
        border-bottom: 1px solid $table-border-color;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.link_back-to-list {
    display: inline-block;
    position: relative;
    padding: 10px 20px 11px 64px;
    background: #fff;
    border: 1px solid #f2f2f2;
    line-height: 22px;
    box-shadow: none;
    z-index: 2;
    border-radius: 2px;
    color: #333;
    white-space: nowrap;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 44px;
        display: block;
        background: #f2f2f2;
        width: 1px;
        height: 100%;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 12px;
        margin: auto 0;
        display: block;
        background: url(svg-encode(svg-icon-chevron-right(#333))) center / 100% 100% no-repeat;
        transform: scaleX(-1);
        width: 21px;
        height: 21px;
    }

    &:hover {
        background: #f2f2f2;

        &:before {
            background: #fff;
        }
    }

}

.line-vertical-divider {
    display: inline-block;
    height: 1.5em;
    width: 1px;
    margin: 0 5px;
    vertical-align: middle;
    background: #dee2e7;
}

.nav-tabs {
    border-bottom-color: #f2f2f2;

    .nav-link {
        position: relative;
        background: #f9f9fa;
        border: 1px solid #f2f2f2;
        color: #222;
        margin: 0 -1px -1px 0;
        padding: 17px 25px 18px;
        text-decoration: none;
        border-radius: 0;
        font-weight: 700;
        text-transform: none;

        &:before {
            content: "";
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background: #ddd;
        }

        &:hover {
            color: $primary;
        }

        &.active {
            color: $primary;

            &:before {
                display: block;
                background: $primary;
            }
        }
    }

}

.nav-tabs_links {
    border: 0;
    padding-bottom: 5px;

    .nav-item {
        margin-right: 36px;
        margin-bottom: 6px;

        @include media-breakpoint-down(sm) {
            margin-right: 20px;
        }
    }

    .nav-link {
        font-size: 16px;
        line-height: 1.2;
        border: 0;
        border-bottom: 2px solid transparent;
        padding: 0 0 5px 0;
        font-weight: 700;
        color: $primary;
        background: transparent;

        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }

        &:before {
            display: none !important;
        }

        &:hover {
            color: $dark;
            border-bottom-color: transparent;

        }

        &.active {
            color: $dark;
            border-bottom-color: $primary;
        }
    }
}

.adaptive-tabs {

    &__nav {

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__tab-content {

        @include media-breakpoint-down(sm) {
            padding-top: 0 !important;
        }
    }

    &__tab-pane {

        @include media-breakpoint-down(sm) {
            display: block !important;
            opacity: 1 !important;
        }
    }

    &__header {
        display: none;
        position: relative;
        margin: 0 0 -1px 0;
        padding: 18px 45px 17px 25px;
        border: 1px solid #f5f5f5;
        border-radius: 0;
        font-weight: 700;
        font-size: 15px;
        color: #222;
        background: #fff;
        cursor: pointer;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        &:before {
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            right: 25px;
            font-weight: 400;
            background: url(svg-encode(svg-icon-chevron-down($dark))) center / 100% 100% no-repeat;
        }

        &:hover {
            color: $primary;

            &:before {
                background-image: url(svg-encode(svg-icon-chevron-down($primary)))
            }
        }

        &:not(.collapsed) {
            color: $primary;

            &:before {
                transform: rotateZ(180deg);
                background-image: url(svg-encode(svg-icon-chevron-down($primary)))
            }
        }
    }

    &__collapse {


        @include media-breakpoint-up(md) {
            display: block !important;
        }
    }

    &__inner {

        @include media-breakpoint-down(sm) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

.page-item {

    &.active {

        .page-link {
            color: #fff;
        }
    }
}

.page-link {
    font-weight: 700;
    border-radius: 2px;
    border: 0;
    color: $dark;
    background: transparent;

    &:hover {
        color: $primary;
        background: transparent;
    }

    &_arrow {
        height: 2.3em;
        width: 2.3em;
        background: url(svg-encode(svg-icon-chevron-right($dark))) center / 20px 20px no-repeat !important;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }

        &_left {
            transform: rotateZ(180deg);
        }

        &_right {

        }
    }
}


.captcha-row {
    position: relative;
    max-width: 418px;
    overflow: hidden;
}

.line-height-1 {
    line-height: 1
}

.line-height-2 {
    line-height: 1.15
}

.line-height-3 {
    line-height: 1.35
}

.line-height-4 {
    line-height: 1.4
}

.line-height-5 {
    line-height: 1.5
}


.modal {

    &-lg {

        @include media-breakpoint-up(lg) {
            max-width: 900px;
        }
    }

    .close {
        position: absolute;
        right: 33px;
        top: 50%;
        padding: 0;
        margin: -10px 0 0;
        font-size: 0;
        width: 20px;
        height: 20px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            margin-top: -1px;
            display: block;
            width: 100%;
            height: 2px;
            background: #888;
        }

        &:before {
            transform: rotateZ(45deg);
        }

        &:after {
            transform: rotateZ(-45deg);
        }
    }

    &-dialog {

    }

    &-content {
        border-radius: 0;
        border: 0;
        background-clip: border-box;
    }

    &-header {
        position: relative;
        padding: 34px 75px 35px 35px;
        border-bottom: 1px solid #f2f2f2;

        &_bordered {
            border-bottom: 2px solid $primary;
        }

        &_slim {
            padding-top: 25px;
            padding-bottom: 25px;
        }

        .modal-title {
            font-size: 22px;
        }
    }

    &-title {
        font-size: 26px;
        line-height: 1.1;
        color: #383838;
    }

    &-body {
        padding: 30px 35px 35px;

        &_grey {
            border-top: 1px solid #f2f2f2;
            background: #f8f8f8;
        }

        @media (max-width: 400px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &-footer {
        background: #f7f7f7;
        border-top: 1px solid #f2f2f2;
        padding: 28px 35px 37px;
        display: block;

        @media (max-width: 400px) {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

.page-preloader {
    display: none;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff url('../images/template/lazy.svg') center center / 450px 300px no-repeat;

    @at-root body.is-page-loading & {
        display: block;
    }
}

.page-to-up {
    display: block;
    position: fixed;
    right: 73px;
    bottom: 55px;
    width: 44px;
    height: 44px;
    opacity: 0;
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    background: $primary url(svg-encode(svg-icon-arrow-up(#fff))) center / 55% 55% no-repeat;
    z-index: 995;
    cursor: pointer;
    transition: opacity .3s;

    @include media-breakpoint-down(sm) {
        display: none;
    }

    &.active {
        opacity: 1;
    }
}

.loading-animation {
    background: scroll rgba(0, 0, 0, 0) url('../images/template/loading.svg') center center / 20px 20px no-repeat !important;
    border-color: transparent !important;
    color: transparent !important;
    cursor: default !important;

    * {
        background: rgba(0, 0, 0, 0) !important;
        border-color: transparent !important;
        color: transparent !important;
        cursor: default !important;
    }
}

.input-clear {
    position: relative;

    &__input {

    }

    &__btn {
        position: absolute;
        top: 0;
        height: 100%;
        right: 0;
        width: 37px;
        border: 0;
        padding: 0;
        color: #a1a1a1;
        background: transparent;
        box-shadow: none !important;
        outline: none !important;

        transition: 300ms color ease;

        &:after,
        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 2px;
            height: 14px;
            background: currentColor;
            content: '';
        }

        &:after {
            transform: rotate(45deg)
        }

        &:before {
            transform: rotate(-45deg)
        }

        &:hover {
            color: $dark;
        }

    }
}

.slider {

    .tooltip {
        display: none;
    }

    &-handle {
        display: inline-block;
        top: -2px;
        width: 18px;
        height: 18px;
        cursor: pointer;
        background: #fff;
        border-radius: 16px;
        border: 3px solid $primary;
    }

    &-track {
        box-shadow: none;
        background: #f2f2f2;
    }

    &-selection {
        background: $primary;
        box-shadow: none;
    }

    &.slider-horizontal {
        display: block;
        margin-left: 9px;
        margin-right: 9px;
        width: auto;

        .slider-track {
            height: 4px;
        }

        .slider-handle {
            margin-left: -9px;
        }

        .min-slider-handle {
            margin-left: -9px;
        }

        .max-slider-handle {
            margin-right: -9px;
        }
    }

}


label {
    color: #888;
    font-size: 13px;
    margin-bottom: 6px;
}

.label-required {
    color: $danger;
    font-size: 12px;
    font-weight: 400;
}

.custom-control {

    &-input {

        &:not(:disabled):hover ~ .custom-control-label::before {
            background-color: $primary;
            border-color: $primary;
        }
    }

    &-label {
        cursor: pointer;

        &:before,
        &:after {
            margin-top: -0.15em;
        }

        &:before {
            background-color: #fff;
            border-color: #ccc;
        }

        &:after {
            background-size: 65% 65%;
        }
    }

    &-lg {
        padding-left: 2.2rem;

        .custom-control-label {
            font-size: 15px;

            &::before,
            &::after {
                left: -2.2rem;
                top: .1rem;
                width: 1.5rem;
                height: 1.5rem;
            }
        }

    }

    &_strong {


        .custom-control-label {
            font-weight: 700;
            color: $dark;
        }
    }
}

.custom-control-lg-padding {
    padding-left: 2.2rem;
}


.dropdown {
    &-menu {
        background-color: #fff;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .1);
        border: 0;
        border-radius: 0;
    }

    &-item {

        &.active,
        &:active {

        }
    }
}

.shine {
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 0;
        left: -45%;
        z-index: 2;
        display: block;
        content: '';
        width: 25%;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .3) 100%);
        transform: skewX(-25deg);
    }

    &:hover {

        &:before {
            animation: shine .5s;
            opacity: 1;
        }
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.blink {

    &:hover {
        animation: flash .8s;
    }
}

@keyframes flash {
    0% {
        opacity: .55;
    }
    100% {
        opacity: 1;
    }
}

.colorbox {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-size: 0;
    line-height: 0;

    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid #f2f2f2;
    }

    &__item {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &:nth-child(2) {
            left: 50%;
            width: 50%;
        }

        &:nth-child(3) {
            left: 50%;
            top: 50%;
            width: 50%;
            height: 50%;
        }

        &:nth-child(4) {
            left: 0%;
            top: 50%;
            width: 50%;
            height: 50%;
        }
    }
}

.close-button {
    display: inline-block;
    padding: 0;
    width: 28px;
    height: 28px;
    box-shadow: none !important;
    outline: none !important;
    border: 0;
    background: url('../vendors/feather/icons/x.svg') center / 100% 100% no-repeat;
}

.sign-in-socials {

    &__title {
        font-size: 15px;
        color: #222;
        margin-bottom: 15px;
        line-height: 1.2;
    }

    &__socials-icons {

    }
}

.breadcrumb {
    padding: 0;
    padding-bottom: 11px;
    margin: 0;
    background: transparent;

    &-item {
        font-size: 12px;
        color: #b6b5b5;

        & + & {

            &:before {
                padding-right: 5px;
                content: "-";
            }
        }

        & > a {
            color: #b6b5b5;

            &:hover {
                color: $primary;
            }
        }

        & > a,
        & > span {
            cursor: pointer;
        }

        &.active {
            color: #b6b5b5;
        }

        &_dropdown {
            padding-right: 18px;

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0.58em;
                bottom: 0;
                border: 3px solid transparent;
                border-top-color: $muted;
                right: 0.5em;
                margin: auto 0;
            }

            &:not(:hover) > a {
                border-bottom: 1px dotted #b4b4b4;
            }
        }
    }
}

.breadcrumb-dropdown-menu {
    border-top: 2px solid $primary;
    padding: 12px 12px 5px;
    font-size: 11px;
    line-height: 1.2;

    &__item {
        padding: 0;
        margin-bottom: 7px;

        &.active,
        &:active {
            background: #fff;

            .breadcrumb-dropdown-menu__link {
                color: $primary;
            }
        }
    }

    &__link {
        display: block;
        color: #1d2029;

        &:hover {
            color: $primary;
        }
    }
}

.hint-toggle {
    border: 1px solid #e5e5e5;
    display: inline-block;
    text-align: center;
    padding: 0;
    height: 17px;
    width: 17px;
    line-height: 15px;
    border-radius: 50%;
    color: #444;
    font-size: 12px;

    &:hover {
        color: #fff;
        background: $primary;
        border-color: $primary;
    }
}

.countdown {
    height: 2.866em;
    display: inline-flex;
    flex-wrap: wrap;
    overflow: hidden;
    border-radius: 2px;
    font-size: 15px;
    margin-bottom: 5px;

    &-item {
        display: inline-block;
        position: relative;
        height: 2.866em;
        width: 2.666em;
        padding: 0.4666em 0 0;
        margin: 0;
        vertical-align: top;
        font-weight: 700;
        font-size: 1em;
        text-align: center;
        background: #eee;
        border-radius: 0;
        line-height: 1;


        &:before,
        &:after {
            content: "";
            background: #333;
            width: 3px;
            height: 3px;
            display: block;
            position: absolute;
            right: -1px;
            border-radius: 6px;
            z-index: 10
        }

        &:before {
            top: 1em
        }

        &:after {
            bottom: 1em
        }

        &__text {
            opacity: .6;
            font-weight: 400;
            font-size: 0.8em;
            line-height: 1.2;
            display: block;
            width: auto;
            text-transform: lowercase;
        }

        &_days {

        }

        &_hours {

        }

        &_minutes {

        }

        &_seconds {

        }

        &:last-child {
            &:before,
            &:after {
                display: none;
            }
        }
    }
}

.filter-price {

    &__ranges {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            height: 1px;
            width: 12px;
            left: 50%;
            margin-left: -6px;
            background: #ccc;
        }
    }

    &__range-place {
        flex: 0 0 50%;
        max-width: 50%;

        &_left {
            padding-right: 10px;
        }

        &_right {
            padding-left: 10px;
        }
    }


    &__range {
        width: 100%;
        background: #fff;
        padding: 8px;
        color: #666;
    }
}

.lazy-youtube {
    position: absolute;
    background: #424242 center / cover no-repeat;
    cursor: pointer;

    &:before {
        content: "";
        position: absolute;
        height: 100px;
        width: 100%;
        background-repeat: repeat-x;
        background-position: top;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
        pointer-events: none;
    }

    &__btn {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        width: 68px;
        height: 48px;
        margin-left: -34px;
        margin-top: -24px;
        background: #212121;
        opacity: 0.8;
        border-radius: 15px;
        padding: 0;
        border: 0;

        transition: color .25s cubic-bezier(0.0, 0.0, 0.2, 1),
        opacity .25s cubic-bezier(0.0, 0.0, 0.2, 1);


        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            border: 11px solid transparent;
            border-left: 20px solid #fff;
            margin-top: -11px;
            margin-left: -7px;
        }
    }

    &:hover {

        .lazy-youtube__btn {
            background: #f00;
            opacity: 1;
        }
    }
}

.share {
    display: inline-block;

    &__menu {
        position: absolute !important;
        width: 145px;
        left: 0 !important;
        right: 0 !important;
        margin: 10px auto 10px;
        padding: 20px 20px 15px 23px;
        box-shadow: 0 0 15px rgba(0, 0, 0, .12);
        border-radius: 2px;
        border-color: transparent;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 11px solid transparent;
            border-bottom-color: #fff;
            left: 50%;
            top: -16px;
            margin-left: -11px;
        }

        &[x-placement^="top"] {

            &:before {
                top: auto;
                bottom: -16px;
                transform: scaleY(-1);
            }
        }

        &_in-header {
            left: auto !important;
            right: -10px !important;
            transform:  none !important;
            top: 100% !important;
            bottom: auto !important;

            &:before {
                top: -16px !important;
                transform: none !important;
                bottom: auto !important;
                right: 20px !important;
                left: auto !important;
            }
        }
    }

    &__btn {
        padding: 8px 20px 10px 20px;
        background: #fff;
        border: 1px solid #f2f2f2;
        line-height: 22px;
        box-shadow: none;
        z-index: 2;
        border-radius: 2px;
        color: #333;

        &:hover {
            background: #f2f2f2;
        }

        &_with-icon {
            position: relative;
            padding-right: 64px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 44px;
                display: block;
                background: #f2f2f2;
                width: 1px;
                height: 100%;
                z-index: 1;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 12px;
                margin: auto 0;
                display: block;
                background: url(svg-encode(svg-icon-share-2(#333))) center / 100% 100% no-repeat;
                width: 17px;
                height: 18px;
            }

            &:hover {

                &:before {
                    background: #fff;
                }
            }
        }
    }

    .ya-share2__badge {
        border-radius: 3px;
    }

    .ya-share2__container_size_m .ya-share2__icon {
        height: 28px;
        width: 28px;
    }

    .ya-share2__container_size_m .ya-share2__item {
        margin-right: 5px;
        margin-bottom: 5px;
    }


    &_adaptive {

        @include media-breakpoint-down(sm) {
            .share__btn {
                font-size: 0;
                padding: 0;
                width: 46px;
                height: 46px;

                &:before {
                    display: none;
                }
            }

            .share__menu {
                right: 0 !important;
                left: auto !important;
                transform: none !important;
                top: 100% !important;

                &:before {
                    left: 85%;
                }
            }
        }
    }
}

.share-pop {

    &__content {
        position: absolute;
        background: #f2f2f2;

        min-width: 50px;
    }
}

.divider-line {
    height: 2px;
    background: $primary;
    margin:  20px 0;
}

.file-type {
    position: relative;
    margin-top: 15px;
    padding-left: 46px;

    &__icon {
        position: absolute;
        display: block;
        width: 45px;
        height: 35px;
        left: 0;
        top: 3px;

        background: url('../images/template/icons/file_type.png') top left / auto 100% no-repeat;

        &:before {
            content: "FILE";
            position: absolute;
            right: 10px;
            top: 10px;
            min-width: 27px;
            font-size: 9px;
            text-transform: uppercase;
            color: #fff;
            background: #999;
            border: 1px solid #fff;
            text-align: center;
        }
    }

    &__info {

    }

    &__text {
        display: block;
        word-break: break-word;
    }

    &__size {
        color: #888;
    }


    &_pdf {

        .file-type__icon:before {
            content: "PDF";
            background-color: #e9372f;
        }
    }

    &_doc {

        .file-type__icon:before {
            content: "DOC";
            background-color: #3591d7;
        }
    }

    &_jpg {

        .file-type__icon:before {
            content: "JPG";
            background-color: #ffa200;
        }
    }

    &_png {

        .file-type__icon:before {
            content: "PNG";
            background-color: #5870eb;
        }
    }

    &_ppt {

        .file-type__icon:before {
            content: "PPT";
            background-color: #ff6600;
        }
    }

    &_txt {

        .file-type__icon:before {
            content: "TXT";
            background-color: #999;
        }
    }

    &_tif {

        .file-type__icon:before {
            content: "TIF";
            background-color: #1254c3;
        }
    }

    &_xls {

        .file-type__icon:before {
            content: "XLS";
            background-color: #7bad1b;
        }
    }
}

.iconblock {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
    }

    &__image-place {
        display: block;
        flex: 0 0 70px;
        max-width: 70px;
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        background: #fff;
        border-radius: 50%;

        @include media-breakpoint-down(sm) {
            display: inline-block;
            margin-bottom: 10px;
        }
    }

    &__img {
        display: inline-block;
        max-width: 44px;
        max-height: 44px;
        vertical-align: middle;
    }

    &__text {
        flex: 1 1 1px;
        display: block;
        font-size: 13px;
        line-height: 1.2;
        padding-left: 20px;
        padding-right: 10px;

        @include media-breakpoint-down(sm) {
            padding-left: 10px;
        }

        a {
            color: #555;

            &:hover {
                color: $primary;
            }
        }
    }
}

.icon-badge {
    display: inline-block;
    border-radius: 27px;
    padding: 0 12px;
    height: 30px;
    font-size: 13px;
    line-height: 29px;
    color: $dark;
    background: #efefef;
    margin: 2px 0;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &__icon {
        max-width: 20px;
        max-height: 20px;
        margin-right: 2px;
    }
}

.circle-iconblock {
    display: flex;
    margin-bottom: 30px;

    &__icon-place {
        flex: 0 0 45px;
        max-width: 45px;
        height: 45px;
        padding: 10px;
        border-radius: 50%;
        background: $primary;
        margin-right: 20px;
        text-align: center;
        line-height: 21px;
        color: #fff;
        font-size: 18px;
        overflow: hidden;
    }

    &__icon {
        max-width: 100%;
        max-height: 100%;
    }

    &__body {
        font-size: 12px;
    }
}

.page-section {
    margin-top: 15px;

    &__header {
        padding: 25px 0;
    }

    &__title {
        font-size: 19px;
        color: $dark;

        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }

    &__body {
        padding-bottom: 25px;
    }

    &_with-slider {

        .page-section__header {
            padding-right: 80px;
        }
    }

    &_border {
        padding-top: 15px;
        border-top: 1px solid rgba(0, 0, 0, .1);
    }
}

// Media item

.media-item {
    display: block;
    position: relative;
    min-height: 78px;
    text-decoration: none;
    color: #2f383d !important;
    border-top: 1px solid #f6f6f6;
    padding: 5px 0;

    &__row {
        display: flex;
        align-items: center;
        height: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__image-place {
        flex-shrink: 0;
        padding-left: 25px;
        width: 100px;
        height: 90px;
        line-height: 90px;
        text-align: left;
    }

    &__image {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }

    &__content {
        padding-left: 25px;
        line-height: 1.1;
    }

    &__title {

    }

    &__info {
        margin-top: 10px;
    }

    &__price {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        margin-right: 7px;
    }

    &__price-discount {
        display: inline-block;
        font-size: 13px;
        line-height: 15px;
        font-weight: 400;
        color: #888;
        text-decoration: line-through;
        margin-right: 7px;
    }
}

// /Media item

// Burder button

.burger-btn {
    position: relative;
    display: inline-block;
    padding: 0;
    height: 40px;
    width: 40px;
    border: 0;
    background: transparent;
    vertical-align: middle;

    &__icon {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        height: 21px;
        width: 21px;
        margin: -10.5px 0 0 -10.5px;

        &_close {
            display: none;
        }
    }

    &:hover {
        color: $primary;
    }

    &.active {

        .burger-btn__icon {

            &_open {
                display: none;
            }

            &_close {
                display: block;
            }
        }
    }
}

// /Burger button


// Icon link

.icon-link {
    display: inline-flex;
    align-items: flex-start;
    height: 24px;
    margin: 0 5px;

    &__icon {
        position: relative;
        flex-shrink: 0;
        color: #222;

        &-img {
            display: inline-block;
            width: 24px;
            height: 24px;
            opacity: 0.5;
        }
    }

    &__count {
        font-size: 11px;
        border-radius: 100%;
        text-align: center;
        color: #fff;
        width: 20px;
        height: 20px;
        display: inline-block;
        line-height: 20px;
        position: absolute;
        top: -7px;
        left: 16px;
        box-shadow: 0 0 0 2px #fff;
        background: #b0b0b0;
    }

    &__value {
        flex-shrink: 0;
        margin-left: 6px;
    }

    &__title {
        display: block;
        font-size: 13px;
        line-height: 1.1;
        font-weight: 700;
        margin-bottom: 4px;
    }

    &__desc {
        display: block;
        font-size: 11px;
        line-height: 1.2;
        color: #999;
        max-width: 62px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &_only-icon {

        .icon-link__value {
            display: none;
        }
    }

    &_with-count {
        .icon-link__icon {
            margin-right: 14px;
        }
    }

    &:hover {
        color: $primary;

        .icon-link__icon {
            color: $primary;
        }
    }

    &.active {

        .icon-link__icon-img {
            opacity: 1;
        }

        .icon-link__count {
            background: $primary;
        }
    }
}

// /Icon Link

// Count Link

.count-link {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    &__icon {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 20px;
    }

    &__title {

    }

    &__count {
        display: inline-block;
        width: 17px;
        height: 17px;
        line-height: 17px;
        border-radius: 8px;
        text-align: center;
        white-space: nowrap;
        font-size: 12px;
        color: #fff;
        background: #b0b0b0;
        font-weight: initial;
        margin-left: 7px;

        box-shadow: 0 0 0 2px #fff;
    }

    &.active {

        .count-link__count {
            background: $primary;
        }
    }
}

// /Count Link

.section {
    margin-bottom: 30px;
}

/*
 * Header
 */

.header {
    position: relative;

    &-top {
        display: none;
        position: relative;
        border-bottom: 1px solid #f6f6f6;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding-top: 12px;
            padding-bottom: 12px;
        }

        &__actions {
            display: flex;
            flex-shrink: 0;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &-middle {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-bottom {
        display: none;
        border-bottom: 1px solid #f6f6f6;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &-sticky {
        display: none;
        position: fixed;
        visibility: hidden;
        z-index: $zindex-fixed;
        top: 0;
        left: 0;
        right: 0;
        background: #fff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .136);
        transform: translateY(-100%);
        transition: transform 300ms linear, visibility 0ms linear 300ms;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &_fixed {
            visibility: visible;
            transform: translateY(0%);
            transition: transform 300ms linear;
        }

        &__container {

        }

        &__row {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__actions {
            display: flex;
            margin: 0 -5px;
            padding-left: 10px;
        }

        &__smartmenu-place {
            flex-grow: 1;
            padding: 0 25px;
        }
    }

    &-sticky-search {
        display: none !important;

        @include media-breakpoint-up(sm) {
            display: block !important;
        }

        &.show {

            .header-sticky-search__wrapper {
                visibility: visible;
                transform: translateY(0%);
                transition: transform 300ms linear;
            }

            .header-sticky-search__results.show {
                display: block;
            }

            .header-sticky-search__backdrop {
                display: block;
            }
        }

        &__wrapper {
            position: fixed;
            visibility: hidden;
            z-index: 1030;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: 0 1px 5px rgba(0, 0, 0, .136);

            transform: translateY(-100%);
            transition: transform 300ms linear, visibility 0ms linear 300ms;

            background: #fff;
        }

        &__backdrop {
            position: fixed;
            display: none;
            z-index: 1029;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(#3a3f44, .7);
        }

        &__results {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            overflow: auto;
            max-height: 400px;
            background: #fff;

            @include scrollbar-cute;
        }
    }
}

.header-logo {
    display: inline-block;
    width: 16.6%;
    max-width: 205px;
    height: 104px;
    line-height: 101px;
    padding-right: 15px;
    min-width: 150px;

    &__img {
        max-height: 100%;
    }

    &_in-sticky {
        max-width: 170px;
        height: 40px;
        line-height: 40px;
    }
}

.header-city {
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;

    margin-left: 30px;
    padding: 0 10px 0 20px;

    &__title {
        font-weight: 700;
        color: #333;
        font-size: 14px;
    }

    &__icon {
        position: absolute;
        left: 0;

        width: 17px;
        height: 17px;
    }

    &__value {
        position: relative;
        display: block;
        padding-right: 14px;
        font-size: 12px;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 3px;
            border: 3px solid transparent;
            border-top-color: #333;
        }
    }

    &__dropdown-place {
        line-height: 0;
        font-size: 0;
        text-align: right;
    }

    &__dropdown {
        display: inline-block;
        margin-right: 4px;

        .dropdown-menu {
            transform: translateX(-50%) translateY(10px) !important;

            &:before {
                content: "";
                position: absolute;
                bottom: 100%;
                left: 50%;
                margin-left: -7px;
                margin-bottom: -7px;
                display: inline-block;
                width: 15px;
                height: 15px;
                background: #fff;
                box-shadow: -1px -1px 1px 0 rgba(0, 0, 0, .03);
                transform: rotateZ(45deg);
            }
        }
    }
}

.header-search {
    position: relative;
    flex-grow: 1;

    margin-left: 15px;

    &__input {
        padding: 0 44px 0 17px;
        height: 40px;
        font-size: 13px;
        line-height: 40px;
        background: #f5f5f5;
        border: 1px solid #eee;

        &:focus {
            box-shadow: none;
            outline: none;
            background: #f5f5f5;
            border-color: #eee;
        }
    }

    &__btn {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0 15px;
        border: 0;
        outline: 0 !important;
        box-shadow: none !important;

        &-icon {
            width: 16px;
            height: 16px;
        }
    }

    &__results {
        display: none;
        position: absolute;
        z-index: 1001;
        top: 100%;
        left: 0;
        width: 100%;
        overflow: auto;
        max-height: 400px;
        background: #fff;
        box-shadow: $theme-box-shadow;

        @include scrollbar-cute;

        &.show {
            display: block;
        }
    }
}

.header-actions {
    display: flex;
    padding-left: 30px;
    margin: 0 -5px;
}

.top-menu {

    &__item {
        font-size: 12px;
        margin-right: 20px;

        &:first-child {
            margin-left: 0;
        }

        &.active {

            .top-menu__link {
                color: $primary;
            }
        }
    }

    &__link {
        color: #777;
        padding: 0;
    }
}

.top-call {
    display: inline-block;
    padding: 0 8px;
    line-height: 18px;

    &__phone {
        display: inline-block;
        padding: 0 7px;
        font-weight: 700;

        &-icon {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 15px;
            height: 13px;
            margin-right: 7px;
            vertical-align: middle;
        }

        &-value {
            color: #222;
        }
    }

    &__order {
        display: inline-block;
        padding: 0 7px;
        font-size: 12px;
    }
}

.top-login {
    display: inline-block;
    padding-left: 15px;
    color: #222;
    font-size: 12px;
    white-space: nowrap;

    &__icon {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 12px;
    }

    &__value {
        display: inline-block;
        vertical-align: middle;
        max-width: 85px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// Smartmenu

.smartmenu {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #f6f6f6;

    &__list {
        display: flex;
        width: 100%;
    }

    &-item {
        flex-grow: 1;
        max-width: 300px;

        &__link {
            display: block;
            position: relative;
            padding: 18px 18px 25px;
            height: 59px;
            color: #222;
            font-weight: 700;
            font-size: 15px;
            text-align: center;

            &_with-icon {
                padding-left: 50px;
            }

            @at-root .smartmenu-item.active &,
            &:hover {
                color: $primary;

                .smartmenu-item__link-value:after {
                    width: 100%;
                }
            }
        }

        &__link-value {
            position: relative;
            display: inline-block;
            white-space: nowrap;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                background: $primary;
                width: 0%;
                height: 2px;
                bottom: -19px;

                transition: width .15s ease-out;
            }
        }

        &__link-icon {
            position: absolute;
            display: block;
            top: 50%;
            left: 19px;
            width: 22px;
            height: 22px;
            transform: translateY(-50%);
        }


        &_dropdown {

            .smartmenu-item__link {
                padding-right: 50px;

                &:after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 24px;
                    right: 20px;
                    width: 14px;
                    height: 14px;
                    background: url(svg-encode(svg-icon-chevron-down(#222))) center / 100% 100% no-repeat;
                }

                &:hover {
                    &:after {
                        background-image: url(svg-encode(svg-icon-chevron-down($primary)));
                    }
                }

            }

            &.active {

                .smartmenu-item__link:after {
                    background-image: url(svg-encode(svg-icon-chevron-down($primary)));
                }
            }


        }

        &_in-drop {

            &:hover {

                .smartmenu-item-dropdown {
                    display: block;
                }
            }

            .smartmenu-item__link {
                padding: 14px 29px 14px 19px;
                font-weight: 400;
                height: auto;
                line-height: 1.4;
                text-align: left;

                &-value {

                    &:after {
                        display: none;
                    }
                }

                &:after {
                    transform: rotateZ(-90deg);
                    right: 7px;
                    position: absolute;
                    top: 50%;
                    margin-top: -5px;
                    height: 11px;
                    width: 11px;
                }
            }

        }

        &_dropdown-simple {
            .smartmenu-item-dropdown {
                max-width: 200px;
            }
        }

        &_in-drop,
        &_dropdown-simple {
            position: relative;

            .smartmenu-item-dropdown {
                top: 100%;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                width: auto;
                max-width: none;
                max-height: none;
                margin-top: -2px;
                border-top: 2px solid $primary;

                &__container {
                    display: block;
                    padding: 0;
                }

                &-item {
                    display: block;
                    max-width: 100%;
                    margin: 0;
                    padding: 0;
                    min-height: 0;


                    &__image {
                        display: none;
                    }

                    &__title {
                        display: block;
                        font-weight: 400;
                        font-size: 15px;
                        padding: 14px 29px 14px 19px;
                    }

                    &-list {
                        display: none;
                    }

                    &:not(:first-child) {
                        border-top: 1px solid #f0f0f0;
                    }
                }
            }

        }

        &_in-drop {
            .smartmenu-item-dropdown {
                top: 0 !important;
                right: 100% !important;
                left: auto !important;
                margin-top: -1px;
                border-top: 1px solid #f2f2f2;
            }

            &:not(:first-child) {
                border-top: 1px solid #f0f0f0;
            }
        }

        &_in-sticky:not(&_in-drop) {
            .smartmenu-item__link {
                padding-left: 12px;
                padding-right: 12px;

                &:after {
                    //display: none;
                }
            }

            &.smartmenu-item_dropdown .smartmenu-item__link {
                padding-right: 40px;
            }
        }
    }

    &-dropdown {
        flex-shrink: 0;

        &:hover {
            .smartmenu-dropdown__menu {
                // display: block;
            }
        }

        &__link {
            display: block;
            width: 60px;
            height: 59px;
            line-height: 59px;
            color: #222;
            text-align: center;
        }

        &__menu {
            padding: 0;
            min-width: 220px;
            white-space: nowrap;
            transform: none !important;
            top: 100% !important;
            right: 0 !important;
            left: auto !important;
            margin-top: -1px;
            border-radius: 0;
            border: none;
            border-top: 2px solid $primary;

        }
    }


    // modifer "in-sticky" for menu in sticky header line

    &_in-sticky {
        position: static;
        border-top: 0;
    }
}

.smartmenu-item-dropdown {
    left: 0 !important;
    width: 100%;
    max-height: 485px;
    top: 100% !important;
    transform: none !important;
    margin-top: 0;
    padding: 0;
    border: 0;
    border-top: 1px solid #f2f2f2;
    overflow: auto;

    @include scrollbar-cute;

    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 25px 30px 10px;
    }

    &-item {
        position: relative;
        flex: 0 0 25%;
        max-width: 25%;
        margin: 0 0 30px;
        padding: 0 20px 0 80px;
        min-height: 60px;
        line-height: 1.4;

        @include media-breakpoint-down(lg) {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }

        &__image {
            position: absolute;
            left: 0;
            top: 0;
            width: 60px;
            height: 60px;
            background: left top / contain no-repeat;
        }

        &__title {
            display: block;
            font-weight: 700;
            font-size: 13px;
            white-space: normal;
            padding: 2px 29px 5px 19px;
            color: #222;
        }

        &-list {
            list-style: none;
            padding-left: 0;
            margin: 0;

            &__item {

                &.active {

                    .smartmenu-item-dropdown-item-list__link {
                        color: $primary;
                    }
                }
            }

            &__link {
                display: inline-block;
                color: #666;
                font-size: 13px;
                padding: 4px 0 4px 20px;

                &:hover {
                    color: $primary;
                }
            }
        }

        &:hover,
        &.active {

            .smartmenu-item-dropdown-item__title {
                color: $primary;
            }
        }
    }
}

// /Smartmenu

// Search row

.search-row {
    display: flex;
    align-items: center;

    &__input-place {
        flex-grow: 1;
    }

    &__actions {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        padding-right: 10px;
    }

    &__input {
        width: 100%;
        height: 128px;
        background: #fff;
        border: 0;
        font-size: 28px;
        color: #444;
        padding: 0 200px 0 25px;
        box-shadow: none !important;
        outline: 0 !important;
    }

    &__btn {
        font-size: 15px;
        font-weight: 700;
        padding: 9px 25px;
    }

    &__close-button {
        margin-left: 30px;
    }
}

// /Search Row

/*************************
 * Mobile Header and Side
 ************************/

// -- Mobile Header

.mobile-header {

    &__burger-btn {
        height: 100%;
        min-height: 58px;
        width: 58px;
    }

    &-logo {
        display: inline-block;
        width: 160px;
        height: 60px;
        line-height: 60px;
        padding-right: 5px;

        &__img {
            max-width: 100%;
            max-height: 100%;
        }

        @media (max-width: 400px) {
            width: 112px;
        }
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.mobile-header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    background: #fff;
    border-bottom: 1px solid #f5f5f5;

    &__left {

    }

    &__right {

    }

    &__actions {
        margin: 0 -5px;
        padding-left: 10px;
    }
}

.mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    max-width: 100%;
    z-index: 1030;
    overflow: hidden;
    overflow-y: auto;
    background: #fff;

    transform: translateX(-100%);
    transition: transform .4s ease 0s, opacity .4s ease 0s;

    &.show {
        transform: translateX(0);
    }

    &__scroller {
        overflow: hidden;
    }

    &__wrapper {
        transition: transform 200ms linear;
    }

    &-overlay {
        position: fixed;
        display: none;
        left: 0;
        top: 0;
        z-index: 1029;
        height: 100%;
        width: 100%;
        background: rgba(#3a3f44, .7);
        z-index: 99;
    }

    @include scrollbar-cute;
}


// /-- Mobile Header


// -- Mobile Side

.mobile-menu-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        display: block;
        list-style: none;
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #f2f2f2;

        &_grey {
            background: #fbfbfb;
        }

        &_back {

            .mobile-menu-list__link {
                padding-left: 57px;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    width: 22px;
                    height: 22px;
                    margin-top: -12px;
                    background: url('../vendors/feather/icons/arrow-left.svg') center / contain no-repeat;
                }
            }
        }

        &_title {
            background-color: #fbfbfb;

            .mobile-menu-list__link {
                font-weight: 700 !important;
                color: $primary;
            }
        }

        &_parent {

            & > .mobile-menu-list__link {
                padding-right: 46px;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    margin: -3px 0 0 -3px;
                    border: 3px solid transparent;
                    border-left-color: currentColor;
                }
            }
        }
    }

    &__link {
        padding: 19px 20px 20px 19px;
        display: block;
        position: relative;
        color: #222;
        font-weight: 700;
        font-size: 15px;

        &:hover {

            @at-root a#{&} {
                color: $primary;
            }
        }

        &_active {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                width: 2px;
                height: 100%;
                background: $primary;
            }
        }
    }

    &__link-icon {
        position: relative;
        top: -2px;
        max-width: 17px;
        max-height: 17px;
        margin-right: 20px;
    }

    &_child {
        display: none;
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;

        .mobile-menu-list__link {
            font-weight: 400;

            &_active {
                &:before {
                    height: 4px;
                    width: 4px;
                    left: 9px;
                    border-radius: 50%;
                }
            }
        }
    }

    &_expanded {
        display: block;
    }
}

.mobile-menu-block {

    &__title {
        font-weight: 700;
        padding: 19px 20px 6px 19px;
    }

    &_socials {
        padding: 6px 0 30px;
    }
}

.mobile-contacts {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-bottom: 10px;

    &__item {
        list-style: none;
    }

    &__link {
        display: block;
        padding: 12px 20px 0 50px;
        position: relative;
        line-height: 18px;
        font-size: 13px;
        color: #333;

        &:hover {
            @at-root a#{&} {
                color: $primary;
            }
        }
    }

    &__icon {
        display: inline-block;
        position: absolute;
        left: 20px;
        top: 12px;
        width: 18px;
        height: 18px;
    }

    &__socials {

        .mobile-contacts__icon {
            top: 11px;
            width: 20px;
            height: 20px;
        }
    }
}

// /-- Mobile Side

.footer {
    background: #f6f6f7;

    &-main {
        padding: 34px 0 28px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    &-bottom {

        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        &__container {
            padding-top: 36px;
            padding-bottom: 30px;
            border-top: 1px solid #eee;
        }
    }
}

.footer-block {
    margin-bottom: 20px;

    &__title {
        color: #222;
        font-weight: 700;
        margin-bottom: 14px;
        font-size: 15px;

        a {
            color: #222;

            &:hover {
                color: $primary;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &_contacts {
            padding-left: 12%;
        }
    }
}

.footer-list {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 1.4;

    &__item {
        list-style: none;

        &.active {

            .footer-list__link {
                color: $primary;
            }
        }
    }

    &__link {
        display: block;
        color: #555;
        margin-bottom: 10px;

        &:hover {
            color: $primary;
        }
    }
}


.footer-input-group {

    &__input {
        background: #fff;
        height: 42px;

        &:focus {
            background: #fff;
        }
    }

    &__btn {
        height: 42px;
        width: 42px;
        line-height: 40px;
        padding: 0;
    }

    &__icon {
        display: inline-block;
        width: 23px;
        height: 23px;
    }
}

.footer-subscribe {
    background: #f9f9f9;

    @include media-breakpoint-down(sm) {
        text-align: center;
    }

    &__container {
        position: relative;
        padding-top: 51px;
        padding-bottom: 51px;

        &:before {
            content: "";
            position: absolute;
            left: 15px;
            top: 0;
            width: 185px;
            height: 100%;
            background: url('../images/template/plane-black.png') -6px 0 no-repeat;
            opacity: .05;
        }
    }

    &__inner {
        max-width: 1089px;
        margin: 0 auto;
    }

    &__text {
        font-size: 16px;
        line-height: 1.3;
        font-weight: 700;

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }

    &__input-group {

    }
}

.footer-slider {

    &__container {
        padding-top: 10px;
        padding-bottom: 25px;
    }

    &__header {
        padding-top: 25px;
        padding-right: 70px;
    }

    &__title {
        font-size: 18px;
    }

    &__slider {

        .slick-list {
            margin: 0 -10px;
            padding: 0 5px;
        }

        .slick-track {
            display: flex;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-left: 0;
        }

        .slick-slide {
            position: relative;
            height: auto;
            margin: 0 5px;

            &.slick-active:hover {
                z-index: 3;
            }
        }

    }

    &__item {
        background: #f0f0f1;
        box-shadow: inset 0 0 0 1px #eee;

    }
}

.socials-icons {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;

    &__item {
        display: inline-block;
    }

    &__link {
        display: block;
        height: 40px;
        width: 40px;
        margin: 0 10px 5px 0;
        overflow: hidden;
        border-radius: 100%;
        font-size: 0;
        background: url('../images/template/socials_sprite.png') no-repeat;

        &_ok {
            background-position: -4px -4px;
        }

        &_vk {
            background-position: -253px -4px;
        }

        &_fb {
            background-position: -53px -4px;
        }

        &_ya {
            background-position: -404px -54px;
        }

        &_tw {
            background-position: -104px -4px;
        }

        &_mail {
            background-position: -3px -54px;
        }

        &_inst {
            background-position: -154px -4px;
        }

        &_google {
            background-position: -453px -4px;
        }

        &_youtube {
            background-position: -204px -4px;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    &_in-footer {
        text-align: right;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }
}

.pay-system-icon {
    display: inline-block;
    height: 20px;
    width: 34px;
    background: url('../images/template/pay_sprite.png') no-repeat;

    &_cash {
        background-position: 0px -1px;
    }

    &_master {
        background-position: -42px -1px;
    }

    &_visa {
        background-position: -89px -1px;
    }

    &_sberbank {
        background-position: -259px -1px;
    }

    &_alfa {
        background-position: -305px -1px;
    }

    &_qiwi {
        background-position: -222px -1px;
        width: 21px;
    }

    &_webmoney {
        background-position: -182px -1px;
        width: 21px;
    }

    &_yandex {
        background-position: -142px -1px;
        width: 21px;
    }
}


.footer-contacts {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        list-style: none;

        @include media-breakpoint-down(sm) {
            display: inline-block;
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    &__link {
        position: relative;
        display: block;
        color: #333;
        padding: 0 0 0 32px;
        margin-bottom: 22px;

        &:hover {

            @at-root a#{&} {
                color: $primary;
            }

            .footer-contacts__icon {
                color: currentColor;
            }
        }
    }

    &__icon {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        color: #000;
    }
}

.footer-pay-systems {
    padding: 0;
    margin: 0;
    list-style: none;

    @include media-breakpoint-up(md) {
        text-align: right;
    }

    @include media-breakpoint-between(md, md) {
        max-width: 240px;
        margin-left: auto;
    }

    @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
    }

    &__item {
        display: inline-block;
        margin: 0 11px 9px 0;

        &:last-child {
            margin-right: 0;
        }
    }
}

.footer__copyright {
    color: #888;
    padding-right: 20px;
    padding-top: 10px;

    @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-bottom: 20px;
    }
}

.page-title__place {
    word-wrap: break-word;

    .page-title {
        @include media-breakpoint-down(sm) {
            text-align: center;
        }

        @include media-breakpoint-down(xs) {
            font-size: 26px;
        }
    }
}

.inner-page {
    padding-top: 30px;
    padding-bottom: 50px;

    @include media-breakpoint-down(sm) {
        padding-bottom: 20px;
    }

    &-header {
        padding-bottom: 30px;
        min-height: 60px;

        &__inner {
            position: relative;
            padding-top: 5px;
        }

        &__actions {
            float: right;

            @include media-breakpoint-down(sm) {
                float: none;
                text-align: right;
            }
        }

        &__btn {
            position: relative;
            border: 0;
            padding: 12px;
        }
    }
}


.l-page-grid {
    position: relative;
    display: flex;

    &__side {
        display: none;
        flex: 0 0 258px;
        max-width: 258px;
        margin-right: 30px;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        @media (max-width: 1299px) {
            flex-basis: 234px;
            max-width: 234px;
        }

        @include media-breakpoint-down(lg) {
            flex-basis: 210px;
            max-width: 210px;
        }

        &_right {
            margin-right: 0;
            margin-left: 30px;
            order: 2;
        }
    }

    &__main {
        flex: 1 1 1px;
        max-width: 100%;
    }

    &_with-sidebar {

        .l-page-grid__main {
            max-width: 100%;

            @include media-breakpoint-up(lg) {
                max-width: calc(100% - 258px - 30px);
            }

            @media (max-width: 1299px) {
                max-width: calc(100% - 234px - 30px);
            }

            @include media-breakpoint-down(lg) {
                max-width: calc(100% - 210px - 30px);
            }

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }
        }
    }
}


.catalog-root {

    &__row {

    }

    &__col {

    }

    &-item {
        font-size: 13px;
        padding: 30px 30px;
        border: 1px solid #f2f2f2;
        height: 100%;
        margin: 0 0 -1px -1px;
        transition: box-shadow ease .2s, border ease-out .2s;

        @media (max-width: 400px) {
            padding: 22px 22px 17px;
            height: auto;
        }

        &__image-place {
            display: block;
            flex: 0 0 140px;
            max-width: 140px;
            padding: 0 25px 10px 0;

            @include media-breakpoint-down(md) {
                max-width: 90px;
                flex-basis: 90px;
            }

            @media (max-width: 400px) {
                flex: auto;
                max-width: 100%;
                padding: 0;
                max-height: 120px;
                text-align: center;
            }
        }

        &__image {
            max-width: 100%;
            max-height: 100%;
        }

        &__header {
            display: flex;

            @media (max-width: 400px) {
                display: block;
            }
        }

        &__name {
            display: inline-block;
            margin-bottom: 8px;
            font-size: 16px;
            line-height: 1.1;
            color: #222;
        }

        &-sections {
            padding: 0;
            margin: 0;
            list-style: none;

            &__item {
                display: inline-block;
                padding: 0 8px 0 0;
            }

            &__link {
                color: #666;

                &:hover {

                    .catalog-root-item-sections__count {
                        color: currentColor;
                    }
                }
            }

            &__count {
                color: #222;
            }
        }

        &__desc {
            display: block;
            padding: 0;
            margin: 11px 0 0;
            color: #888;
            font-size: 13px;

            @media (max-width: 400px) {
                display: none;
            }
        }

        &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, .1);
            border-color: #fff;
        }
    }
}


.side-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 30px;

    &__item {
        position: relative;
        display: block;

        &:hover {
            .side-menu__link {
                color: $primary;
                background: #fff;

                &:before {
                    background: $primary;
                }

                &:after {
                    background-image: url(svg-encode(svg-icon-chevron-right($primary)));
                }
            }

            .side-menu-dropdown {
                display: flex;
            }
        }

        &_dropdown {

            .side-menu__link {

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 13px;
                    height: 13px;
                    top: 0;
                    bottom: 0;
                    right: 16px;
                    margin: auto 0;

                    background: url(svg-encode(svg-icon-chevron-right($muted))) center / 100% 100% no-repeat;
                }

            }
        }

        &.active {

            .side-menu__link {
                background: $primary;
                color: #fff;

                &:after {
                    background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
                }
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        border: 1px solid #f2f2f2;
        background: #f8f8f9;
        padding: 11px 31px 13px 17px;
        color: #333;
        font-size: 13px;
        cursor: pointer;

        &:not(:last-child) {
            border-bottom-width: 0;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 3px;
            height: 100%;
            background: transparent;
        }
    }
}

.side-menu-dropdown {
    display: none;
    position: absolute;
    left: 100%;
    top: 2px;
    padding: 20px 0 20px;
    min-width: 500px;
    background: #fff;
    border: 0;
    margin-top: -1px;
    flex-wrap: wrap;

    &-item {
        position: relative;
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 0 0 100px;
        margin-bottom: 20px;
        min-height: 50px;

        &__link {
            display: block;
            position: relative;
            padding: 9px 0 0 0;
            font-weight: 700;
            font-size: 13px;

            &:hover {
                color: #333;
            }
        }

        &__image {
            position: absolute;
            left: 30px;
            top: 0;
            width: 50px;
            height: 50px;
            background: left top / contain no-repeat;
        }

        &__name {

        }

        &-list {
            padding: 0;
            margin: 0;
            margin-top: 9px;
            list-style: none;

            &__item {
                padding: 0 10px 0 0;
                display: inline-block;

                &.active {

                    .side-menu-dropdown-item-list__link {
                        color: $primary;
                    }
                }
            }

            &__link {
                color: #555;
                line-height: 1.2;
            }
        }

        &.active {

            .side-menu-dropdown-item__link {
                color: $dark;
            }
        }
    }


}

.side-subs {
    border: 1px solid #f2f2f2;
    background: #f9f9f9;
    border-radius: 2px;
    position: relative;
    margin: 0 0 30px;

    &__header {
        padding: 13px 20px 14px;
        border-bottom: 1px solid #f2f2f2;
        font-weight: 700;
        color: $dark;
    }

    &__title {

    }

    &__body {
        background: #fff;
        padding: 10px;
    }

    &-list {
        padding: 0 10px;
        margin: 0;
        list-style: none;
        font-size: 13px;

        &__item {
            padding: 3px 0;

            &.active {

                .side-subs-list__link {
                    color: $primary;
                }
            }
        }

        &__link {
            display: block;
            position: relative;
            color: $dark;

            &:hover {
                color: $primary;
            }
        }

        &__name {
            padding-right: 30px;
            display: inline-block;
        }

        &__value {
            position: absolute;
            right: 0;
            top: 0;
            width: 25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: right;
        }
    }
}


.side-form {
    margin-bottom: 30px;
    padding: 17px 20px 22px;
    border-radius: 2px;

    &__title {
        font-size: 16px;
        font-weight: 700;
    }

    &__text {
        font-size: 13px;
        line-height: 1.2;
        opacity: 0.8;
    }

    &__input-group {
        margin-top: 11px;
    }

    &__ps {
        margin-top: 10px;
        font-size: 12px;
        line-height: 1.2;
    }

    &_subscribe {
        position: relative;
        z-index: 1;
        background: darken($primary, 3);
        color: #fff;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../images/template/envelope.png') 113px 17px no-repeat;
            opacity: 0.2;
        }

        .side-form__ps a {
            color: #eee;

            &:hover {
                color: #fff;
            }
        }
    }


}

.side-news {
    margin: 0 0 30px;
    padding: 13px 0 0;
    border: 1px solid #f2f2f2;
    border-radius: 2px;

    &__header {
        padding: 0 19px 12px;
        margin: 0;
    }

    &__title {
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
    }

    &__link {
        float: right;
    }

    &-list {

        &-item {
            position: relative;
            padding: 16px 19px 18px;
            margin: 0 0 0;
            overflow: hidden;
            border-bottom: 1px solid #ebeef1;
            color: #666;
            font-size: 13px;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom-width: 0;
            }

            &:before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 2px;
                background: $primary;
                bottom: 0;
                transition: top .3s ease;
            }

            &__date {

            }

            &__text {
                display: block;
            }

            &:hover {

                &:before {
                    top: 0;
                }
            }
        }
    }
}


.panel {
    background: #fff;
    border: 1px solid #f2f2f2;
    margin-bottom: 20px;
    margin-top: -1px;

    &__header {
        position: relative;
        padding: 29px 19px 29px 79px;
        margin-bottom: -1px;
    }

    &__title {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 600;
        color: $dark;

        @include media-breakpoint-down(xs) {
            font-size: 18px;
        }
    }

    &__icon {
        position: absolute;
        left: 27px;
        top: 23px;
        width: 30px;
        height: 30px;
        color: $primary;
    }

    &__content {
        padding: 0 29px 25px 79px;
        font-size: 15px;

        @include media-breakpoint-down(md) {
            padding-left: 29px;
        }
    }

    &__body {
        padding: 25px;
    }

    &_gray {
        .panel__header {
            background: #fbfbfb;
            border-bottom: 1px solid #f2f2f2;
        }
    }

}

.simple-text-block {

    blockquote {
        position: relative;
        margin: 43px 0 45px;
        min-height: 40px;
        padding-left: 25px;
        vertical-align: middle;
        border: none;

        &:before {
            position: absolute;
            left: 0;
            bottom: 3px;
            content: " ";
            display: block;
            top: 3px;
            width: 3px;
            background: $primary;
        }
    }

    h2, h3, h4, h5, h6 {
        margin: 40px 0 26px;

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    p {
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        padding: 0;
        padding-left: 15px;
        margin-bottom: 27px;

        ul, ol {
            padding-left: 0;
        }

        & > li {
            margin: 0 0 4px;
            padding-left: 20px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: 7px;
                height: 7px;
                left: 0;
                top: 0.6em;
                border-radius: 50%;
                background: $primary;
            }
        }
    }

    ol {
        list-style: none;
        padding: 0;
        padding-left: 15px;
        counter-reset: li;
        margin-bottom: 27px;

        ul, ol {
            padding-left: 0;
        }

        & > li {
            margin: 0 0 4px;
            padding-left: 20px;
            position: relative;
            counter-increment: li;

            &:before {
                content: counter(li) ". ";
                position: absolute;
                width: 20px;
                top: 0;
                left: 0;
                border-radius: 50%;
            }
        }
    }

    ol ol, ol ul, ul ol, ul ul {
        margin-bottom: 0;
    }

    figure {
        margin: 30px 0;
    }

    figcaption {
        display: block;
        text-align: center;
        margin-top: 10px;
        font-size: 16px;
        color: #999;
    }
}

.item-card {
    position: relative;
    background: #fff;

    &__image-place {
        width: 100%;
        margin: 0 auto;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        width: 100%;
        max-height: 100%;
    }

    &__body {
        padding: 20px 0 22px;
    }

    &__title {
        font-weight: 700;
        margin-bottom: 6px;
        line-height: 1.3;
        word-wrap: break-word;

        a {
            color: $dark;
        }
    }

    &__text {
        font-size: 13px;
        margin-top: 9px;
        line-height: 1.7;
    }

    &__date {
        font-size: .929em;
        color: #9a9a9a;
        font-weight: 400;
        margin-top: 3px;
    }

    &__margin {
        margin-bottom: 30px;
    }

    &__gradient {
        display: none;
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, .5);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    }

    &:hover {
        z-index: 1;

        .item-card__title a {
            color: $primary;
        }
    }

    &_card {
        box-shadow: 0 0 0 1px #f2f2f2;
        transition: box-shadow ease .1s, border-color ease .1s;

        .item-card__body {
            padding: 20px;
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
        }
    }

    &_card-2 {
        box-shadow: 0 0 0 1px #f2f2f2;

        .item-card__body {
            padding: 20px;
        }

        .item-card__date {
            margin-top: 0;
            margin-bottom: 4px;
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
        }

        transition: box-shadow ease .1s, border-color ease .1s;
    }

    &_split {
        display: flex;
        border: 1px solid #f2f2f2;
        padding: 30px;
        transition: box-shadow .3s ease;
        margin-right: -1px;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        .item-card__image-place {
            flex: 0 0 165px;
            max-width: 165px;
            margin-right: 30px;

            @include media-breakpoint-down(sm) {
                flex: auto;
                max-width: 100%;
                width: 165px;
                margin-right: 0;
                margin-left: 0;
            }
        }

        .item-card__body {
            padding-top: 0;
            padding-bottom: 0;
            flex: 1 1 1px;

            @include media-breakpoint-down(sm) {
                flex: auto;
                width: 100%;
                padding-top: 10px;
            }
        }

        &:hover {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
        }
    }

    &_blog {

        .item-card__img {
            width: 100%;
            max-width: 100%;
        }

        .item-card__image-place {
            overflow: hidden;
        }
    }

    &_blog-first {

        .item-card__title {
            font-size: 18.6px;
        }

        .item-card__text {
            font-size: 14px;
            margin-top: 16px;
            margin-bottom: 9px;
        }

        .item-card__image-place {
            max-height: 428px;
            overflow: hidden;
        }
    }

    &_index-first {
        position: relative;
        z-index: 1;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-up(lg) {
            padding-bottom: 70%;

            .item-card__gradient {
                display: block;
            }

            .item-card__image-place {
                display: none;
            }

            .item-card__title {
                font-size: 19px;
                font-weight: 700;

                a {
                    color: #fff !important;
                }
            }

            .item-card__date {
                padding-top: 8px;
                color: #fff;
            }

            .item-card__body {
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }

        @include media-breakpoint-up(xl) {
            padding-bottom: 65%;
        }

        @include media-breakpoint-down(md) {
            background-image: none !important;
        }

    }
}


.item-row {
    position: relative;
    display: flex;
    padding: 14px;
    border: 1px solid #eee;
    transition: box-shadow ease-out .2s, border ease-out .2s;;
    margin-bottom: -1px;
    border-radius: 4px;
    background: #fff;

    &__image-place {
        flex: 0 0 91px;
        max-width: 91px;
        height: 91px;
        line-height: 91px;
        margin: 0 auto;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    &__body {
        flex: 1 1 1px;
        padding: 10px 0 0 15px;
    }

    &__title {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        margin-bottom: 6px;
        max-height: 57px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        -webkit-line-clamp: 50;
        -webkit-box-orient: vertical;

        a {
            color: $dark;
        }
    }

    &__price {
        font-weight: 700;
        margin-top: 8px;
    }

    &__margin {
        margin-bottom: 30px;
    }

    &:hover {
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, .15);
        z-index: 2;

        .item-row__title a {
            color: $primary;
        }
    }
}


.item {
    height: 100%;
    padding: 30px 10px 30px;
    border: 1px solid #f2f2f2;
    text-align: center;
    transition: all ease .2s;
    margin: 0 0 0 -1px;

    &__wrapper {
        margin-bottom: -1px;
    }

    &__image-place {
        width: 120px;
        height: 120px;
        line-height: 120px;
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
    }

    &__title {
        font-weight: 700;
        font-size: 1.071em;
        line-height: 20px;
        word-break: break-word;
        margin-top: 22px;
    }

    &__margin {
        margin-bottom: 30px;
    }

    &_thin {

        .item__title {
            font-weight: 400;
        }
    }

    &:hover {
        border-color: #fff;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    }

    &_brand {
        padding-top: 50px;
        padding-bottom: 50px;

        .item__image-place {
            width: 100%;
            height: 60px;
            line-height: 60px;
        }
    }
}

.tizer-card {
    position: relative;
    padding-bottom: 33.959%;
    background: center / cover no-repeat;
    font-size: 15px;
    overflow: hidden;

    &__container {
        display: flex;
        position: absolute;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__caption {
        color: $dark;
        padding-left: 8.4%;
        margin-bottom: 0.4em;
    }

    &__title {
        font-size: 1.5em;
        line-height: 1.2;
        margin-bottom: 0.2em;
    }

    &__sub {
        font-size: 1.15em;
        line-height: 1.2;
    }

    &_square {
        padding-bottom: 73.5%;

        .tizer-card {

            &__container {
                align-items: flex-start;
            }

            &__caption {
                margin-top: 1.85em;
                padding-right: 6%;
                padding-left: 9.2%;
            }

            &__title {
                font-size: 2.15em;
            }

            &__sub {
                font-size: 1.4em;
            }
        }
    }

    &.light {

        .tizer-card__caption {
            color: #fff;
        }
    }
}

.menu-tabs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -11px 40px;

    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 11px;
        right: 11px;
        height: 1px;
        background: #f2f2f2;
    }

    &__item {
        display: inline-block;
        margin: 0 11px;

        &.active {

            .menu-tabs__link {
                border-bottom-color: $primary;
                color: $primary;
            }
        }
    }

    &__link {
        display: block;
        padding: 10px 0 24px;
        border-bottom: 2px solid transparent;
        color: $dark;
        line-height: 1;

        @at-root a#{&}:hover {
            color: $primary;
        }
    }
}

.brand-block {
    border: 1px solid #f2f2f2;

    &__image-place {
        display: inline-block;
        border: 1px solid #f2f2f2;
        padding: 30px 36px 30px 28px;
        margin: -1px;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }

    &__body {
        padding: 28px 28px 19px 28px;
        color: #666;
        font-size: .933em;
    }

    &__text {
        padding: 0 0 15px 0;
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {
            padding: 0 0 8px 15px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 2px;
                left: 0;
                width: 14px;
                height: 14px;
                background: url(svg-encode(svg-icon-chevron-right(#919191))) center / 100% 100% no-repeat;
            }
        }

        &__link {

        }
    }
}

.reviews {
    margin-bottom: 30px;

    &__wrapper {

    }

    &-note {
        border: 1px solid #ddd;
        padding: 10px;
        margin: 0 0 20px;
    }

    &-item {
        padding: 20px 25px;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 13px;
        background: #f5f5f5;

        &__header {

        }

        &__name {
            display: inline-block;
            font-weight: 700;
            color: $dark;
        }

        &__date {
            display: inline-block;
            margin-left: 11px;
            font-size: 13px;
            line-height: 1.1;
            color: #777;
        }

        &__body {
            margin: 10px 0 14px;
        }

        &__text {

        }

        &__footer {

        }

        &__footer-link {
            display: inline-block;
            font-size: 13px;
            margin-right: 10px;
            color: #999;

            &:hover {
                color: $primary;
            }
        }

        &-images {

            &__item {
                display: inline-block;
                margin-right: 6px;
                margin-top: 6px;
                width: 30px;
                height: 30px;
                background: center / cover no-repeat;
                border: 1px solid #f2f2f2;
                border-radius: 2px;
            }
        }
    }

    &-form {

        &__btn {
            margin-bottom: 15px;
        }

        &__collapse {

        }

        &__footer {
            margin-top: 20px;
        }
    }


}


.catalog {

    &-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 0 0 20px;

        &__left {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &__right {

        }

        @include media-breakpoint-down(xs) {

            .view-types {
                display: none;
            }
        }

    }

    &-no-products {
        position: relative;
        padding: 10px 0 15px 97px;
        min-height: 70px;
        margin-left: 30px;

        @include media-breakpoint-down(sm) {
            margin-left: 0;
            padding-left: 70px;
        }

        &__wrapper {
            padding-top: 30px;
            border-top: 1px solid #f2f2f2;
        }

        &__icon {
            content: "";
            position: absolute;
            width: 54px;
            height: 54px;
            line-height: 52px;
            text-align: center;
            background: $primary;
            color: #fff;
            left: 0;
            top: 8px;
            border-radius: 100%;
        }

        &__icon-img {
            display: inline-block;
            width: 35px;
            height: 35px;
            stroke-width: 3;
        }

        &__title {
            font-size: 19px;
            font-weight: 300;
            line-height: 1.1;
        }

        &__text {
            font-size: 15px;
        }
    }

    &-footer {
        padding-top: 15px;
        padding-bottom: 40px;
    }

    &__show-more {
        display: block;
        width: 100%;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        text-align: center;

        &:before {
            content: "";
            display: inline-block;
            position: relative;
            top: 0.3em;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            vertical-align: baseline;
            background: url(svg-encode(svg-icon-rotate-cw($primary))) center / 100% 100% no-repeat;
            transition: transform .5s ease;
        }

        &:hover {
            border-color: #d2d2d4;
            color: $primary;

            &:before {
                transform: rotateZ(360deg);
            }
        }

        &.loading {

            &:before {
                animation: spinner 0.5s linear infinite;
            }
        }
    }

    &__pagination {
        justify-content: center;
    }

    &-show-count {
        padding: 5px 0;
    }

    &__col {
        margin-bottom: -1px;
    }
}

@keyframes spinner {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}

.sorts {
    padding: 7px 0;

    &-item {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        padding-right: 20px;
        color: #a5a3a3;

        @include media-breakpoint-down(sm) {
            margin-right: 10px;
        }

        @include media-breakpoint-down(xs) {
            margin-right: 5px;
            padding-right: 17px;
        }

        @media (max-width: 400px) {
            margin-right: 0px;
        }

        &__name {
            display: inline-block;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &__icon {
            display: none;
            width: 18px;
            height: 18px;

            @include media-breakpoint-down(sm) {
                display: inline-block;
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 4px;
            margin: auto 0;
            height: 13px;
            width: 13px;

            background: url(svg-encode(svg-icon-chevron-down(#a5a3a3))) center / 100% 100% no-repeat;
        }

        &:hover,
        &.active {
            color: $dark;

            &:after {
                background-image: url(svg-encode(svg-icon-chevron-down($dark)))
            }
        }

        &_asc {

            &:after {
                transform: rotateZ(180deg);
            }
        }
    }
}

.view-types {
    white-space: nowrap;

    &-btn {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        margin: 0 0 0 8px;
        vertical-align: top;
        text-align: center;
        background: #f5f5f5;
        color: $dark;
        border-radius: 2px;

        @include media-breakpoint-down(xs) {
            margin-left: 0;
        }

        @media (max-width: 400px) {
            width: 30px;
            height: 30px;
            line-height: 30px;
        }

        &__icon {
            width: 17px;
            height: 17px;
        }

        &.active {
            background: $primary;
            color: #fff;
        }
    }
}


.good-marker {
    position: relative;
    padding: 22px 0 19px 58px;
    font-size: 13px;
    border-top: 1px solid #f2f2f2;
    min-height: 90px;


    &__icon {
        display: inline-block;
        position: absolute;
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        background-color: #4fad00;
        left: 0;
        top: 26px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        border-radius: 50%;
    }

    &__title {
        font-size: 15px;
        font-weight: 700;
    }

    &__text {
        margin: 5px 0 4px;
        color: #666;
    }

    &_hit {

        .good-marker__icon {
            background-color: #2992d9;
        }
    }

    &_sale {

        .good-marker__icon {
            background-color: #e52929;
        }

    }

    &_recommend {

        .good-marker__icon {
            background-color: #893ca9;
        }
    }
}

.catalog-item {


    &__view-block {
        display: inline-flex;
        flex-wrap: wrap;
        margin: 10px 0 0;
    }

    &__quantity-place {
        width: 40px;
    }

    &__countdown-title,
    &__quantity-title {
        margin: 0 0 5px;
        font-size: 11px;
        line-height: 1.3;
        color: #666;
    }

    &__countdown {
        width: 8em;
        border-radius: 2px 0 0 2px;

        .countdown-item {

            &:nth-child(3) {

                &:before,
                &:after {
                    display: none;
                }
            }
        }
    }

    &-sale {
        font-size: 0;

        &__value {
            display: inline-block;
            background: #e32330;
            color: #fff;
            border-radius: 3px;
            padding: 5px 8px 4px;
            margin: 3px 2px;
            font-size: 12px;
            line-height: 1;
        }

        &__name {
            display: inline-block;
            background: $yellow;
            padding: 5px 8px 4px;
            border-radius: 3px;
            margin: 3px 2px;
            font-size: 12px;
            line-height: 1;
        }
    }

    &-store {
        display: inline-block;
        margin: 0 6px;
        white-space: nowrap;
        font-size: 12px;
        line-height: 1.2;
        color: #666;

        &__icon {
            display: inline-block;
            width: 14px;
            height: 14px;
            vertical-align: middle;
            margin: -2px 3px 0 0;
            background: url(svg-encode(svg-icon-check($success))) center / 100% 100% no-repeat;
        }

        &__text {
            display: inline-block;
        }

        &_little {

            .catalog-item-store__icon {
                background-image: url(svg-encode(svg-icon-trending-down($primary)));
            }
        }

        &_missing {

            .catalog-item-store__icon {
                background-image: url(svg-encode(svg-icon-x($danger)));
            }
        }
    }

    &__char {
        display: inline-block;
        font-size: 12px;
        line-height: 1.2;
        color: #999;
        margin: 0 6px;
    }

    &__price {
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 8px;
        margin-right: 7px;
        margin-left: 7px;
        white-space: nowrap;

        &_discount {
            font-size: 13px;
            line-height: 1;
            font-weight: 400;
            color: #888;
            text-decoration: line-through;
        }
    }


    &-actions {

        &-btn {
            display: inline-flex;
            align-items: center;
            flex-wrap: nowrap;
            margin: 0 0 5px;
            text-align: center;
            transition: opacity .3s ease;
            font-size: 13px;
            opacity: .7;

            &__icon {
                display: inline-block;
                width: 30px;
                height: 30px;
                vertical-align: middle;
                background: #fff center / 16px 16px no-repeat;
                opacity: 1;
                border-radius: 50%;


                &_favorite {
                    background-image: url(svg-encode(svg-icon-heart($dark)));
                }

                &_compare {
                    background-image: url(svg-encode(svg-icon-bar-chart-2($dark)));
                }
            }

            &__name {
                display: inline-block;
                vertical-align: middle;
                padding-left: 10px;
            }

            &:hover {
                cursor: pointer;
                opacity: 1;

                .catalog-item-actions-btn__icon {
                    opacity: 1;
                    background-color: #e3e3e3;
                }
            }

            &.active {
                opacity: 1;

                .catalog-item-actions-btn__icon {
                    background-color: $primary;

                    &_favorite {
                        background-image: url(svg-encode(svg-icon-heart(#fff)));
                    }

                    &_compare {
                        background-image: url(svg-encode(svg-icon-bar-chart-2(#fff)));
                    }
                }

                .catalog-item-actions-btn__name {
                    color: $primary;
                }
            }
        }
    }

    &-props {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }

        &__title {
            color: #888;
            font-size: 12px;
        }

        &-list {
            margin: 4px 0 0;
            padding: 0;
            list-style: none;
            font-size: 0;

            &__item {
                display: inline-block;
                padding: 7px 10px;
                margin: 0 5px 5px 0;
                border: 1px solid #888;
                border-radius: 2px;
                font-size: 11px;
                text-align: center;
                cursor: pointer;

                &.active {
                    color: #FFF;
                    border-color: $primary;
                    background-color: $primary;
                }

                &_color {
                    padding: 0;
                    width: 26px;
                    height: 26px;
                    border: 0;
                    vertical-align: top;
                    box-shadow: 0 0 0 1px #f2f2f2 inset;

                    &.active {
                        box-shadow: 0 0 0 1px #fff inset, 0px 0px 0px 2px $primary;
                    }
                }
            }
        }
    }

    &-chars {

        &__title {
            display: block;
            margin-top: 10px;
            font-size: 13px;
            color: #666;

            @at-root a#{&} {
                &:hover {
                    color: $primary;
                }
            }

            &.collapsed {

                .catalog-item-chars__arrow {
                    transform: rotateZ(180deg);
                }
            }
        }

        &__arrow {
            position: relative;
            top: -2px;
            display: inline-block;
            border: 3px solid transparent;
            border-top-width: 0;
            border-bottom-color: currentColor;
            margin-left: 3px;
            vertical-align: middle;
        }

        &__wrapper {

        }

        &-list {
            padding: 10px 0 0;
            margin: 0;
            font-size: 13px;
            color: #666;

            &-item {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding: 2px 0 2px;
                min-height: 26px;
                word-wrap: break-word;

                &:before {
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: 0;
                    right: 0;
                    height: 1px;
                    border-top: 1px dotted #ccc;
                }

                &__name {
                    position: relative;
                    z-index: 1;
                    display: inline-block;
                    padding-right: 10px;
                    background: #fff;
                }

                &__value {
                    position: relative;
                    z-index: 1;
                    display: inline;
                    padding-left: 10px;
                    background: #fff;
                    text-align: right;
                }
            }

            &_column {

                .catalog-item-chars-list-item {
                    display: block;
                    margin-bottom: 9px;

                    &:before {
                        display: none;
                    }

                    &__name {
                        display: block;
                        padding-right: 0;
                        text-align: left;
                        color: #888;
                    }

                    &__value {
                        display: block;
                        padding-left: 0;
                        text-align: left;
                        color: #333;
                    }
                }

            }
        }

    }

    &__buy-total {
        margin-top: 9px;
        font-size: 13px;
        color: #666;
    }

    &__description {
        font-size: 13px;
        color: $muted;
    }

    &-missing {

        &__btn {
            opacity: 0.8;

            &:hover {
                opacity: 1;
                background-color: $primary;
                border-color: $primary;
                color: #fff;
            }
        }

        &__text {
            color: #b3b3b3;
            font-size: 11px;
            line-height: 1.2;
            margin: 16px 0 0;
        }
    }

    &__fast-view {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 5;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background-color: rgba(229, 229, 229, .9);
        text-decoration: none;
        border-radius: 2px;
        cursor: pointer;
        color: #666;
        font-size: 13px;
        white-space: nowrap;
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, -50%);
        transition: all .2s ease-in-out;

        padding: 0 14px 0 36px;

        &:before {
            content: "";
            opacity: .5;
            position: absolute;
            left: 10px;
            top: 50%;
            margin-top: -10px;
            width: 20px;
            height: 20px;

            background: url(svg-encode(svg-icon-zoom-in($dark))) center / 100% 100% no-repeat;
        }

        &:hover {
            color: #666;
        }


    }

    &-table-sizes {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        font-size: 13px;
        line-height: 1.2;
        color: $primary;

        &:before {
            content: "";
            position: absolute;
            top: -1px;
            left: 0;
            width: 19px;
            height: 19px;
            background: url(svg-encode(svg-icon-help-circle(#666))) center / 100% 100% no-repeat;
        }

        &:hover {
            color: $dark;
        }

        &__title {
            display: inline-block;
            border-bottom: 1px dotted currentColor;
        }
    }

    &-cheaper {
        display: inline-block;
        position: relative;
        padding-left: 24px;
        font-size: 13px;
        line-height: 1.2;
        color: #666;

        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0;
            width: 17px;
            height: 18px;
            background: url(svg-encode(svg-icon-credit-card(#666))) center / 100% 100% no-repeat;
        }

        &:hover {
            color: $dark;
        }

        &__title {
            display: inline-block;
            border-bottom: 1px dotted currentColor;
        }
    }

    &-favorite-to-cart {
        position: relative;
        margin-bottom: 10px;
        padding-left: 40px;

        &__icon {
            position: absolute;
            top: -4px;
            left: 0;
        }

    }
}


.catalog-item-quantity-item {
    display: inline-block;
    position: relative;
    height: 2.8666em;
    width: 2.666em;
    padding: 0.4666em 0 0;
    margin: 0;
    vertical-align: top;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    background: $primary;
    border-radius: 0;
    line-height: 1;
    color: #fff;

    &__value {

    }

    &__text {
        opacity: .6;
        font-weight: 400;
        font-size: 0.8em;
        line-height: 1.2;
        display: block;
        width: auto;
        text-transform: lowercase;
    }
}


.counter {
    display: inline-flex;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 0;
    background: #f3f3f5;

    &__btn {
        display: inline-block;
        line-height: 36px;
        height: 37px;
        width: 24px;
        cursor: pointer;
        border: 0;
        font-size: 0;
        background: transparent;

        &_minus {
            background: url(svg-encode(svg-icon-minus(#333))) center / 13px 13px no-repeat;
        }

        &_plus {
            background: url(svg-encode(svg-icon-plus(#333))) center / 15px 15px no-repeat;
        }

        &:hover {
            opacity: 0.8;
        }

        &:active {
            box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
        }
    }

    &__input {
        display: inline-block;
        width: 34px;
        border: 0;
        border-left: 1px solid #f3f3f5;
        border-right: 1px solid #f3f3f5;
        background: #f3f3f5;
        color: #666;
        font-size: 13px;
        height: 37px;
        border-radius: 0;
        text-align: center;
        line-height: 13px;
        -moz-appearance: textfield; /* Firefox */

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &:focus {
            outline: none;
        }
    }

    &-lg {

        .counter__input {
            height: 40px;
            width: 65px;
            font-size: 14px;
        }

        .counter__btn {
            width: 28px;
            height: 40px;

            &_plus {
                background-size: 17px 17px;
                background-position: center left 2px;
            }

            &_minus {
                background-size: 17px 17px;
                background-position: center right 2px;
            }
        }

    }
}

.stickers {

    &__item {
        display: inline-block;
        position: relative;
        width: auto;
        margin: 0 3px 6px;
        padding: 4px 10px 5px;
        border-radius: 2px;
        font-size: 10px;
        line-height: 12px;
        background-color: #4fad00;
        letter-spacing: .5px;
        color: #fff;
        text-transform: uppercase;

        &_hit {
            background-color: #2992d9;
        }

        &_sale {
            background-color: #e52929;
        }

        &_recommend {
            background-color: #893ca9;
        }

        &_archive {
            background-color: #ccc;
        }

        &_event {
            background-color: #fa9308;
        }

        &_bonus {
            background-color: #893ca9;
        }

        &_action {
            background-color: #004085;
        }
    }
}

.markers {

    &__item {
        display: inline-block;
        position: relative;
        width: 40px;
        height: 40px;
        margin: 0 3px 6px;
        padding: 0;
        font-size: 13px;
        font-weight: 700;
        line-height: 40px;
        background-color: #4fad00;
        letter-spacing: .5px;
        color: #fff;
        text-transform: uppercase;
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        transition: all 250ms ease;

        &_hit {
            background-color: #2992d9;
        }

        &_sale {
            background-color: #e52929;
        }

        &_recommend {
            background-color: #893ca9;
        }
    }
}


.rating {
    display: inline-flex;
    flex-wrap: wrap;
    text-align: center;
    font-size: 0;

    &__star {
        display: inline-block;
        width: 15px;
        height: 14px;
        overflow: hidden;
        margin: 0 1px;
        background: url(svg-encode(svg-icon-star(#cccccc))) center / 100% 100% no-repeat;

        &.active {
            background-image: url(svg-encode(svg-icon-star($yellow)));
        }
    }

    &-lg {

        .rating__star {
            width: 17px;
            height: 17px;
        }
    }
}

.stores-list {
    padding: 10px 0 0;
    margin: 0;
    font-size: 15px;
    color: #666;

    &-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 2px 0 2px;
        margin: 5px 0;
        min-height: 26px;
        word-wrap: break-word;

        @include media-breakpoint-down(xs) {
            display: block;
        }

        &__dots {
            display: block;
            position: absolute;
            top: 12px;
            left: 0;
            right: 0;
            height: 1px;
            border-top: 1px dotted #ccc;

            @include media-breakpoint-down(xs) {
                position: static;
                margin: 5px 0;
            }
        }

        &__name {
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-right: 10px;
            background: #fff;

            @include media-breakpoint-down(md) {
                font-size: 13px;
            }

            @include media-breakpoint-down(xs) {
                display: block;
                font-size: 12px;
            }
        }

        &__value {
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-left: 10px;
            background: #fff;
            text-align: right;
            white-space: nowrap;

            @include media-breakpoint-down(xs) {
                display: block;
                text-align: left;
                font-size: 13px;
                padding-left: 0;
            }
        }

        &__value-icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            vertical-align: middle;
            margin: 0;
            background: center / 100% 100% no-repeat;
            margin-right: 3px;

            @include media-breakpoint-down(xs) {
                width: 15px;
                height: 15px;
            }

            &_check {
                background-image: url(svg-encode(svg-icon-check($success)));
            }

            &_little {
                background-image: url(svg-encode(svg-icon-trending-down($primary)));
            }

            &_missing {
                background-image: url(svg-encode(svg-icon-x($danger)));
            }
        }
    }

}

.dots-list {
    border: 1px solid #e5e5e5;
    padding: 20px 38px 20px;
    color: #666;

    &-item {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin: 5px 0;
        min-height: 20px;
        word-wrap: break-word;

        @include media-breakpoint-down(xs) {
            display: block;
        }

        &__dots {
            display: block;
            position: absolute;
            top: 11px;
            left: 0;
            right: 0;
            height: 1px;
            border-top: 1px dotted #ccc;

            @include media-breakpoint-down(xs) {
                position: static;
                margin: 5px 0;
            }
        }

        &__name {
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-right: 28px;
            padding-left: 10px;
            background: #fff;

            &:before {
                content: "";
                display: block;
                position: absolute;
                background: $primary;
                left: 1px;
                top: 10px;
                width: 2px;
                height: 2px;
            }

            @include media-breakpoint-down(md) {
                font-size: 13px;
            }

            @include media-breakpoint-down(xs) {
                display: block;
            }
        }

        &__value {
            position: relative;
            z-index: 1;
            display: inline-block;
            padding-left: 29px;
            background: #fff;
            text-align: right;
            white-space: nowrap;
            font-weight: 700;

            @include media-breakpoint-down(xs) {
                display: block;
                text-align: left;
                font-size: 13px;
                padding-left: 10px;
            }
        }
    }
}


.catalog-card {
    position: relative;
    z-index: 0;
    min-height: 100%;
    margin: 0 -1px 0 0;
    padding: 20px 19px 26px 20px;
    text-align: center;
    box-shadow: 0 0 0 1px #f2f2f2 inset;
    background: #fff;

    &__wrapper {
        height: 100%;

        @include media-breakpoint-down(xs) {
            height: auto !important;
        }
    }

    &__view-place {
        position: relative;
        margin-bottom: 10px;
        margin-top: 6px;
    }

    &__image-place {
        display: block;
        min-width: 70px;
        height: 170px;
        line-height: 170px;
        margin: 0 auto;
        padding: 0;
        vertical-align: middle;
        text-align: center;
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
    }

    &__stickers {
        position: absolute;
        z-index: 5;
        top: 4px;
        z-index: 3;
        text-align: left;
        padding-right: 35px;
        font-size: 0;
    }

    &__markers {
        position: absolute;
        z-index: 5;
        bottom: -4px;
        right: -2px;
        text-align: right;
    }

    &__catalog-item-actions {
        position: absolute;
        z-index: 5;
        right: 0;
        top: 0;

        .catalog-item-actions-btn {
            display: block;
            opacity: 0;
            visibility: hidden;

            &__name {
                display: none;
            }

            &.active {
                opacity: .7;
                visibility: visible;
            }
        }

    }

    &__info {
        padding-bottom: 10px;
    }


    &__title {
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
    }

    &__rating {
        margin: 0 0 9px;
    }

    &__meta {

    }

    &__prices {
        margin-top: 11px;
    }

    &__more-content {
        display: none;
    }

    &__buy {
        margin: 0 3px 0 3px;
    }

    &__counter {
        margin: 11px 5px 0 5px;
    }

    &__buy-btn {
        margin: 11px 5px 0 5px;
        padding-left: 7px;
        padding-right: 7px;
    }

    .catalog-item__fast-view {
        padding-left: 14px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        &:before {
            display: none;
        }
    }

    @media (max-width: 1300px) and (min-width: 992px) {
        .catalog-item__view-block {
            margin-left: -15px;
            margin-right: -15px;
        }

        .catalog-item__countdown {
            font-size: 13px;
        }

        .catalog-item-quantity-item {
            font-size: 13px;
        }

        .catalog-item__countdown-title {
            font-size: 10px;
        }

        .catalog-item__quantity-title {
            font-size: 10px;
        }

        .catalog-item__quantity-place {
            width: 35px;
        }
    }

    &:hover {
        z-index: 2;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);

        .catalog-item__fast-view {
            visibility: visible;
            opacity: 1;
        }

        .catalog-item-actions-btn {
            opacity: 0.7;
            visibility: visible;

            &:hover {
                opacity: 1;
            }

            &.active {
                opacity: 1;

                .catalog-item-actions-btn__icon {
                    opacity: 1;
                }
            }
        }
    }

    &_show-more {

        .catalog-card__more-content {
            display: block;
        }
    }

    &_not-available {

        .catalog-card__img {
            opacity: 0.2;
        }
    }
}

.mobile {

    .catalog-card__more-content {
        display: block;
    }

    .catalog-card__catalog-item-actions .catalog-item-actions-btn {
        opacity: .7;
        visibility: visible;
    }
}


.catalog-row {
    display: flex;
    position: relative;
    z-index: 0;
    box-shadow: 0 0 0 1px #f2f2f2 inset;
    background: #fff;
    margin-bottom: -1px;

    @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;
    }

    &__view {
        flex: 0 0 240px;
        max-width: 240px;
        padding: 19px;

        @include media-breakpoint-down(sm) {
            flex: auto;
            max-width: 100%;
        }
    }

    &__view-place {
        text-align: center;
        position: relative;

        @include media-breakpoint-down(sm) {
            width: 200px;
            margin: 0 auto;
        }

        @media (max-width: 400px) {
            width: auto;
        }
    }

    &__image-place {
        display: block;
        max-width: 100%;
        height: 200px;
        line-height: 200px;
        margin: 0 auto;
        padding: 0;
        vertical-align: middle;
        text-align: center;
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
    }

    &__stickers {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        text-align: left;
        padding-right: 50px;
        font-size: 0;
    }

    &__info {
        padding: 19px 20px 19px 12px;
        flex: 1 1 1px;

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &__info-bottom {
        margin: 23px 0 0;
        border-top: 1px solid #f2f2f2;
        padding: 14px 0 0;
    }

    &__title {
        font-size: 16px;
        font-weight: 700;
        overflow: hidden;
    }

    &__data {
        margin-top: 8px;
    }

    &__rating {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
    }

    &__catalog-item-actions {
        .catalog-item-actions-btn {
            margin-right: 30px;
        }
    }


    &__prices {
        .catalog-item__price {
            margin-left: 0;
        }
    }

    &__more-content {
        flex: 0 0 240px;
        max-width: 240px;
        padding: 25px 29px 27px 6px;

        @include media-breakpoint-down(sm) {
            flex: auto;
            padding-left: 0;
            padding-right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__buy {
        margin-top: 15px;
    }

    &__counter {
        margin-right: 10px;
    }

    .catalog-item-sale {
        margin-bottom: 8px;
        margin-left: -3px;
        margin-right: -3px;
    }

    .catalog-item-props__area {
        margin-top: 10px;
    }

    .catalog-item__description {
        margin-top: 10px;
    }

    .catalog-item__fast-view {
        position: relative;
        transform: none;
        top: 0;
        left: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &:hover {
        z-index: 1;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);

        .catalog-item__fast-view {
            opacity: 1;
            visibility: visible;
        }

    }

    &_not-available {

        .catalog-row__img {
            opacity: 0.2;
        }
    }
}


.catalog-line {
    position: relative;
    z-index: 0;
    display: flex;
    align-items: center;
    box-shadow: 0 0 0 1px #f2f2f2 inset;
    background: #fff;

    @include media-breakpoint-down(xs) {
        display: block;
        text-align: center;
    }

    &__view {
        flex: 0 0 70px;
        max-width: 70px;
        padding: 19px;
        padding-right: 0;
        position: relative;

        @include media-breakpoint-down(xs) {
            max-width: 100%;
            padding: 10px 10px 0;
        }
    }

    &__image-place {
        display: block;
        max-width: 100%;
        height: 50px;
        line-height: 50px;
        margin: 0 auto;
        padding: 0;
        vertical-align: middle;
        text-align: center;

        @include media-breakpoint-down(xs) {
            width: 50px;
        }
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
    }

    &__info {
        padding: 19px 10px 19px 20px;
        flex: 1 1 1px;

        @include media-breakpoint-down(xs) {
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }

    &__title {
        font-size: 14px;
        font-weight: 700;
        overflow: hidden;
        word-break: break-word;

        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    &__data {
        margin-top: 3px;

        @include media-breakpoint-down(xs) {
            display: none;
        }
    }

    &__rating {
        display: inline-block;
        vertical-align: middle;
        font-size: 0;
    }

    &__catalog-item-actions {
        .catalog-item-actions-btn {
            margin-right: 5px;

            &__name {
                display: none;
            }
        }
    }

    &__price-info {
        flex: 0 0 25%;
        max-width: 25%;
        padding: 19px 0;

        @include media-breakpoint-down(sm) {
            flex-basis: 130px;
            max-width: 130px;
            padding-left: 3px;
            padding-right: 3px;
        }

        @include media-breakpoint-down(xs) {
            max-width: 100%;
            padding-top: 3px;
            padding-bottom: 3px;
        }
    }


    &__prices {
        .catalog-item__price {
            margin-left: 0;
            font-size: 15px;
            margin-bottom: 5px;

            &_discount {
                font-size: 13px;
            }
        }
    }

    &__buttons-cell {
        flex: 0 0 215px;
        max-width: 215px;
        padding: 19px 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__buttons-adaptive {
        display: none;
        margin: 10px 0 10px;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__actions-cell {
        flex: 0 0 92px;
        max-width: 92px;
        position: relative;
        padding: 19px 5px 19px 5px;
        text-align: center;

        @include media-breakpoint-down(md) {
            flex-basis: 40px;
            max-width: 40px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__buy {
        position: relative;
    }

    &__counter {
        margin-right: 10px;
        margin-bottom: 7px;
    }

    &__buy-btn {
        margin-bottom: 7px;
    }

    .catalog-item-sale {
        margin-bottom: 8px;
        margin-left: -3px;
        margin-right: -3px;
    }

    .catalog-item__buy-total {
        margin: -4px 0 0;
        position: absolute;
        font-size: 12px;
    }

    .catalog-item__fast-view {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        font-size: 0;
        padding: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &:hover {
        z-index: 1;
        box-shadow: 0 0 20px rgba(0, 0, 0, .1);

        .catalog-item__fast-view {
            visibility: visible;
            opacity: 1;
        }
    }

    &_not-available {

        .catalog-line__img {
            opacity: 0.2;
        }
    }
}


.catalog-modal {
    display: flex;
    position: relative;
    z-index: 0;
    background: #fff;
    min-height: 510px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 269px;
        height: 100%;
        width: 1px;
        background: #f2f2f2;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    @include media-breakpoint-down(md) {
        display: block;
        text-align: center;
    }

    &__view {
        flex: 0 0 310px;
        max-width: 310px;
        padding: 30px;

        @include media-breakpoint-down(md) {
            flex: auto;
            max-width: 100%;
            padding-bottom: 0;
        }
    }

    &__view-place {
        text-align: center;
        position: relative;
    }

    &__image-place {
        display: block;
        max-width: 100%;
        height: 340px;
        line-height: 337px;
        margin: 0 auto;
        padding: 0;
        vertical-align: middle;
        text-align: center;

        @include media-breakpoint-down(md) {
            height: 220px;
            line-height: 220px;
        }
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
    }

    &__stickers {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        text-align: left;
        padding-right: 50px;
        font-size: 0;
    }

    &__info {
        flex: 1 1 1px;
        padding: 27px 30px 0 30px;
        max-height: 480px;
        overflow-y: auto;
        overflow-x: hidden;

        @include scrollbar-cute;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }

    &__title {
        font-size: 18px;
        line-height: 1.2;
        overflow: hidden;
    }

    &__data {
        margin-bottom: 20px;
    }

    &__rating {
        display: block;
        vertical-align: middle;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-left: -1px;
        font-size: 0;
    }

    &__catalog-item-actions {
        padding-top: 14px;

        .catalog-item-actions-btn {
            display: block;
            text-align: left;


            &:not(.active) {
                opacity: 0.7;

                &:hover {
                    opacity: 1;
                }
            }

            &.active {
                .catalog-item-actions-btn__name {
                    color: #333;
                }
            }
        }
    }

    &__price-info {
        margin-bottom: 15px;
    }

    &__prices {
        .catalog-item__price {
            margin-left: 0;

            &_discount {
                font-size: 16px;
            }
        }
    }

    &__more-content {
        flex: 0 0 270px;
        max-width: 270px;
        padding: 26px 30px 0 27px;
        max-height: 507px;
        overflow-y: auto;
        overflow-x: hidden;

        @include scrollbar-cute;

        @include media-breakpoint-down(md) {
            flex: auto;
            padding-left: 0;
            padding-right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__buy {

    }

    &__buy-justify {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__counter {
        margin-top: 15px;
        margin-bottom: 12px;
    }

    &__buy-btn {
        margin-top: 15px;
        margin-bottom: 12px;
    }

    &__meta {
        margin: 0 -6px;
    }

    .catalog-item-store,
    .catalog-item__char {
        margin: 0;
        font-size: 13px;
        padding: 3px 6px 6px;
    }

    .catalog-item-props__title {
        font-size: 13px;
    }

    .catalog-item-chars-list {
        padding-top: 3px;
    }

    .catalog-item-props-list__item {
        margin: 0 8px 8px 0;
    }

    .catalog-item-sale__value {
        margin-left: 0;
        margin-right: 3px;
    }

    .catalog-item-sale__name {
        margin-left: 0;
    }

    .countdown-item {
        width: 3.066em;
    }

    .catalog-item__countdown {
        width: 3.066 * 3em;
    }

    .catalog-item__buy-total {
        margin-top: 15px;
        margin-left: -3px;
    }
}


.catalog-filters {
    position: relative;
    margin-bottom: 27px;
    border: 1px solid #f2f2f2;
    background: #f9f9f9;
    border-radius: 2px;

    &__header {
        padding: 13px 19px 14px;
        border-bottom: 1px solid #f2f2f2;
    }

    &__title {
        font-weight: 700;
        font-size: 14px;
    }

    &__footer {
        text-align: center;
        padding: 19px 14px 19px 14px;
    }

    &__btn {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 5px;

        &_reset {
            margin-left: 5px;
        }

        @include media-breakpoint-down(md) {

        }
    }

    &-group {
        padding: 13px 19px 14px;
        border-bottom: 1px solid #f2f2f2;

        &__title {
            position: relative;
            display: block;
            padding: 0 35px 0 0;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
            color: #333;
            white-space: nowrap;

            &:hover {
                color: $primary;

                .catalog-filters-group__arrow {
                    background-image: url(svg-encode(svg-icon-chevron-down($primary)));
                }
            }

            &.collapsed {

                .catalog-filters-group__arrow {
                    transform: rotateZ(0deg);
                }
            }
        }

        &__name {
            display: inline-block;
            white-space: normal;
            word-break: break-word;
        }

        &__arrow {
            position: absolute;
            top: 3px;
            right: -3px;
            width: 13px;
            height: 13px;
            background: url(svg-encode(svg-icon-chevron-down(#333))) center / 100% 100% no-repeat;
            transform: rotateZ(180deg);
        }

        &__collapse {

        }

        &__content {
            padding-top: 15px;
            max-height: 250px;
            overflow-y: auto;

            @include scrollbar-cute
        }

        &:hover {
            background: #fff;
        }
    }

    label {
        color: #333;
    }
}

.catalog-filters-tooltip {
    position: absolute;
    z-index: $zindex-dropdown;
    left: 100%;
    margin-left: -20px;
    margin-top: -20px;
    padding: 5px 10px 5px 10px;
    white-space: nowrap;
    font-size: 13px;
    background: #fff;
    border: 1px solid $primary;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

    transition: top 300ms ease-in-out;

    @include media-breakpoint-down(sm) {
        display: none !important;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 100%;
        margin-top: -7px;
        display: block;
        border: 7px solid transparent;
        border-right-color: $primary;
    }

    &__btn {
        margin-left: 10px;
    }

    &_2 {
        background: $primary;
        box-shadow: none;
        color: #fff;
        margin-left: 1px;

        &:before {
            display: none;
            border: 0;
            margin-left: 0;
        }
    }
}


.filter-adaptive {

    &-place {
        display: none;
        font-size: 0;
        margin-right: 15px;

        @include media-breakpoint-down(md) {
            display: block;
        }

        @include media-breakpoint-down(xs) {
            margin-right: 10px;
        }

        &__drop {
            display: none;
            position: absolute;
            z-index: $zindex-dropdown;
            top: 100%;
            left: 0;
            width: 100%;
            max-width: 500px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .15);

            &.active {
                display: block;
            }

            .catalog-filters {
                margin-bottom: 0;
            }
        }

    }

    &-btn {
        display: inline-block;
        position: relative;
        min-height: 35px;
        padding: 8px 14px 8px 35px;
        margin: 0;
        border-radius: 2px;
        background: #e5e5e5;
        border: 0;
        font-size: 13px;
        color: #383838;
        cursor: pointer;


        @include media-breakpoint-down(xs) {
            padding: 0;
            width: 35px;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 8px;
            left: 10px;
            width: 16px;
            height: 16px;
            background: url(svg-encode(svg-icon-filter(#333))) center / 100% 100% no-repeat;
        }


        &__text {

            @include media-breakpoint-down(xs) {
                display: none;
            }
        }

        &.active {
            background: $primary;
            color: #fff;

            &:before {
                background-image: url(svg-encode(svg-icon-filter(#fff)));
            }
        }
    }
}


.good-page {

    &__main {
        position: relative;
        margin: 0 0 30px;
        padding: 0;
        border: 1px solid #f2f2f2;

        .catalog-item__price {
            display: block;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 12px;
            font-size: 26px;
            line-height: 1;

            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }

            &_discount {
                margin-bottom: 10px;
                font-size: 16px;
            }
        }

        .catalog-item-sale__value {
            margin-left: 0;
        }

        .countdown-item {
            width: 3.066em;
        }

        .catalog-item__countdown {
            width: 3.066 * 3em;
        }

        .catalog-item-store {
            margin-left: 0;
            font-size: 13px;
            margin-right: 30px;

            &__text {
                border-bottom: 1px dotted;
            }

        }

        .catalog-item__view-block {
            margin-bottom: 12px;
        }

        .catalog-item-props {

            &__title {
                font-size: 13px;
            }

            &-list__item {
                font-size: 13px;
                line-height: 1.1;
                margin: 0 8px 8px 0;
            }
        }

        .catalog-item-table-sizes {
            margin-bottom: 27px;
        }

        .catalog-item-props {
            margin-bottom: 15px;
        }

        .catalog-item__buy-total {
            margin-top: 0;
        }

        .catalog-item-chars__title {
            font-size: 14px;
            font-weight: 500;
        }
    }

    &__tab-content {
        padding-top: 40px;
    }

    &__grid {
        display: flex;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    &__view {
        flex: 0 0 50%;
        max-width: 50%;

        padding: 37px 25px 29px 37px;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            padding: 20px 25px 15px 25px;
            border-bottom: 1px solid #f2f2f2;
        }

    }

    &-content {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 10px;
        padding-right: 30px;

        @include media-breakpoint-down(sm) {
            max-width: 100%;
            padding-right: 10px;
        }

        &__top {
            padding: 30px 0 20px;
            border-bottom: 1px solid #f2f2f2;

            @include media-breakpoint-down(md) {
                padding-top: 20px;
                padding-bottom: 10px;
            }
        }

        &__middle {
            padding: 35px 0 15px;

            @include media-breakpoint-down(md) {
                padding-top: 25px;
            }
        }

        &__bottom {
            border-top: 1px solid #f2f2f2;
            padding: 30px 0 39px;
        }
    }

    &__view-place {
        position: relative;
    }

    &__stickers {
        position: absolute;
        top: 4px;
        z-index: 3;
        text-align: left;
        padding-right: 50px;
        font-size: 0;

        .stickers__item {
            font-size: 11px;
        }
    }

    &__catalog-item-actions {
        position: absolute;
        z-index: 5;
        right: 0;
        top: 0;

        .catalog-item-actions-btn {
            display: block;

            &__name {
                display: none;
            }

            &.active {
                opacity: 1;
                visibility: visible;

                &:hover {
                    opacity: 1;
                }
            }
        }

    }

    &__buy {
        margin-bottom: 15px;
    }

    &__disclaimer {
        color: #7e7e7e;
        font-size: 12px;
    }

    &-slider {
        padding: 44px 0 0 101px;

        @include media-breakpoint-down(md) {
            padding-left: 70px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-top: 5px;
        }

        &-main {
            text-align: center;

            &__wrapper {
                max-width: 500px;
                height: 500px;
                margin-bottom: 22px;
                overflow: hidden;

                @include media-breakpoint-down(sm) {
                    height: 270px;
                    max-width: 100%;
                    margin-bottom: 0;
                    padding-bottom: 30px;
                }
            }

            &-item {
                position: relative;
                height: 500px;
                line-height: 497px;

                @include media-breakpoint-down(sm) {
                    height: 240px;
                    line-height: 240px;
                }
            }

            &-item__img {
                display: inline-block !important;
                width: auto !important;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }

            &__fancy {
                display: block;
                height: 100%;
                line-height: inherit;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 50px;
                    width: 50px;
                    margin-left: -25px;
                    margin-top: -25px;
                    border-radius: 50%;
                    background: $primary url(svg-encode(svg-icon-zoom-in(#fff))) left 10px top 10px / 27px 27px no-repeat;

                    opacity: 0;
                    transition: opacity 150ms linear;
                }

                &:hover {

                    &:before {
                        opacity: 1;
                    }
                }
            }

            .slick {

                &-dots {
                    position: absolute;
                    z-index: 2;
                    bottom: -35px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    padding: 0 15px;
                    margin: 0;

                    li {
                        position: relative;
                        display: inline-block;
                        height: 8px;
                        width: 8px;
                        margin: 4px;
                        border-radius: 50%;

                        background: #b9b9b9;

                        &:before {
                            content: "";
                            position: absolute;
                            top: -4px;
                            left: -4px;
                            right: -4px;
                            bottom: -4px;
                        }

                        button {
                            display: none;
                        }

                        &.slick-active {
                            background-color: $primary;
                        }
                    }
                }

                &-arrow {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    margin-top: -21px;
                    font-size: 0;
                    line-height: 42px;
                    width: 42px;
                    height: 42px;
                    border-radius: 50%;
                    border: 0;
                    background: transparent url(svg-encode(svg-icon-chevron-right(#333))) left 7px center / 30px 30px no-repeat;

                    &:hover {
                        background-color: $primary;

                        &:not(.slick-disabled) {
                            background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
                        }

                        &.slick-disabled {
                            background-color: #eee;
                        }
                    }

                    button {
                        display: none;
                    }

                }

                &-prev {
                    left: 0;
                    transform: scaleX(-1);
                }

                &-next {
                    right: 0;
                }
            }
        }


        &__more {
            display: none;
            position: absolute;
            top: 80px;
            left: 3px;
            width: 54px;
            height: 400px;

            @include media-breakpoint-up(md) {
                display: block;
            }
        }

        &__thumbs-wrapper {
            max-height: 310px;
            overflow: hidden;
        }

        &-thumbs {
            padding: 0;
            margin: 0;
            list-style: none;

            &__item {
                position: relative;
                display: block;
                width: 54px !important;
                height: 54px !important;
                line-height: 50px;
                padding-left: 0;
                margin: 0 0 10px;
                overflow: hidden;
                vertical-align: bottom;
                list-style: none;
                text-align: center;
                border: 2px solid #fff !important;
                transition: border-color ease-out .2s;
                cursor: pointer;

                &.active {
                    border-color: $primary !important;
                }
            }

            &__img {
                display: inline-block !important;
                width: auto !important;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }

            .slick {

                &-arrow {
                    display: block;
                    z-index: 2;
                    top: 50%;
                    font-size: 0;
                    line-height: 20px;
                    width: 100%;
                    height: 20px;
                    border: 0;
                    background: #fff url(svg-encode(svg-icon-chevron-down(#333))) center / 15px 15px no-repeat;

                    &:hover {
                        background-color: $primary;

                        &:not(.slick-disabled) {
                            background-image: url(svg-encode(svg-icon-chevron-down(#fff)));
                        }

                        &.slick-disabled {
                            background-color: #eee;
                        }
                    }

                    button {
                        display: none;
                    }
                }

                &-prev {
                    left: 0;
                    transform: scaleY(-1);
                }

                &-next {
                    right: 0;
                }
            }
        }

        &-videos {

            &__link {
                position: relative;
                display: block;
                padding: 5px 0 0;
                margin-top: 10px;
                font-size: 12px;
                font-weight: 700;
                text-transform: uppercase;
                color: #222;
                text-align: center;

                &:before {
                    content: "";
                    position: relative;
                    display: inline-block;
                    background: url(svg-encode(svg-icon-play(#222))) center / 100% 100% no-repeat;
                    width: 20px;
                    height: 20px;
                    margin-bottom: 3px;
                    margin: auto;
                }
            }
        }
    }

    &__brand-col {
        text-align: right;

        @include media-breakpoint-down(md) {
            text-align: left;
        }

        @include media-breakpoint-down(sm) {
            text-align: center;
        }
    }

    &__catalog-item__char {
        font-size: 13px;
    }

    &__price-info {
        margin-bottom: 5px;
    }

    &__meta {
        margin-bottom: 15px;
    }

    &__counter {
        margin-bottom: 12px;
        margin-right: 12px;
    }

    &__buy-btn {
        font-size: 14px;
        margin-bottom: 12px;
        padding: .608rem 1.8rem;
    }

    &__link-to-chars {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        line-height: 1.2;
        color: $primary;
        border-bottom: 1px dotted;

        &:hover {
            color: #333;

            &:before {
                background-image: url(svg-encode(svg-icon-chevron-down(#333)));
            }
        }

        &:before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            right: -20px;
            top: 0.2em;
            background: url(svg-encode(svg-icon-chevron-down($primary))) center / 100% 100% no-repeat;
        }
    }

    &__tizer {
        border-top: 1px solid #f2f2f2;
        border-bottom: 0;
        padding: 20px 0 0;
    }

    &__iconblock {
        padding-left: 20px;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            padding-left: 10px;
        }

        .iconblock__text {

            @include media-breakpoint-down(md) {
                padding-left: 10px;
                font-size: 11px;
            }
        }
    }
}

.good-page-section {
    margin-top: 15px;

    &__header {
        padding: 25px 0;

        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }

    &__title {
        font-size: 19px;
        color: $dark;
    }

    &__body {
        padding-bottom: 25px;
    }

    &__catalog-item-chars {


        .catalog-item-chars-list-item__value {
            flex: 0 0 50%;
            max-width: 50%;
            text-align: left;
        }
    }

    &_with-slider {

        .good-page-section__header {
            padding-right: 65px;

            @include media-breakpoint-down(xs) {
                text-align: left;
            }
        }
    }
}


.catalog-cards-slider {
    position: relative;
    z-index: 1;
    margin-top: -25px;
    display: none;

    &.slick-initialized {
        display: block;
    }

    .catalog-card {
        margin-top: 0;
    }

    .catalog-card__img {
        display: inline-block !important;
    }

    .slick-track {
        display: flex;
        margin-left: 0;
        padding-top: 25px;
        padding-bottom: 25px;
        transform: none;
    }

    .slick-slide {
        position: relative;
        height: auto;

        &.slick-active:hover {
            z-index: 3;
        }
    }

    .slick-list {
        margin: 0 -15px;
        padding: 0 15px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 14px;
            z-index: 2;
            background: #fff;
        }

        &:before {
            left: -1px;
        }

        &:after {
            right: -2px;
        }
    }
}

.slick_outer-arrows {

    .slick-list {
        padding-top: 10px;
    }

    .slick-arrow {
        position: absolute;
        top: -30px;
        width: 33px;
        height: 35px;
        border: 1px solid #e5e5e5;
        font-size: 0;
        padding: 0;
        opacity: 0.8;
        background: transparent url(svg-encode(svg-icon-chevron-right(#333))) center / 20px 20px no-repeat;

        transition: opacity 100ms ease;

        &:hover {
            opacity: 1;
            background-color: #f5f5f5;
        }

        &:active {
            background-color: #eee;
        }

        &.slick-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .slick-prev {
        right: 32px;
        transform: scaleX(-1);
        border-radius: 2px 0 0 2px;
    }

    .slick-next {
        right: 0;
        border-radius: 0 2px 2px 0;
    }
}

.slick_side-arrows {
    padding-left: 30px;
    padding-right: 30px;

    .slick-arrow {
        position: absolute;
        z-index: 3;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 33px;
        border: 0;
        font-size: 0;
        padding: 0;
        opacity: 0.8;
        background: transparent url(svg-encode(svg-icon-chevron-right(#333))) center / 20px 20px no-repeat;
        transition: opacity 100ms ease;

        &:hover {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.02);
        }

        &:active {
            background-color: #eee;
        }

        &.slick-disabled {
            opacity: 0;
            pointer-events: none;
        }
    }

    .slick-prev {
        left: 0;
        transform: scaleX(-1);
    }

    .slick-next {
        right: 0;
    }
}


.cart-page {

    &__gifts {
        margin: 30px 0;
    }
}

.cart-page-empty {

    &__icon {
        display: block;
        width: 140px;
        height: 140px;
        margin: 35px auto 20px;
        color: #b3b3b3;

        @include media-breakpoint-down(xs) {
            width: 125px;
            height: 125px;
            margin-top: 15px;
        }
    }

    &__title {
        font-size: 36px;
        line-height: 1.1;
        text-align: center;
        color: #bababa;
        margin-bottom: 30px;

        @include media-breakpoint-down(xs) {
            font-size: 24px;
            margin-bottom: 25px;
        }
    }

    &__text {
        text-align: center;
        color: #000;
        font-size: 16px;
        margin-bottom: 42px;
    }
}


.cart-page-panel {
    display: flex;
    padding: 19px 27px 25px;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #f2f2f2;
    border-radius: 2px;

    @include media-breakpoint-down(md) {
        display: block;
    }

    &__coupon {
        flex: 1 1 1px;
        padding-left: 5px;
    }

    &__coupon-container {
        min-width: 225px;
        padding-right: 5px;

        @include media-breakpoint-down(sm) {
            min-width: 1px;
        }
    }

    &__checkout {
        display: flex;
        justify-content: flex-end;
        flex: 3 1 1px;
        padding-left: 5px;
        padding-right: 5px;

        @include media-breakpoint-down(md) {
            justify-content: flex-start;
        }

        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
        }
    }

    &__total {
        padding-top: 21px;
        padding-right: 20px;
    }

    &__prices {
        padding-top: 17px;

        @include media-breakpoint-down(sm) {
            padding-right: 15px;
        }
    }

    &__buttons {
        white-space: nowrap;

        @include media-breakpoint-down(md) {
            white-space: normal;
        }

        @include media-breakpoint-down(sm) {
            width: 50%;
        }

        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }

    &__btn-place {
        display: inline-block;
        padding-top: 13px;
        padding-left: 15px;

        @include media-breakpoint-down(sm) {
            display: block;
            padding-left: 0;
        }
    }

    &__btn {

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}


.cart-coupon {

    &__result {
        font-size: 13px;
        margin-top: 6px;
    }
}

.cart-page-total {

    &__title {
        font-weight: 700;
        font-size: 16px;
    }

    &__desc {
        font-size: 12px;
        color: #a1a1a1;
    }
}

.cart-page-prices {


    &__current {
        font-weight: 700;
        font-size: 32px;
        line-height: 1.1;
        color: #2f3435;
        white-space: nowrap;

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            white-space: normal;
        }
    }

    &__old {
        font-size: 13px;
        line-height: 1.1;
        color: #777;
        white-space: nowrap;
        text-decoration: line-through;

        @include media-breakpoint-down(sm) {
            white-space: normal;
        }
    }
}

.cart-page-prices-difference {
    font-size: 12px;
    color: #888;
    padding: 8px 0 10px;

    &__name {

    }

    &__value {
        display: inline-block;
        background: #ffd02e;
        padding: 3px 8px 2px;
        color: $dark;
        line-height: 16px;
        border-radius: 3px;
        margin: 2px 0 0 0;
    }
}


.cart-table {

    border: 1px solid #f2f2f2;
    margin-bottom: 20px;

    &-header {
        display: flex;
        padding: 13px 15px;
        background: #f9f9f9;
        border-bottom: 1px solid #f2f2f2;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        &__left {
            flex: 1 1 1px;

            @include media-breakpoint-down(sm) {
                width: 100%;
                text-align: center;
            }
        }

        &__right {
            flex: 1 1 1px;
            padding-left: 10px;
            text-align: right;

            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-top: 10px;
                text-align: center;
            }
        }
    }

    &-search {
        min-width: 235px;
    }

    &-tab-links {
        display: inline-block;
        padding: 0 10px;

        &__item {
            display: inline-block;
            position: relative;
            font-size: 14px;
            line-height: 1.3;
            font-weight: 700;
            margin: 0 10px;
            border: none;
            transition: color 300ms ease;

            @include media-breakpoint-down(sm) {
                margin-bottom: 12px;
                white-space: nowrap;
            }

            &:before {
                content: "";
                position: absolute;
                bottom: -6px;
                left: 0;
                right: 0;
                height: 2px;
                background: $primary;
                opacity: 0;

                transition: opacity 300ms ease;
            }

            &.active {
                color: $dark;

                &:before {
                    opacity: 1;
                }
            }
        }
    }


    &__btn-clean {
        border-color: #a6a6a6;
        color: #888;

        &:before {
            content: "";
            display: inline-block;
            height: 12px;
            width: 12px;
            background: url(svg-encode(svg-icon-x(#555))) center / 100% 100% no-repeat;
            vertical-align: middle;
            margin: 0 7px 0 -5px;
        }

        &:hover {
            color: $primary;
            border-color: $primary;
            background: #fff;
        }

        &:active {
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
        }
    }


}

.cart-table__body {
    width: 100%;

    @include media-breakpoint-down(sm) {
        display: block;

        tbody {
            display: block;
            width: 100%;
        }
    }
}

.cart-table-row {
    position: relative;

    @include media-breakpoint-down(sm) {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__column {
        vertical-align: top;
        border-top: 1px solid #f2f2f2;

        @include media-breakpoint-down(sm) {
            display: inline-block;
            border-top-width: 0;
        }

        &_description {
            width: 100%;
            padding: 29px 0 33px 41px;

            @include media-breakpoint-between(md, md) {
                padding-left: 15px;
            }

            @include media-breakpoint-down(sm) {
                display: block;
                padding-bottom: 10px;
                border-top-width: 1px;
            }

            @include media-breakpoint-down(xs) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &_price-one {
            padding-top: 29px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 33px;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        &_count {
            padding-top: 25px;
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 33px;

            @include media-breakpoint-between(md, md) {
                padding-right: 15px;
                padding-left: 15px;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 5px;
            }

            @include media-breakpoint-down(xs) {
                padding-left: 10px;
                padding-right: 10px;
            }
        }

        &_price-total {
            padding-top: 29px;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 33px;

            @include media-breakpoint-down(sm) {
                padding-top: 5px;
            }
        }

        &_notify {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 41px;
            padding-right: 10px;

            @include media-breakpoint-down(sm) {
                display: block;
                width: 100%;
                border-top-width: 1px;
                padding-right: 40px;
            }

            @include media-breakpoint-down(xs) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    &__view {
        display: flex;
        width: 100%;

        @include media-breakpoint-down(xs) {
            display: block;
        }
    }

    &__image-place {
        flex: 1 1 1px;
        width: 100%;
        max-width: 150px;
        min-width: 80px;
        padding-right: 15px;
        text-align: center;

        @include media-breakpoint-down(xs) {
            margin-left: auto;
            margin-right: auto;
            max-width: 230px;
            margin: 10px auto 15px;
            padding-right: 0;
        }
    }

    &__image-link {
        position: relative;
        display: block;
        width: 100%;
    }

    &__label {
        position: absolute;
        bottom: -2px;
        right: -2px;
        display: inline-block;
        margin-bottom: 2px;
        background: #999;
        color: #fff;
        vertical-align: middle;
        text-align: center;
        transition: all 250ms ease;
        width: 40px;
        height: 40px;
        font-size: 13px;
        line-height: 40px;
        border-radius: 50%;
        font-weight: 700;

        &_sale {
            background-color: #e32330;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;

        @include media-breakpoint-down(xs) {
            max-height: 160px;
        }
    }

    &__info {
        flex: 3 1 1px;
        padding-left: 26px;
        padding-right: 55px;

        @include media-breakpoint-between(md, md) {
            padding-right: 10px;
            padding-left: 15px;
        }

        @include media-breakpoint-down(xs) {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    &__title {
        padding-right: 20px;
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 8px;
        word-break: break-word;

        a {
            color: $dark;

            &:hover {
                color: $primary;
            }
        }


        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }

    &__properties {

    }

    &-prop {
        font-size: 13px;
        padding-bottom: 4px;

        &__name {
            color: #888;
            padding-top: 6px;
            margin-right: 5px;
        }

        &__value {
            padding-top: 6px;
        }
    }


    &-price {
        white-space: nowrap;

        &__current {
            font-size: 16px;
            font-weight: 700;
            color: #333;
        }

        &__old {
            font-size: 13px;
            color: #777;
            text-decoration: line-through;
        }

        &__title {
            white-space: normal;
            color: #a1a1a1;
            font-size: 12px;
        }
    }

    &-price-difference {
        font-size: 12px;
        color: #888;
        padding: 8px 0 10px;
        white-space: normal;

        &__name {

        }

        &__value {
            display: inline-block;
            background: #ffd02e;
            padding: 3px 8px 2px;
            color: $dark;
            line-height: 16px;
            border-radius: 3px;
            margin: 2px 0 0 0;
        }
    }


    &__counter-desc {
        text-align: center;
        font-size: 12px;
        color: #a1a1a1;
    }

    &__clean-btn {
        position: relative;
        z-index: 2;
        top: 0;
        height: 30px;
        right: 0;
        width: 30px;
        padding: 0;
        border: 0;
        color: #a1a1a1;
        background: transparent;
        box-shadow: none !important;
        outline: none !important;

        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 20px;
        }

        transition: 300ms color ease;

        &:after,
        &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            width: 1px;
            height: 9px;
            background: currentColor;
            content: '';
        }

        &:after {
            transform: rotate(45deg)
        }

        &:before {
            transform: rotate(-45deg)
        }

        &:hover {
            color: $dark;
        }
    }

    .catalog-item-props {

        &__area {
            margin-bottom: 8px;
        }

        &__title {
            font-size: 13px;
        }

        &-list__item {
            font-size: 12px;
        }
    }

    &-notify {
        display: flex;
        padding: 10px 0;
        border-radius: 2px;

        &__text {
            flex: 1 1 1px;
            padding-right: 15px;
        }

        &__link {

        }

        @include media-breakpoint-down(xs) {
            display: block;
        }
    }

    &__to-cart {

    }

    &:first-child {

        .cart-table-row__column {
            border-top: 0;
        }
    }
}


.order-coupon {
    display: flex;

    &__label-place {
        flex: 0 0 90x;
        max-width: 90px;
    }

    &__form {
        flex: 1 1 1px;
    }
}


.order-table {
    width: 100%;

    @include media-breakpoint-down(md) {
        display: block;

        tbody,
        thead {
            display: block;
            width: 100%;
        }

    }

    &-row {
        position: relative;

        @include media-breakpoint-down(md) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 20px 20px 20px 110px;

            &:not(:last-child) {
                border-bottom: 1px solid #f2f2f2;
            }
        }

        @include media-breakpoint-down(xs) {
            padding-left: 20px;
        }

        &-item {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(sm) {
                display: block;
            }

            &__image-place {
                flex: 0 0 70px;
                max-width: 70px;
                width: 70px;
                height: 70px;
                line-height: 70px;

                @include media-breakpoint-down(md) {
                    position: absolute;
                    left: 20px;
                    top: 25px;
                }

                @include media-breakpoint-down(xs) {
                    position: relative;
                    top: 0 !important;
                    left: 0;
                    flex: none;
                    max-width: 100%;
                    margin-bottom: 15px;
                }
            }

            &__img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }

            &__info {
                flex: 1 1 1px;
                padding-left: 20px;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                }

                @include media-breakpoint-down(xs) {
                    flex: none;
                    width: 100%;
                }
            }

            &__title {
                margin-bottom: 6px;

                a {
                    color: $dark !important;
                }
            }
        }


        &-value {

            &__title {
                display: none;
                font-size: 12px;
                color: #999;

                @include media-breakpoint-down(md) {
                    display: block;
                }
            }

            &__text {

            }
        }

        &__price {
            font-weight: 700;
            font-size: 15px;
            line-height: 1.05;
            white-space: nowrap;
        }

        &__price-old {
            font-size: 13px;
            color: #777;
            text-decoration: line-through;
            margin-left: 4px;
            white-space: nowrap;
        }

        &_head {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &:last-child {

            .order-table__column:not(.order-table__column_head) {
                border-bottom: 0;
            }
        }
    }

    &__column {
        padding: 39px 10px 25px;
        border-top: none;
        border-bottom: 1px solid #f2f2f2;
        vertical-align: middle;

        @include media-breakpoint-down(md) {
            display: block;
            border-bottom: 0;
            padding: 0 !important;
        }

        &_head {
            padding: 7px 10px 8px;
            font-size: 13px;
            color: #999;
        }

        &_item {
            min-width: 300px;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                max-width: 100%;
                min-width: 1px;
            }
        }

        &_value {
            text-align: right;

            @include media-breakpoint-down(md) {
                margin-top: 10px;
                flex: 0 0 25%;
                max-width: 25%;
                text-align: left;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }

        &:first-child {
            padding-left: 30px;
        }

        &:last-child {
            padding-right: 24px;
        }
    }

    &_slim {
        .order-table__column {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        @include media-breakpoint-down(md) {
            .order-table-row {
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .order-table-row-item__image-place {
                top: 12px;
            }
        }
    }
}

.order-form {

    &-list {

        &-item {
            margin-top: 5px;

            &__title {
                display: inline;
                margin: 0 3px 0 0;
                color: #777;
                word-wrap: break-word;
            }

            &__value {
                padding: 5px 7px 5px 9px;
                background: #f0f0f0;
                border: none;
                color: #333;
                border-radius: 10px;
                display: inline-block;
                line-height: 10px;
                vertical-align: top;
                margin: 0 0 0 5px;
            }
        }
    }

    &__description {
        max-width: 400px;
        margin: 8px 0 9px 0;
        color: #777;
        font-size: 13px;
    }
}

.order-form-pickup {
    border-top: 1px solid lighten($primary, 30);
    font-size: 13px;
    word-break: break-word;

    &:last-child {
        border-bottom: 1px solid lighten($primary, 30);
    }


    background: #fff;


    &-list {

    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 35px;

        @include media-breakpoint-down(xs) {
            display: block;
        }
    }

    &__title {
        padding: 16px 10px 16px 0;
        font-size: 13px;
        font-weight: 700;
        color: $primary;
    }

    &__body {
        display: none;
        padding: 5px 35px 16px 35px;
        min-height: 80px;
    }

    &__city {
        font-weight: 700;
    }

    &__description {

    }

    &.active {
        background: lighten($primary, 47);

        .order-form-pickup__choose {
            display: none;
        }

        .order-form-pickup__body {
            display: block;
        }
    }
}

.order-total {
    border: 1px solid #f2f2f2;
    background: #fff;
    padding-bottom: 40px;

    @include media-breakpoint-down(md) {
        margin-bottom: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eaebec;
        padding: 30px;
        margin-bottom: 40px;
    }

    &__title {
        font-size: 16px;
        color: $dark;
        font-weight: 700;
    }

    &__body {
        padding-left: 30px;
        padding-right: 30px;
    }

    &-list {

        &-item {
            display: flex;
            justify-content: space-between;
            margin: 2px 0 0;
            font-size: 14px;

            &_highlight {
                background: #ffd02e;
                padding: 3px 8px 2px;
                border-radius: 3px;
                margin: 10px 0 10px;
            }
        }
    }

    &-info {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        padding: 25px 0;
        border-top: 1px solid #eaebec;
        font-weight: 700;

        &__name {
            font-size: 16px;
        }

        &__value {
            font-size: 18px;
        }
    }

    &__btn {

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}


.order-grid {
    display: block;

    &__left {
        max-width: none;
        width: 100%;
        float: left;
        padding-right: 300px;
    }

    &__right {
        float: right;
        width: 300px;
        margin-left: -300px;
        padding-top: 24px;
        max-width: none;
    }
}


.order-complete-container {
    margin: 0 auto;
    max-width: 730px;
}

.order-payment {
    border: 1px solid #f2f2f2;
    padding: 34px 39px 34px;

    @include media-breakpoint-down(xs) {
        padding-left: 20px;
        padding-right: 20px;
    }

    &__header {
        margin: 0 0 22px;
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.1;
        color: $dark;
    }

    &__view {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(xs) {
            flex-wrap: wrap;
        }
    }

    &__image-place {
        display: inline-block;
        margin: 0 25px 0 0;
        width: 120px;
        height: 72px;
        line-height: 68px;
        text-align: center;
        border: 1px solid #f2f2f2;
        vertical-align: middle;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    &__name {
        margin: 10px 0;
        flex: 1 1 1px;
    }

    &__body {
        margin-top: 20px;
        padding: 24px 38px 24px 38px;
        border: 1px solid #f2f2f2;

        @include media-breakpoint-down(xs) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &__title {

    }
}


.page-404 {
    padding: 20px 0 50px;

    &__image {
        max-height: 225px;
        color: $primary;
    }

    &__content {
        text-align: center;
    }

    &__title {
        font-size: 44px;
        line-height: 1.1;
        color: $dark;
    }

    &__subtitle {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1.3;
    }

    &__desc {
        font-size: 13px;
        margin-top: 20px;
    }

    &__footer {
        margin-top: 20px;
    }
}


.contacts-row {
    display: flex;
    border-bottom: 1px solid #f2f2f2;

    &__image-place {
        flex: 0 0 15%;
        max-width: 15%;
        padding: 20px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__img {
        max-width: 100%;
        max-height: 100%;
    }

    &__content {
        flex: 1 1 1px;
        padding: 20px;

        @include media-breakpoint-down(sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__contacts {
        flex: 0 0 200px;
        max-width: 200px;
        padding: 20px;

        @include media-breakpoint-down(sm) {
            flex: 0 0 33.3333%;
            max-width: 33.3333%;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    &__payments {
        flex: 0 0 30%;
        max-width: 30%;
        padding: 20px;
        line-height: normal;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &__title {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 5px;
    }
}

.contacts-props {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 13px;

    &-item {
        position: relative;
        padding-left: 24px;
        display: block;
        margin-bottom: 3px;
        min-height: 17px;

        &__icon {
            position: absolute;
            top: 1px;
            left: 0;
            max-width: 17px;
            max-height: 17px;
            color: $primary;
        }
    }
}


.index-section {
    padding: 30px 0;

    &__header {
        margin-bottom: 24px;
    }

    &__title {
        display: inline-block;
        font-size: 20px;
        margin-right: 40px;
        color: $dark;
    }

    &_gray {
        background-color: #f9f9fa;
    }

    &_with-slider {

        .index-section__header {
            margin-bottom: 0;
            padding-right: 65px;
        }
    }
}

.index-slider {
    display: none !important;
    overflow: hidden;
    margin-bottom: 30px;

    @include media-breakpoint-up(sm) {
        display: block !important;
    }

    &-item {
        display: flex !important;
        align-items: center;
        height: 460px;
        background: center bottom / cover;

        @include media-breakpoint-down(md) {
            height: 350px;
        }

        @include media-breakpoint-down(sm) {
            height: 300px;
        }

        &__content {
            margin-left: 90px;
            flex: 0 0 50%;
            max-width: 400px;

            @include media-breakpoint-down(md) {
                margin-left: 80px;
                max-width: 275px;
            }

            @include media-breakpoint-down(sm) {
                margin-left: 30px;
            }
        }

        &__title {
            color: $dark;
            font-size: 30px;
            line-height: 1.1;
            overflow: hidden;

            @include media-breakpoint-down(md) {
                font-size: 17px;
                line-height: 22px;
            }
        }

        &__text {
            margin-top: 11px;
            color: $dark;
            font-size: 15px;
            overflow: hidden;
        }

        &__buttons {
            margin-top: 25px;
        }

        &__btn {
            margin-right: 10px;
            margin-bottom: 4px;
            padding: 10px 20px;
        }

        &.light {

            .index-slider-item__title,
            .index-slider-item__text {
                color: #fff;
            }
        }
    }

    .slick {

        &-dots {
            position: absolute;
            z-index: 2;
            bottom: 15px;
            left: 0;
            right: 0;
            text-align: center;
            padding: 0 15px;
            margin: 0;

            li {
                position: relative;
                display: inline-block;
                height: 10px;
                width: 10px;
                margin: 3px;
                border-radius: 50%;
                border: 2px solid #fff;
                background: transparent;
                cursor: pointer;

                &:before {
                    content: "";
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    right: -4px;
                    bottom: -4px;
                }

                button {
                    display: none;
                }

                &.slick-active {
                    background-color: #fff;
                }
            }
        }

        &-arrow {
            position: absolute;
            z-index: 2;
            top: 50%;
            margin-top: -21px;
            font-size: 0;
            line-height: 42px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 0;
            background: #fff url(svg-encode(svg-icon-chevron-right(#333))) left 7px center / 30px 30px no-repeat;
            transition: all ease .3s;

            &:hover {
                background-color: $primary;
                background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
            }

            button {
                display: none;
            }

            &.slick-disabled {
                opacity: 0;
                pointer-events: none;
            }
        }

        &-prev {
            left: -50px;
            transform: scaleX(-1);
        }

        &-next {
            right: -50px;
        }
    }

    &:hover {
        .slick {

            &-prev {
                left: 20px;
            }

            &-next {
                right: 20px;
            }
        }
    }
}

.index-banner-cards {

    @include media-breakpoint-down(xs) {
        max-width: 350px;
        margin: 0 auto;
    }
}

.index-banner-card {
    position: relative;
    z-index: 1;
    height: 215px;
    margin-bottom: 30px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        padding-bottom: 65%;
        height: auto;
        min-height: 180px;
    }

    &:before {
        content: "";
        z-index: 1;
        position: absolute;
        height: 60%;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .5) 100%);
    }

    &__bg {
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: transform .6s ease;
        background: center / cover no-repeat;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            background-color: rgba(0, 0, 0, .5);
            transition: opacity .8s cubic-bezier(.19, 1, .22, 1);
        }
    }

    &__content {
        position: absolute;
        z-index: 2;
        bottom: 20px;
        padding: 0 33px 0 20px;
    }

    &__tags {

    }

    &__tag {
        display: inline-block;
        font-size: 10px;
        background: $primary;
        padding: 5px 8px;
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__title {
        display: block;
        font-size: 17px;
        line-height: 20px;
        margin: 10px 0 0;
        max-height: 80px;
        overflow: hidden;
        color: $dark !important;
    }

    &:hover {

        .index-banner-card__bg {
            transform: scale(1.25);

            &:before {
                opacity: 1;
            }
        }

        .index-banner-card__title {
            color: #fff !important;
        }
    }



    &.light {

        .index-banner-card__title {
            color: #fff !important;
        }

        &:hover {

            .index-banner-card__title {
                color: #fff !important;
            }
        }
    }
}

.index-iconblocks {
    margin-top: 30px;
    margin-bottom: -30px;

    &__iconblock {
        padding-left: 20px;
        margin-bottom: 30px;

        @include media-breakpoint-down(lg) {
            padding-left: 10px;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0px;
        }

        .iconblock__image-place {
            flex: 0 0 80px;
            max-width: 80px;
            width: 80px;
            height: 80px;
            line-height: 80px;
        }

        .iconblock__text {

            @include media-breakpoint-down(xs) {
                font-size: 12px;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}


.index-news-slider {

    .slick-list {
        margin: 0 -15px;
        padding: 0 5px;
    }

    .slick-track {
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-left: 0;
    }

    .slick-slide {
        height: auto;
        margin: 0 15px;
    }
}

.index-grid-col {

    &-xl {
        @include media-breakpoint-up(xl) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    &-lg {
        @include media-breakpoint-up(lg) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}

.index-techno-grid {

    &_1 {
        flex: 0 0 50%;
        max-width: 50%;

        @include media-breakpoint-up(lg) {
            flex: 0 0 40%;
            max-width: 40%;
        }
    }

    &_2 {
        flex: 0 0 50%;
        max-width: 50%;

        @include media-breakpoint-up(lg) {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (max-width: 400px) {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.tizer-line {
    display: block;
    background: center / cover no-repeat;
    height: 150px;

    @include media-breakpoint-down(md) {
        height: auto;
        padding-bottom: 17%;
    }
}


.brands-slider {
    margin: 0;

    &__wrapper {
        border: 1px solid #f2f2f2;
        border-left-width: 0;
        border-right-width: 0;
        padding: 25px 0;
    }

    &__item {
        display: inline-block;
        padding: 0 10px;
        height: 48px;
        line-height: 48px;
        margin: 0 15px;
        text-align: center;

        transition: all ease-out .2s;
        opacity: .5;
        filter: url('data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter i…0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>');
        filter: grayscale(100%);

        &:hover {
            filter: saturate(120%);
            opacity: 1;
        }
    }

    &__img {
        display: inline-block !important;
        vertical-align: middle;
        max-width: 100%;
        max-height: 100%;
        width: auto !important;
    }
}

.insta-slider {
    padding: 0;
    margin: 0;
    overflow: hidden;

    &-item {

        &__content {
            position: relative;
            z-index: 1;
            display: block;
            background: center / cover no-repeat;
            padding-bottom: 95%;
            overflow: hidden;

            &:before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                display: block;
                opacity: 0;
                z-index: -1;
                background: rgba(0, 0, 0, 0.7);
                transition: opacity .3s ease;
            }
        }

        &__text {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -100%;
            max-height: 90%;
            padding: 0 20px;
            color: #fff;
            text-align: left;
            font-size: 13px;
            line-height: 20px;
            margin-bottom: 16px;
            transition: bottom .3s ease;
        }

        &:hover {

            .insta-slider-item__text {
                bottom: 0;
            }

            .insta-slider-item__content:before {
                opacity: 1;
            }
        }
    }

    .slick-track {
        margin-left: 0;
    }

    .slick {

        &-arrow {
            position: absolute;
            z-index: 2;
            top: 50%;
            margin-top: -21px;
            font-size: 0;
            line-height: 42px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 0;
            background: transparent url(svg-encode(svg-icon-chevron-right(#333))) left 7px center / 30px 30px no-repeat;
            transition: all ease .3s;

            &:hover {
                background-color: $primary;

                &:not(.slick-disabled) {
                    background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
                }

                &.slick-disabled {
                    background-color: #eee;
                }
            }

            button {
                display: none;
            }

        }

        &-prev {
            left: -50px;
            transform: scaleX(-1);
        }

        &-next {
            right: -50px;
        }
    }

    &:hover {
        .slick-prev {
            left: 5px;
        }

        .slick-next {
            right: 5px;
        }
    }
}


.sale-card {
    display: flex;
    padding-bottom: 35px;
    margin-bottom: 35px;
    border-bottom: 1px solid #f2f2f2;

    @include media-breakpoint-down(xs) {
        display: block;
    }

    &__image-place {
        flex: 0 0 300px;
        max-width: 300px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            flex-basis: 250px;
            max-width: 250px;
        }

        @include media-breakpoint-down(xs) {
            padding: 0;
            padding-bottom: 24px;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        width: 100%;
    }

    &__body {
        flex: 1 1 1px;
        padding-left: 45px;
        padding-top: 12px;

        @include media-breakpoint-down(xs) {
            padding-left: 0;
        }
    }

    &__title {
        display: block;
        font-size: 16px;
        line-height: 1.3;
        margin-bottom: 5px;

        a {
            color: $dark;

            &:hover {
                color: $primary;
            }
        }
    }

    &__date {
        margin: 2px 0 9px;
        font-size: 12px;
    }

    &__description {
        margin: 5px 0 20px;
    }

    &__more {
        display: inline-block;
        white-space: nowrap;
        color: $dark;
        font-size: 13px;

        &:after {
            content: "";
            display: inline-block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background: transparent url(svg-encode(svg-icon-chevron-right($dark))) center / 70% 70% no-repeat;
            margin: 0 6px;
            vertical-align: middle;
        }

        &:hover {
            color: $primary;

            &:after {
                background-color: $primary;
                background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
                color: #fff;
            }
        }
    }
}

.swimming-question {
    padding: 45px 25px 45px;
    font-size: 13px;
    background: #fbfbfb;
    border: 1px solid #f5f5f5;
    max-width: 280px;
    margin: 0 auto;
    text-align: center;

    @include media-breakpoint-down(md) {
        max-width: 100%;
    }

    &__icon {
        content: "";
        display: inline-block;
        width: 88px;
        height: 80px;
        left: 50%;
        top: 0;
        background: url('../images/template/ask_message.png') center / contain no-repeat;
        margin-bottom: 20px;
    }

    &__text {

    }

    &__footer {
        margin-top: 20px;
    }

    &__btn {

    }

    &_in-side {
        max-width: 100%;
    }
}


.sale-page {

    &-banner {
        display: flex;
        align-items: center;
        height: 450px;
        overflow: hidden;
        background: center / auto 100% no-repeat;

        @include media-breakpoint-down(md) {
            height: 350px;
        }

        @include media-breakpoint-down(sm) {
            background: transparent !important;
            height: auto;
        }

        &__wrapper {
            width: 100%;
        }

        &__content {
            padding: 20px 15px;
            width: 42%;
            animation-name: fadeInUp;
            animation-duration: 0.8s;
            animation-delay: 0.6s;
            animation-fill-mode: both;

            @include media-breakpoint-down(md) {
                width: 50%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                text-align: center;
                padding-top: 60px;
            }
        }

        &__title {

            .page-title {
                font-weight: 400;
                margin-bottom: 15px;
                font-size: 30px;
                line-height: 1.3;
                color: $dark;
                text-align: left;

                @include media-breakpoint-down(md) {
                    font-size: 25px;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }
        }

        &__text {
            padding: 0 0 25px;
        }

        &.light {

            @include media-breakpoint-up(md) {
                color: #fff;

                .sale-page-banner__title .page-title {
                    color: #fff;
                }
            }
        }
    }

    &__date {
        font-size: 12px;
    }

    &__content {
        padding: 15px 0;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0)
    }

    to {
        opacity: 1;
        transform: none
    }
}


.brand-detail {
    float: right;
    margin: 0 0 50px 50px;
    line-height: normal;
    width: 288px;
    max-width: 100%;

    @include media-breakpoint-down(xs) {
        float: none;
        margin: 0 auto 30px;
    }

    &__image-place {
        box-shadow: inset 0 0 0 1px #f5f5f5;
        display: block;
        padding: 80px 30px;
        line-height: normal;
        text-align: center;

        @include media-breakpoint-down(xs) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
    }
}


.cabinet-link {
    display: block;
    padding: 25px 15px;
    text-align: center;
    background-color: #fbfbfb;
    border: 1px solid #f2f2f2;
    height: 100%;
    color: $dark;
    transition: box-shadow ease .1s, border-color ease .1s;

    &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .075);
    }

    @include media-breakpoint-down(sm) {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &__margin {
        margin-bottom: 30px;
    }

    &__icon {
        width: 50px;
        height: 50px;
        margin-top: 8px;
        display: inline-block;
        color: $primary;
    }

    &__title {
        font-size: 17px;
        margin: 25px 0 16px;

        @include media-breakpoint-down(sm) {
            margin-top: 16px;
        }
    }
}

.cabinet-payment {

    &-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__sub {
            flex: 1 1 100%;
            font-size: 12px;
            color: #888;
            margin-bottom: 8px;
        }

        &__sum {
            font-size: 36px;
            line-height: 1;
            color: $dark;
            font-weight: 700;
            white-space: nowrap;
            margin-right: 26px;

            @include media-breakpoint-down(xs) {
                font-size: 28px;
            }
        }

        &__currency {
            color: #955d00;
            line-height: 1;
            font-weight: 600;

            &-small {
                font-size: 0.8em;
            }
        }
    }
}

.cabinet-payment {

    @include media-breakpoint-down(xs) {
        text-align: center;
    }

    &__sum-btn {
        padding: 5px 10px;
        min-width: 55px;
        font-size: 13px;
    }

    &__input {
        text-align: right;
    }
}

.payment-type {
    display: flex;
    align-items: center;
    color: $dark;

    @media (max-width: 500px) {
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;
    }

    &-view {
        position: relative;
        flex: 0 0 120px;
        max-width: 120px;
        height: 72px;
        border: 1px solid #f2f2f2;
        background: #fbfbfb;
        text-align: center;
        line-height: 72px;

        &:before {
            box-sizing: border-box;
            content: "";
            position: absolute;
            top: 7px;
            left: 7px;
            width: 20px;
            height: 20px;
            border: 1px solid #f2f2f2;
            background: #fff center / 70% 70% no-repeat;
            border-radius: 100%;
            z-index: 1;
        }

        &__input {
            display: none;
        }

        &__icon {
            display: inline-block;
            max-width: 60px;
            max-height: 100%;
            vertical-align: middle;
        }
    }

    &__title {
        display: block;
        flex: 1 1 1px;
        padding-left: 20px;
        font-size: 15px;
        font-weight: 700;


        @media (max-width: 500px) {
            flex-basis: 100%;
            padding-left: 0;
            margin-top: 10px;
        }
    }

    &:hover,
    &.active {
        cursor: pointer;
        color: $primary;

        .payment-type-view {
            border-color: $primary;

            &:before {
                background-color: $primary;
            }
        }
    }

    &.active {

        .payment-type-view {

            &:before {
                background-image: url(svg-encode(svg-icon-check-bold(#fff)));
            }
        }
    }

    &_link {

        .payment-type-view {

            &:before {
                display: none !important;
            }
        }
    }
}


.order-item-step {

    &__sub {
        color: #777;
        margin-bottom: 8px;
    }

    &__title {
        display: inline-block;
        font-size: 15px;
        font-weight: 700;
        color: $dark;
        margin-right: 15px;
    }

    &__label {
        display: inline-block;
        padding: 3px 10px 3px 10px;
        margin: 3px 0;
        font-size: 13px;
        font-weight: 700;
    }

    &__image-place {
        border: 1px solid #f2f2f2;
        height: 51px;
        line-height: 47px;
        vertical-align: middle;
        text-align: center;
        margin-bottom: 10px;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    &-prop {
        padding: 4px 0 0 0;
        font-size: 13px;

        &__name {
            display: inline-block;
            margin-right: 15px;
        }

        &__value {
            display: inline-block;
        }
    }
}


.order-info {
    font-size: 15px;

    &__title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    &__btn {

    }
}


.services-item {
    display: flex;

    @include media-breakpoint-down(xs) {
        display: block;
    }

    &__image-place {
        flex: 0 0 300px;
        max-width: 300px;

        @include media-breakpoint-down(sm) {
            flex-basis: 240px;
            max-width: 240px;
        }

        @include media-breakpoint-down(xs) {
            padding: 0 0 24px;
            max-width: 100%;
            margin: 0 auto;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }

    &__body {
        flex: 1 1 1px;
        padding-left: 50px;
        padding-top: 12px;

        @include media-breakpoint-down(xs) {
            padding-left: 0;
        }
    }

    &__title {
        font-size: 16px;
        margin-bottom: 28px;

        a {
            color: $dark;
        }
    }

    &__text {
        margin-top: 5px;
        margin-bottom: 25px;
    }

    &__footer {

    }

    &:hover {

        .services-item__title {

            a {
                color: $primary;
            }
        }
    }
}


.blog-side-column {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__place {
        flex: 1 1 1px;
    }
}

.side-categories {
    background: #f9f9f9;
    padding: 21px 30px 16px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 30px;

    &__header {
        padding-bottom: 19px;
        margin-bottom: 10px;
        border-bottom: 1px solid #f1f1f1;
    }

    &__title {
        font-weight: 700;
        color: $dark;
        font-size: 15px;
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;

        &__item {

        }

        &__link {
            display: block;
            padding: 4px 0;
            color: $dark;

            &:after {
                content: "";
                display: table;
                clear: both;
            }

            &:hover {
                color: $primary;
            }
        }

        &__name {
            font-size: 13px;
            padding: 0 12px 0 0;
        }

        &__value {
            display: inline-block;
            font-size: 12px;
            float: right;
            color: #999;
        }
    }
}

.side-section {
    margin-bottom: 30px;

    &__header {
        padding-bottom: 16px;
    }

    &__title {
        font-size: 15px;
        font-weight: 700;
    }

    &__body {

    }
}


.blog-row {


    &__image-place {
        max-width: 100%;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        width: 100%;
        max-height: 100%;
    }

    &__body {
        padding-top: 12px;
        padding-left: 20px;
    }

    &__title {
        font-size: 16px;
        margin-bottom: 6px;

        a {
            color: $dark;
        }
    }

    &__text {
        margin-top: 16px;
        margin-bottom: 9px;
    }

    &__date {
        font-size: .929em;
        color: #9a9a9a;
        font-weight: 400;
        margin-top: 3px;
    }

    &__footer {
        margin-top: 25px;
    }

    &:hover {

        .blog-row__title {

            a {
                color: $primary;
            }
        }
    }
}


.blog-page {

    &__view {
        margin-bottom: 35px;
    }

    &__body {

    }

    &__footer {
        margin-top: 40px;
    }

    &__meta {
        margin-bottom: 20px;
    }

    &__title-text {
        font-weight: 500;
        color: $dark;
        margin-bottom: 20px;
    }

    &__date {
        display: inline-block;
        font-size: 13px;
        vertical-align: middle;
        margin-right: 15px;
    }

    &-prop {
        display: inline-block;
        font-size: 13px;
        margin-right: 15px;

        &__icon {
            display: inline-block;
            width: 17px;
            height: 17px;
            vertical-align: middle;
            color: $dark;
            margin-right: 3px;
        }

        &__value {
        }
    }

}


.blog-page-slider {
    text-align: center;
    margin-bottom: 20px;

    &-item {
        position: relative;
    }

    &-item__img {
        display: inline-block !important;
        width: auto !important;
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    &__fancy {
        display: block;
        height: 100%;
        line-height: inherit;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            height: 50px;
            width: 50px;
            margin-left: -25px;
            margin-top: -25px;
            border-radius: 50%;
            background: $primary url(svg-encode(svg-icon-zoom-in(#fff))) left 10px top 10px / 27px 27px no-repeat;

            opacity: 0;
            transition: opacity 150ms linear;
        }

        &:hover {

            &:before {
                opacity: 1;
            }
        }
    }

    .slick {

        &-dots {
            position: absolute;
            z-index: 2;
            bottom: -35px;
            left: 0;
            right: 0;
            text-align: center;
            padding: 0 15px;
            margin: 0;

            li {
                position: relative;
                display: inline-block;
                height: 8px;
                width: 8px;
                margin: 4px;
                border-radius: 50%;

                background: #b9b9b9;

                &:before {
                    content: "";
                    position: absolute;
                    top: -4px;
                    left: -4px;
                    right: -4px;
                    bottom: -4px;
                }

                button {
                    display: none;
                }

                &.slick-active {
                    background-color: $primary;
                }
            }
        }

        &-arrow {
            position: absolute;
            z-index: 2;
            top: 50%;
            margin-top: -21px;
            font-size: 0;
            line-height: 42px;
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 0;
            background: transparent url(svg-encode(svg-icon-chevron-right(#333))) left 7px center / 30px 30px no-repeat;

            &:hover {
                background-color: $primary;

                &:not(.slick-disabled) {
                    background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
                }

                &.slick-disabled {
                    background-color: #eee;
                }
            }

            button {
                display: none;
            }

        }

        &-prev {
            left: 7px;
            transform: scaleX(-1);
        }

        &-next {
            right: 7px;
        }
    }

}

.blog-page-thumbs-slider {
    padding: 0;
    margin: 0;
    list-style: none;

    &__item {
        position: relative;
        display: block;
        padding-left: 0;
        margin: 0 10px;
        overflow: hidden;
        vertical-align: bottom;
        list-style: none;
        text-align: center;
        border: 2px solid #fff !important;
        transition: border-color ease-out .2s;
        cursor: pointer;

        &.active {

            border-color: $primary !important;;
        }
    }

    &__img {
        display: block;
        background: center / cover no-repeat;

        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-bottom: 100%;
        }
    }

    .slick-list {
        margin: 0 -10px;
    }

    .slick-slide {

        &.slick-active {

            .blog-page-thumbs-slider__item {
                border-color: $primary !important;
            }
        }
    }

    .slick {

        &-arrow {
            position: absolute;
            display: block;
            z-index: 2;
            top: 0;
            font-size: 0;
            line-height: 20px;
            width: 20px;
            height: 100%;
            border: 0;
            background: #fff url(svg-encode(svg-icon-chevron-right(#333))) center / 15px 15px no-repeat;

            &:hover {
                background-color: $primary;

                &:not(.slick-disabled) {
                    background-image: url(svg-encode(svg-icon-chevron-right(#fff)));
                }

                &.slick-disabled {
                    background-color: #eee;
                }
            }

            button {
                display: none;
            }
        }

        &-prev {
            left: -10px;
            transform: scaleX(-1);
        }

        &-next {
            right: -10px;
        }
    }
}



.comments {

    &-item {
        padding: 35px 0 17px;
        border-top: 1px solid #f2f2f2;
        margin-top: 10px;

        &__header {
            margin-bottom: 7px;
        }

        &__body {
            font-size: 14px;
        }

        &__footer {
            padding-top: 13px;
        }

        &__name {
            color: $dark;
            font-weight: 700;
            padding-right: 12px;
        }

        &__date {
            display: inline-block;
            color: $muted;
            font-size: .867em;
            padding: 2px 4px;
        }

        &__link {
            display: inline-block;
            color: $muted;
            margin-right: 17px;
            margin-bottom: 3px;

            &:hover,
            &.active {
                color: $dark;
            }
        }

        &__link-icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-top: -6px;
        }

        &_new {

            .comments-item__date {
                background-color: #fff4ca;
            }
        }
    }
}


.project-order {
    display: flex;
    padding: 40px;
    background: #f9f9f9;
    border: #f2f2f2;
    color: $dark;

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
    }

    @include media-breakpoint-down(xs) {
        padding: 20px;
    }

    &__image-place {
        height: 60px;
        flex: 0 0 60px;
        max-width: 60px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;

        &.svg-inline {
            font-size: 40px;
        }
    }

    &__text {
        flex: 1 1 1px;
        color: $dark;
        padding: 5px 40px 0 25px;

        @include media-breakpoint-down(sm) {
            max-width: calc(100% - 60px);
            padding-right: 0;
        }

        @include media-breakpoint-down(xs) {
            max-width: 100%;
            flex-basis: 100%;
            padding-left: 0;
            text-align: center;
        }
    }

    &__button-place {
        flex: 0 0 auto;

        @include media-breakpoint-down(sm) {
            flex: 1 1 100%;
            max-width: 100%;
            margin-top: 15px;
            text-align: center;
        }
    }
}




.service-page {

    &-banner {
        display: flex;
        align-items: center;
        height: 450px;
        overflow: hidden;
        background: center / auto 100% no-repeat;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            height: 350px;
        }

        @include media-breakpoint-down(sm) {
            background: transparent !important;
            height: auto;
        }

        &__wrapper {
            width: 100%;
        }

        &__content {
            padding: 20px 15px;
            width: 42%;
            animation-name: fadeInUp;
            animation-duration: 0.8s;
            animation-delay: 0.6s;
            animation-fill-mode: both;

            @include media-breakpoint-down(md) {
                width: 50%;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
                text-align: center;
                padding-top: 60px;
            }
        }

        &__title {

            .page-title {
                font-weight: 400;
                margin-bottom: 15px;
                font-size: 30px;
                line-height: 1.3;
                color: $dark;
                text-align: left;

                @include media-breakpoint-down(md) {
                    font-size: 25px;
                }

                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }
        }

        &__text {
            padding: 0 0 25px;
        }

        &.light {

            @include media-breakpoint-up(md) {
                color: #fff;

                .sale-page-banner__title .page-title {
                    color: #fff;
                }
            }
        }
    }
}


.zoom-lens {

    &__btn {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        margin-left: -25px;
        margin-top: -25px;
        border-radius: 50%;
        background: $primary url(svg-encode(svg-icon-zoom-in(#fff))) left 10px top 10px / 27px 27px no-repeat;
        opacity: 0;
        transition: opacity 150ms linear;
    }

    &:hover {

        .zoom-lens__btn {
            opacity: 1;
        }
    }
}

.gallery {

    &-item {
        display: block;
        padding-bottom: 66.14%;
        background: center / cover no-repeat;
    }
}



.staff-page {

    &-section {
        margin-bottom: 40px;

        &__title {
            margin-bottom: 26px;
            font-size: 22px;
            font-weight: 700;
            line-height: 1.1;
        }

        &__text {
            margin-bottom: 30px;
        }

        &__body {

        }
    }
}


.staff-card {
    position: relative;
    text-align: center;
    display: block;
    padding: 40px 35px 24px;
    margin: 0 -1px 0 0;
    border: 1px solid #f5f5f5;
    transition: box-shadow .3s ease;
    height: 100%;

    &__col {
        margin-bottom: -1px;
    }

    &__image-place {
        border-radius: 100%;
        overflow: hidden;
        width: 150px;
        height: 150px;
        line-height: 147px;
        margin: 0 auto 23px;
        text-align: center;
    }

    &__img {
        display: inline-block;
        max-width: 100%;
        width: auto;
        max-height: 100%;
    }

    &__top {
        mragin-bottom: 16px;
    }

    &__name {
        font-weight: 700;
        margin-bottom: 1px;
        color: $dark;
    }

    &__post {
        font-size: 13px;
        color: $muted;
    }

    &__text {
        font-size: 13px;
        margin-bottom: 14px;
    }

    &__contacts {

    }

    &__socials {
        margin-top: 20px;
    }

    &-socials-item {
        display: inline-block;
        width: 38px;
        height: 38px;
        line-height: 38px;
        border-radius: 50%;
        margin: 1px 3px 0;
        background: $primary;
        transition: background ease .3s;
        color: #fff;
        font-size: 0;
        overflow: hidden;
        text-align: center;

        &__icon {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }

        &:hover {
            background: $dark;
            color: #fff;
        }
    }

    &:hover {
        box-shadow: 0 0 20px 0 rgba(0,0,0,.1)
    }
}



.vacancy-page {

    &-section {
        margin-bottom: 40px;

        &__title {
            font-size: 22px;
            margin-bottom: 26px;
            line-height: 1.1;
        }

        &__body {

        }
    }
}


.vacancy-item {
    border: 1px solid #f2f2f2;
    margin-bottom: -1px;

    &__header {
        display: block;
        position: relative;
        padding: 35px 80px 35px 45px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.2;
        color: $dark;
        cursor: pointer;
        background: #f5f5f5;

        transition: background 0s linear;

        @include media-breakpoint-down(sm) {
            padding-left: 25px;
            padding-right: 60px;
        }

        &:after {
            content: "";
            display: table;
            clear: both;
        }

        &.collapsed {
            background: #fff;
            transition-delay: 350ms;

            .vacancy-item__arrow {
                transform: none;
            }
        }

        &:hover {

            .vacancy-item__arrow {
                background-color: #f5f5f5;
            }
        }
    }

    &__title {

    }

    &__name {
        display: inline-block;
    }

    &__pay {
        display: inline-block;
        float: right;

        @include media-breakpoint-down(sm) {
            float: none;
        }
    }

    &__arrow {
        display: block;
        position: absolute;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        right: 29px;
        margin: auto 0;

        background: transparent url(svg-encode(svg-icon-chevron-down($dark))) center / 90% 90% no-repeat;

        transform: scaleY(-1);

        @include media-breakpoint-down(sm) {
            right: 20px;
        }
    }

    &__body {

    }

    &__content {
        background: #f5f5f5;
        padding: 0 80px 30px 45px;

        @include media-breakpoint-down(sm) {
            padding-left: 25px;
            padding-right: 25px;
        }
    }

    &__footer {
        margin-top: 20px;
        border-top: 1px solid #f0f0f0;
        padding-top: 20px;
    }

    &__properties {
        margin-bottom: 15px;
    }

    &__prop {
        display: inline-block;
        color: #555;
        margin-right: 15px;
        margin-bottom: 10px;
        font-size: 13px;
    }

    &__btn-place {

    }
}





.compare {
    position: relative;

    &__nav-tabs {

        .nav-item {

            @include media-breakpoint-down(xs) {
                width: 100%;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }

    &-available {
        padding: 5px 0;

        &__btn {
            margin: 0 4px 4px 0;
        }
    }

    &-header {
        position: relative;
        margin-bottom: 25px;

        @include media-breakpoint-down(xs) {
            margin-bottom: 5px;
        }

        &__clear-place {
            position: absolute;
            top: -7px;
            right: 0;

            @include media-breakpoint-down(sm) {
                position: static;
                padding: 10px 0;
            }
        }

        &__clear {
            border-color: #999;
            color: #999;
        }
    }

    &-view {
        margin: 0 0 0 230px;

        @include media-breakpoint-down(xs) {
            margin-left: 100px;
        }

        &__frame {
            position: relative;
            overflow: hidden;
        }

        &__wrap {

        }

        &__list {
            display: flex;
        }

        &-item {
            position: relative;
            flex: 0 0 230px;
            max-width: 230px;
            padding: 40px 20px 20px;
            font-size: 13px;
            overflow: hidden;

            &__image-place {
                min-width: 70px;
                height: 170px;
                line-height: 170px;
                margin: 0 auto;
                padding: 0;
                text-align: center;
                display: block;

                @include media-breakpoint-down(xs) {
                    height: 120px;
                    line-height: 120px;
                }
            }

            &__img {
                display: inline-block;
                max-width: 100%;
                max-height: 100%;
                vertical-align: middle;
            }


            &__title {
                display: block;
                padding: 14px 0 0;
                font-weight: 700;
            }

            &__price {
                margin: 10px 5px 0 0;
                font-size: 18px;
                font-weight: 700;
                line-height: 1;
            }
        }
    }


    &-data {
        margin: 0 0 0 230px;

        @include media-breakpoint-down(xs) {
            margin-left: 100px;
        }

        &__frame {
            position: relative;
            overflow: hidden;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 1px;
                background: #f2f2f2;
            }
        }

        &__wrap {
            margin-left: -230px;

            @include media-breakpoint-down(xs) {
                margin-left: -100px;
            }
        }

        &-clone {
            position: absolute;
            left: 0;
            width: 231px;
            overflow: hidden;

            @include media-breakpoint-down(xs) {
                width: 101px;
            }
        }
    }

    &-table {

        &__row {

            &.hovered {

                .compare-table__cell {
                    background: #f7f7f7;
                }
            }
        }

        &__cell {
            position: relative;
            width: 230px;
            min-width: 230px;
            max-width: 230px;
            height: 40px;
            border: 1px solid #f2f2f2;
            padding: 10px 27px 13px 18px;

            &_title {
                font-weight: 700;

                @include media-breakpoint-down(xs) {
                    width: 100px;
                    min-width: 100px;
                    max-width: 100px;
                    font-size: 10px;
                    word-wrap: break-word;
                    padding-left: 5px;
                    padding-right: 20px;
                }
            }
        }

        &__close {
            display: block;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background: url(svg-encode(svg-icon-x(#666))) center / 70% 70% no-repeat;

            &:hover {
                background-color: $primary;
                background-image: url(svg-encode(svg-icon-x(#fff)));
            }

            &_in-cell {
                position: absolute;
                right: 15px;
                top: 12px;
                display: none;

                @include media-breakpoint-down(xs) {
                    right: 7px;
                }

                .compare-table__row.hovered & {
                    display: block;
                }

                .mobile & {
                    display: block;
                }
            }

            &_in-card {
                position: absolute;
                right: 20px;
                top: 23px;
            }
        }
    }
}


.compare-scroll {

    &__wrap {
        position: relative;
        margin: 21px 0 33px 230px; // 230px

        @include media-breakpoint-down(xs) {
            margin-left: 0;
        }
    }

    &-scrollbar {
        margin: 0 38px 1em;
        background: #f2f2f2;
        height: 1px;
        opacity: 1; // 0

        &__bar {
            height: 5px;
            line-height: 0;
            display: block; // none
            position: relative;
            top: -3px;
            cursor: pointer;
        }

        &__handle {
            color: #FFF;
            background: $primary;
            width: 100px;
            height: 3px;
            position: relative;
            top: 2px;
            cursor: pointer;
            border-radius: 3px;
        }

        &__mousearea {
            position: absolute;
            top: -9px;
            left: 0;
            width: 100%;
            height: 20px;
        }
    }

    &-nav {
        list-style: none;
        padding: 0;
        margin: 0;

        &__area {
            position: absolute;
            right: 0;
            top: -14px;
            width: 100%;
            z-index: 1;
            margin: 0 0 27px;
        }

        &__arrow {
            position: absolute;
            z-index: 3;
            width: 29px;
            height: 29px;
            display: inline-block;
            cursor: pointer;
            opacity: .7;
            background: url(svg-encode(svg-icon-chevron-right($dark))) center / 80% 80% no-repeat;

            &_prev {
                left: 0;
                transform: scaleX(-1);
            }

            &_next {
                right: 0;
            }

            &:hover {
                opacity: 1;
            }

            &.disabled {
                opacity: .5;
            }
        }
    }
}

.toast {
    background: #f6f6f7 !important;
    color: #555 !important;
}

.toast-container>div {
    opacity: 1;
    padding-left: 30px;
}

.toast-container.toast-bottom-full-width>div,
.toast-container.toast-top-full-width>div {
    width: 100%;
    border-radius: 0;
}

.toast-container.toast-bottom-full-width .toast {
    margin-bottom: 0;
    margin-top: 6px;
}

.toast-close-button {
    width: 17px;
    height: 17px;
    background: url(svg-encode(svg-icon-x(#555))) center / 100% 100% no-repeat !important;
    font-size: 0;
}

.toast-container>div:hover {
    box-shadow: 0 0 12px #00000070;
}





@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 0.65;
    }

    to {
        transform: scale(4);
        opacity: 0;
    }
}


.poparea {
    margin: 30px 0;
    text-align: left;

    &-container {
        display: inline-block;
        position: relative;
        border: 6px solid #f2f2f2;
    }

    &-pops {
        position: absolute;
        top: 0; left: 0;
        right: 0; bottom: 0;

        &-item {
            position: absolute;
        }
    }

    .dropdown-menu {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
    }
}



.poparea-pop {
    position: absolute !important;
    z-index: 1000;
    user-select: none !important;

    &.show {
        z-index: 1001;
    }

    &__btn {
        position: relative;
        z-index: 2;
        top: -13px;
        left: -13px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border-color: transparent;
        background: #fff;
        padding: 0;
        box-shadow: 0 0 2px rgba(0,0,0,0.4);

        @include media-breakpoint-down(sm) {
            top: -10px;
            left: -10px;
            width: 15px;
            height: 15px;
        }

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #fff;
            opacity: .8;
            border: 0;
            animation: pulse 3s infinite;
            pointer-events: none;
        }

        &:before {
            animation-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
            animation-delay: 1s;
        }

        &:after {
            animation-timing-function: ease;
        }
    }

    &:hover {

        .poparea-pop__btn {
            background: #fff;
            border-color: transparent;
        }
    }


    &-view {
        z-index: 1;
        background: #fff;
        padding: 25px 20px;
        min-width: 200px;

        &[x-placement^="bottom"] {
            margin-top: -13px;
        }

        &[x-placement^="top"] {
            margin-bottom: -13px;
        }


        &__image-place {
            display: block;
            width: 100%;
            text-align: center;
            height: 120px;
            line-height: 120px;
            margin-bottom: 15px;
        }

        &__image {
            display: inline-block;
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }

        &__title {
            color: $dark;
            font-weight: 700;
            font-size: 14px;

            &:hover {
                color: $primary;
            }
        }

        &__info {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #ddd;
        }

        &__price {
            font-size: 20px;
            line-height: 1;
        }
    }

}



.project-card {

    &__col {
        margin-bottom: 30px;
    }

    &__image-place {
        text-align: center;
        vertical-align: middle;
        display: block;
        position: relative;
        overflow: hidden;

        max-height: 350px;

        &:hover {
            .project-card-dark {
                opacity: .8;

                &__content {
                    transform: translateY(0);
                }
            }
        }
    }

    &__img {
        max-height: 100%;
        display: inline-block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }


    &-dark {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        align-items: center;
        justify-content: center;
        opacity: 0;
        background: #2e2e2f;
        transition: opacity .2s ease;
        overflow: hidden;

        &__content {
            position: relative;
            top: 0;
            left: 0;
            transition: transform .35s ease-out;
            color: #fff;
            transform: translateY(50%);
            font-weight: 400;
            font-size: 15px;
        }

        &__text {
            position: relative;
            padding: 0 30px 0 0;
            display: inline-block;

            &:after {
                content: "";
                position: absolute;
                top: -1px;
                right: 0;
                display: block;
                width: 25px;
                height: 25px;
                background: url(svg-encode(svg-icon-arrow-right(#fff))) center / 100% 100% no-repeat;
            }
        }
    }

    &__body {
        padding: 20px 0 22px;
    }

    &__title {
        font-weight: 700;
        margin-bottom: 6px;

        a {
            color: $dark;

            &:hover {
                color: $primary;
            }
        }
    }

    &:hover {
        .project-card__title a {
            color: $primary;
        }
    }
}


.project-tabs {
    margin: 0 -11px 47px;
    position: relative;

    &:after {
        content: "";
        display: block;
        height: 1px;
        margin: 0 11px;
        background: #f2f2f2;
        margin-top: -1px;
    }

    &__link {
        display: inline-block;
        color: $dark;
        padding: 0 11px;

        &.active,
        &.mixitup-control-active {
            color: $primary;

            .project-tabs__text {

                &:after {
                    right: 0;
                    background: $primary;
                }
            }
        }
    }

    &__text {
        position: relative;
        display: block;
        padding: 10px 0 24px;
        text-decoration: none;

        &:after {
            content: "";
            position: absolute;
            height: 2px;
            background: #fff;
            left: 0;
            right: 100%;
            bottom: 0;
            display: block;
            transition-property: right;
            transition-duration: .15s;
            transition-timing-function: ease-out;
        }
    }
}